import '~/scripts/integrations/jquery-extended';
import Highcharts from '~/scripts/integrations/highcharts';
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';
import Pagelet from '~/scripts/lib/Pagelet';

export default class {
  constructor() {
    this.addEvents();
  }

  createArc(arcData) {
    if (!arcData) return false;

    Highcharts.chart('arcGraph', {
      colors: ['#293462', '#a64942', '#fe5f55', '#fff1c1', '#5bd1d7', '#ff502f', '#004d61', '#ff8a5c', '#fff591', '#f5587b', '#fad3cf', '#a696c8', '#5BE7C4', '#266A2E', '#593E1A'],
      title: {
        text: 'Cross Company Recognitions'
      },
      accessibility: {
        description: 'Arc diagram chart with circles of different sizes along the X axis, and connections drawn as arcs between them. From the chart we can see that Paris is the city with the most connections to other cities.',
        point: {
          valueDescriptionFormat: 'Connection from {point.from} to {point.to}.'
        }
      },
      series: [{
        keys: ['from', 'to', 'weight'],
        type: 'arcdiagram',
        name: 'Recognitions',
        linkWeight: 1,
        centeredLinks: true,
        dataLabels: {
          rotation: 90,
          y: 30,
          align: 'left',
          color: 'black'
        },
        offset: '65%',
        data: arcData
      }]
    });
  }

  addEvents() {
    new CompanyAdmin();
    this.pagelet = new Pagelet();
    this.dateRange = new window.R.DateRange({ refresh: 'turbo' });

    this.bindRandomizeBtn();
    this.bindRecognitionChartButtons();
  }

  bindRandomizeBtn() {
    $document.on(R.touchEvent, "#randomize-link", function (e) {
      var $randomRecognition = $('#random-recognition .random-recognition');
      var loadingClass = 'changing';
      e.preventDefault();

      if (!$randomRecognition.hasClass(loadingClass)) {
        $randomRecognition.addClass(loadingClass);
        window.R.currentPage.pagelet.getPagelet("#random-recognition");
      }

      return false;
    });
  }

  bindRecognitionChartButtons() {
    $document.on(R.touchEvent, '#recognition-series-btns .btn', function () {
      var $selectedBtn = $(this);

      var chart = $('#badge-line-graph-wrapper .graph-placeholder').highcharts();
      var peerStackKey = "column,Peer,,";
      var anniversaryStackKey = "column,Anniversary,,";

      // button state
      $selectedBtn.parent().children().removeClass('active');
      $selectedBtn.addClass('active');

      // toggle series
      var seriesGroup = $(this).data('seriesgroup');
      chart.redraw(false);
      $.each(chart.series, function () {
        if (seriesGroup == 'peer') {
          this.stackKey == peerStackKey ? this.setVisible(true, false) : this.setVisible(false, false);
        } else if (seriesGroup == 'anniversary') {
          this.stackKey == anniversaryStackKey ? this.setVisible(true, false) : this.setVisible(false, false);
        } else {
          this.setVisible(true, false);
        }
      });
      chart.redraw(true);
    });
  }

  removeEvents() {
    $document.off('#randomize-link');
    $document.off('#recognition-series-btns .btn');
  }
}
