import '~/scripts/integrations/jquery-extended';
import Swal from 'sweetalert2';
import SwalForm from '~/scripts/lib/SwalForm';
import remoteCheckboxToggle from '~/scripts/lib/ui/remoteCheckboxToggle';

export default (function () {
  new SwalForm();

  function refreshCmsSwal(evt) {
    evt.preventDefault();
    Swal.fire({
      icon: 'info',
      showCancelButton: true,
      focusConfirm: true,
      title: "Refresh CMS Cache?",
      html: "<p style='text-align:left'>This will remove and repopulate the Wordpress/CMS cached files. This is a safe operation (but use sparingly). This will be done in the priority-caching bg queue.</p>",
      confirmButtonText: 'Go for it!',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      allowOutsideClick: function () { !Swal.isLoading() },
      preConfirm: function (login) {
        const csrfToken = document.querySelector("[name='csrf-token']").content;
        return fetch('/admin/settings/refresh_cms_cache', {
          method: 'POST',
          headers: {
            "X-CSRF-Token": csrfToken, // 👈👈👈 Set the token
            "Content-Type": "application/json"
          },
        }).then(function (response) {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
          .catch(function (error) {
            Swal.showValidationMessage('Request failed: ' + error);
          })
      },
    }).then(function (result) {
      // result.value is undefined if user doesn't confirm the Swal to proceed
      if (result.value) {
        if (result.value.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Refreshing the cache has commenced!',
            text: 'It may take a few minutes. Check back in a bit. Also, you can check the bg jobs to see when it clears',
            showConfirmButton: true
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'There was a problem creating the job. Check the exception logs or server logs.',
            showConfirmButton: true
          })
        }
      }
    });
    return false;
  };

  function updateAppCuesVersionSwal(evt) {
    evt.preventDefault();

    let swalDetails = "<p>Only do this if verified by CTO or CEO that it is safe to do this. NOTE: This is only necessary if the expected and actual values do not match.</p>";
    swalDetails += window.R.appCuesIntegrityChecker.debugHtml();
    Swal.fire({
      icon: 'info',
      showCancelButton: true,
      focusConfirm: true,
      title: "Update AppCues Version?",
      html: swalDetails,
      confirmButtonText: 'Go for it!',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      allowOutsideClick: function () { !Swal.isLoading() },
      preConfirm: function (login) {
        const csrfToken = document.querySelector("[name='csrf-token']").content;
        return fetch('/admin/settings/update_appcues_version', {
          method: 'POST',
          headers: {
            "X-CSRF-Token": csrfToken, // 👈👈👈 Set the token
            "Content-Type": "application/json"
          },
        }).then(function (response) {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
          .catch(function (error) {
            Swal.showValidationMessage('Request failed: ' + error);
          })
      },
    }).then(function (result) {
      // result.value is undefined if user doesn't confirm the Swal to proceed
      if (result.value) {
        if (result.value.success === true) {
          var successMsg = '<p>This change should be immediate. Refresh the company admin to see if it is now loading.</p>'
          // The below doesn't work as expected because gon isn't reset after the ajax request to update
          // Supposedly, gon has gon.watch that is meant to handle ajax updates
          // but I'm not going to implement this right now.
          // Maybe in the future implement this if we use gon.watch in other places
          // successMsg += window.R.appCuesIntegrityChecker.debugHtml();
          Swal.fire({
            icon: 'success',
            title: 'AppCues version has been updated!',
            html: successMsg,
            showConfirmButton: true
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'There was a problem updating the version. Check the exception logs or server logs.',
            showConfirmButton: true
          })
        }
      }
    });
    return false;
  }

  return function () {
    remoteCheckboxToggle();
    $document.on(R.touchEvent, "#refresh-cms-btn", refreshCmsSwal);
    $document.on(R.touchEvent, "#update-appcues-version", updateAppCuesVersionSwal);
  }
})();
