import '~/scripts/integrations/jquery-extended';
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';

export default (function() {
  function Allocation() {
    this.addEvents();
    this.setupAutoSubmitSelect();
    this.togglePoints();
    this.reloadifRestore();
    new window.R.Select2(this.setupSelect2);
  }

  Allocation.prototype.addEvents = function () {
    var that = this;
    this.companyAdmin = new CompanyAdmin();
    this.companyAdmin.autosaveSetting(".autosave-setting-form select, .autosave-setting-form textarea");

    this.companyAdmin.autosaveSetting(".autosave-setting-form#points-budget-switch input", function() {
      $("#points-allocation-form").toggleClass("disabled-blur");
    });

    $("#base-budget-form").on('ajax:success', function(event) {
      var $setting = $(this).find('input')
      window.R.utils.renderFeedbackMessageAfterSave("Saved!", $setting, 3000, "success");

      that.reload(); // to recalculate total company points
    });

    $("#base-budget-form").on('ajax:error', function(event) {
      var $setting = $(this).find('input')
      window.R.utils.renderFeedbackMessageAfterSave("Failed!", $setting, 5000, "danger");
    })
  };

  Allocation.prototype.removeEvents = function () {
    $("#base-budget-form").off('ajax:success');
  };

  Allocation.prototype.setupAutoSubmitSelect = function () {
    $body.on('change', '#catalog_id', function (event) {
      var params = new URLSearchParams(location.search);
      params.set(event.target.name, event.target.value);
      var url = window.R.utils.locationWithNewParams(params.toString())
      Turbo.visit(url);
    });
  };

  Allocation.prototype.setupSelect2 = function () {
    $('.select2').select2(R.utils.companyRoleSelect2TemplateOpts({ theme: 'bootstrap-5' }));
  };

  Allocation.prototype.togglePoints = function() {
    var that = this;
    this.companyAdmin.autosaveSetting("#points-budget-switch input", function() {
      that.reload(); // toggle total company points
    });
  };

  Allocation.prototype.reload = function() {
    var $oldPoints = $("#points-total");
    $("#points-total-wrapper").load(window.location.href + ' #points-total-wrapper', function() {
      $oldPoints.remove();
    });
  };

  // It reloads the page to update the interval after changing the settings and clicking the back button.
  Allocation.prototype.reloadifRestore = function() {
    if ($body.data('page-previously-loaded')) {
      window.location.reload();
    }
    $body.attr('data-page-previously-loaded', 'true');
  };

  return Allocation;
})();