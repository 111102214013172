import '~/scripts/integrations/jquery-extended';

export default class {
  constructor() {
    this.addPaymentMethodHandler();
    this.addLineItemHandler();
    this.addFormHandlers();
  }

  removeEvents() {
    $document.off('click.payment');
  }

  addPaymentMethodHandler() {
    $document.on('click.payment', '.payment-method', function() {
      var $paymentMethod = $(this);
      if($paymentMethod.val() == "CreditCard")
        $("#start-date-wrapper").addClass('hidden');
      else
        $("#start-date-wrapper").removeClass('hidden');
    });
  }

  addLineItemHandler() {
    $document.on('click.payment', '.remove_fields', function(evt) {
      var $this = $(this);
      $this.prev('input[type=hidden]').val('1');
      $this.closest('fieldset').hide();
      evt.preventDefault();
    });

    $document.on('click.payment', '.add_line_item', function(evt) {
      var $this = $(this);
      var time = new Date().getTime();
      var regexp = new RegExp($(this).data('id'), 'g');
      $this.before($this.data('fields').replace(regexp, time));
      evt.preventDefault()
    });
  }

  addFormHandlers() {
    window.R.DatePicker(function(){
      $('.datepicker').datepicker();
    });
    $('#trumbowyg').trumbowyg();
    $("#contract-main").removeClass("opacity0");
  }
}
