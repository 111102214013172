import '~/scripts/integrations/jquery-extended';
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';
import Pagelet from '~/scripts/lib/Pagelet';

export default (function() {
  var Res = function() {
    new CompanyAdmin();
    this.addEvents();
  };

  Res.prototype.addEvents = function() {
    this.pagelet = new Pagelet();
    this.initGraphs();
  };

  Res.prototype.initGraphs = function() {
    $(".monthly .pagelet").on('pageletLoaded', function () {
      this.addResGaugeAndInitTooltip("#res-score-monthly");
      this.addResGaugeAndInitTooltip("#res-score-sender-monthly");
    }.bind(this));

    $(".quarterly .pagelet").on('pageletLoaded', function () {
      this.addResGaugeAndInitTooltip("#res-score-quarterly");
      this.addResGaugeAndInitTooltip("#res-score-sender-quarterly");
    }.bind(this));

    $(".yearly .pagelet").on('pageletLoaded', function () {
      this.addResGaugeAndInitTooltip("#res-score-yearly");
      this.addResGaugeAndInitTooltip("#res-score-sender-yearly");
    }.bind(this));
  };

  Res.prototype.removeEvents = function() {
  };

  Res.prototype.addResGaugeAndInitTooltip = function(resId) {
    var $res = $(resId);
    $res.tooltip();

    if ($res.children().length > 0) {
      $res.empty();
    }

    this.res = this.res || [];
    var options = {
      data: $res.data('res'),
      pane_center: ['50%', '90%'],
      pane_size: '180%',
      chart_height: 150
    };

    this.res.push(R.gage(resId, options));
  };

  return Res;

})();
