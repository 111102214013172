import '~/scripts/integrations/jquery-extended';

(function() {
  function affiliateTracker() {
    var affiliateVisitorIdCookie = 'tap_vid';
    var trackedAffiliateCookieId = 'affiliateTracked';
    var visitorId = readCookie(affiliateVisitorIdCookie);
    var trackedAffiliate = readCookie(trackedAffiliateCookieId);

    // only attempt to track the affiliate if:
    // 1. we have a cookie with visitor id
    // 2. we haven't already tracked this affiliate
    // 3. we're logged in and can peg an affiliate to a company
    // See UsersController#trackedAffiliate for more info about
    // the trackedAffiliate cookie
    if(visitorId && $body.data('loggedin') && !trackedAffiliate) {
      trackAffiliate(visitorId);
    }

    function trackAffiliate(visitorId) {
      $.post({
          url: "/track_affiliate",
          data: {visitor_id: visitorId}
        }).then(function(){
          createCookie(trackedAffiliateCookieId, true);
        })
    }
  }

  // for some reason, had to wait until after all of Recognize
  // had been init'd.
  $document.on('recognizeInitComplete', affiliateTracker);
})();

