import '~/scripts/integrations/jquery-extended';

export default (function() {
  var BadgesShow = function() {
    this.$container = $('#stream');
    this.recognitionCards = new R.Cards("#stream");

    this.comments = new R.Comments();
  };

  BadgesShow.prototype.removeEvents = function (){
    this.comments.removeEvents();
    this.recognitionCards.unload();
  }

  return BadgesShow;
})();
