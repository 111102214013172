import '~/scripts/integrations/jquery-extended';
import Swal from 'sweetalert2';
import HandlebarsTemplates from '~/scripts/integrations/handlebars-templates';
import Pagelet from '~/scripts/lib/Pagelet';

export default (function() {
  var Show = function() {
    var timer = 0;

    this.pagelet = new Pagelet(pageletCallback);

    $body.on(R.touchEvent, "a.access-enabled", togglePrivacy);

    $body.on(R.touchEvent, ".recognition-delete .btn", function(){
      var translation = gon.translations_for_recognition_delete_swal;
      Swal.fire({
        title: translation.title ,
        text: translation.label ,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: translation.confirm_text,
        cancelButtonText: translation.cancel_text,
        customClass: 'destroy-confirm-button'
      }).then(function(result) {
        if (result.value) {
          $(".recognition-delete").submit();
        }
      });
      return false;
    });

    $body.on(R.touchEvent, ".recognition-award-certificate", function(e) {
      e.preventDefault();
      e.stopPropagation();
      var linkGenerator = AwardLinkGenerator();
      linkGenerator.openPopup();
    });

    (function changeYammerText() {
      var $el = $(".yj-publisher-watermark");
      if ($el.length) {
        $el.text("Leave a comment");
        return clearTimeout(timer);
      }
      timer = setTimeout(changeYammerText, 50);
    })();

    this.comments = null; // assigned in pageletCallback
    this.reactions = new R.Reactions();

    handlePossiblePageRestoreForCommentsWysiwyg();

    // check if an element has a data-anniversary value of true
    // if it does, then run the big letters animation
    if ($("#recognitionShowMain").data("anniversary") && $("#recognitionShowMain").data("currentuser") === $("#recognitionShowMain").data("recipient")) {
      new R.ui.BigLettersAnimation($('#badgeName').text());
    }
  };

  Show.prototype.removeEvents = function () {
    $('.new_comment .error').empty();
    // set comment section to be reloaded on restore - similar to the cards behavior
    // apart from re-initializing trumbowyg, this also resets any open edits forms
    $(".comments-wrapper").closest('.pagelet').removeClass('pagelet-loaded');

    if ($('.trumbowyg-modal').length){
      $('.comments-wrapper').removeClass('blur-overlay');
    }
    R.ui.destroyWYSIWYG();
  };

  function pageletCallback(pageletDiv) {
    // there are multiple pagelets in this page, so filter comment pagelet
    if ($(pageletDiv).find('.comments-wrapper').length) {
      this.comments = new R.Comments();
      scrollToCommentIfRelevantAnchorPresent();
      this.comments.setupCommentsWysiwygForNewFormIfRequired();
    } else if($(pageletDiv).find('.reactions').length){
      if (!R.reactionsSupported) {
        $('.reactions').hide();
      }
    }
    R.utils.safe_feather_replace(); // needed for both comments & reactions sections
  }

  function togglePrivacy() {
    setAccess();
  }

  /**
   * Note that there is a global manual scroller in init.js (checkIfScrolledToHashLink)
   *
   * Also, the comment DOM ids could possibly be modified directly on the server-side to be the shorter version used in the anchor here
   * but skipping that as it might cause a lot of spec failures
   */
  function scrollToCommentIfRelevantAnchorPresent() {
    var hash = window.location.hash,
        selector, $commentEl, scrollTopHeight;
    if (!hash.length) return;

    selector = hash.substr(1); // remove # prefix
    // check if anchor startsWith relevant prefix
    if (selector.lastIndexOf('comment-', 0) !== 0) return;

    // fetch relevant comment in DOM via end-of-id match
    $commentEl = $("[id$=" + selector + "]");
    if (!$commentEl.length) return; // be defensive

    scrollTopHeight = $commentEl.offset().top - $('#header').outerHeight() - 5; // 5px spacing
    $("html, body").animate({scrollTop: scrollTopHeight}, 1000);
  }

  function setAccess(makePublic) {
    var $accessWrapper = $("#recognition-access-wrapper");
    var network = $body.data("name");

    var ajaxMetaData = {
      url: "/"+network+"/recognitions/"+$accessWrapper.data('param')+"/toggle_privacy",
      type: "PUT",
      beforeSend: function(xhr, settings){
        xhr.setRequestHeader('accept', '*/*;q=0.5, ' + settings.accepts.script);
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      }          
    };

    if (makePublic) {
      $accessWrapper.removeClass("private");
      ajaxMetaData.data = {make_public: makePublic};
    } else {
      $accessWrapper.toggleClass("private");
    }
        
    $.ajax(ajaxMetaData);
  }

  function AwardLinkGenerator() {
    var linkGenerator = function() {
      $body.on('change', '#award-certificate-recipient', function() {
        var disabled = (this.value === '');
        $('.swal2-actions .swal2-confirm').attr('disabled', disabled);
      });
    };

    linkGenerator.prototype.openPopup = function() {
      var awardForm = this.generateRecipientSelectionForm();
      var that = this;
      Swal.fire({
        title: gon.recognition.award_recipient_title,
        html: awardForm,
        showCancelButton: true,
        confirmButtonText: gon.recognition.view,
        didOpen: function() {
          $('.swal2-actions .swal2-confirm').attr('disabled', 'disabled');
        },
      }).then(function(result) {
        if(result.value) {
          var selectedRecipientSlug = $('#award-certificate-recipient')[0].value;
          that.generateAwardLinkForRecipientAndOpen(selectedRecipientSlug);
        }
      });
    };

    linkGenerator.prototype.generateRecipientSelectionForm = function() {
      var recipientData = {
        recipients: gon.recognition.recipients_data,
        selectUser: gon.recognition.select_user
      };

      var recipientTemplate = HandlebarsTemplates['recognition/recipientSelect'];
      return recipientTemplate(recipientData);
    };

    linkGenerator.prototype.generateAwardLinkForRecipientAndOpen = function(recipientWithId) {
      var $awardCertLink = $('#view-certificate-link').first();
      recipientWithId = recipientWithId.split(':');
      var certificateUrl = window.R.utils.addParamsToUrlString($awardCertLink.attr('href'), { recipient: recipientWithId[1], recipient_type: recipientWithId[0] });
      window.open(certificateUrl, '_blank');
    };

    return new linkGenerator();
  }

  function handlePossiblePageRestoreForCommentsWysiwyg() {
    /*
     * Reset previously opened edit links if this is a page restore
     * this is the existing behavior with cards as the whole comments section is reloaded for them
     * especially needed for wysiwyg inputs
     *
     * An alternative approach here would be to destroy and re-init wysiwyg for these forms
     **/
    var $cancelEditLinks = $('.cancel-edit');
    if ($cancelEditLinks.length){
      $cancelEditLinks.click();
    }

    /*
     * Re-init trumbowyg on page restore
     * Possible scenarios for initial load (which are being avoided):
     *   - either the form will not be visible as the pagelet will not have loaded (most likely)
     *   - or the pagelet might have loaded, but the input will have the trumbowyg class if the pagelet JS initialized it
     *   - or the pagelet might have loaded, and the input is ignored and doesn't have the trumbowyg class because wysiwyg is not enabled,
     *       in which case it will enter the conditional below, but the input will be ignored again by the same setup method
     **/
    var $newComment = $('.new_comment .comment_content:not(.trumbowyg-textarea)');
    if($newComment.length) {
      this.comments.setupCommentsWysiwygForNewFormIfRequired();
    }
  }

  return Show;
})();
