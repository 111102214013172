/**
 * This Stimulus controller is used to apply a CSS line-clamp effect to the target content.
 * 
 * Sample Usage:
 * --------------
 * To use this controller, you need to specify the class, targets in your HTML element.
 * 
 * 1. Add the Stimulus controller identifier to the HTML element:
 * 
 *    <div data-controller="line-clamp">
 *      <div class="line-clamp" data-line-clamp-target="content">Your long content here...</div>
 *    </div>
 * 
 * 2. Optionally, you can set the number of lines to clamp by adding a data attribute for the value:
 * 
 *    <div data-controller="line-clamp" data-line-clamp-lines-value="5">
 *      <div class="line-clamp" data-line-clamp-target="content">Your long content here...</div>
 *    </div>
 * 
 * By default, if the lines value is not provided, it will clamp to 3 lines.
 * 
 * HTML Attributes:
 * - data-controller="line-clamp": Attaches this controller to the element.
 * - data-line-clamp-target="content": Identifies the element whose content will be clamped.
 * - data-line-clamp-lines-value="5" (optional): Sets the number of lines to clamp. If not set, defaults to 3.
 */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"]
  static values = { lines: Number }

  connect() {
    this.applyLineClamp()
  }

  applyLineClamp() {
    const lineClampValue = this.linesValue || 3
    this.contentTarget.style.setProperty('--line-clamp', lineClampValue)
  }
}