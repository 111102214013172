import * as pdfMake from 'pdfmake/build/pdfmake';
import Konva from 'konva';

export default (function() {
  var AwardGenerator = function(optionData) {
    this.addEvents();
    this.optionData = optionData;

    this.stage = new Konva.Stage({
      container: 'award-viewpoint',
      width: 3300,
      height: 2550
    });

    this.layer = new Konva.Layer();
    this.stage.add(this.layer);

    this.fontfamily = this.getFontFamily();

    this.content = {};
    this.getContent();

    var $currentImg = $("#awardGenerator .current img");
    if ($currentImg[0].complete) {
      this.setBackground($currentImg);
    } else {
      $currentImg.one('load', function () {
        this.setBackground($currentImg);
      }.bind(this));
    }
  };

  AwardGenerator.prototype.getFontFamily = function() {
    var $el = $('#fontFamily');
    var that = this;

    $document.on('keyup', '#fontFamily', function(event) {
      for (var el in that.content) {
        that.content[el].fontFamily(event.target.value || 'bitter, serif');
      }

      that.layer.draw();
      that.fontFamily = event.target.value;
    });

    return $el.prop('value');
  };

  AwardGenerator.prototype.getContent = function() {
    var $controls = $(".text-options .control");
    var that = this;
    var currentAwardId = $('.certificateThumbnails .current').prop('id');

    $controls.removeClass("displayNone");

    $controls.each(function() {
      var $this = $(this);
      var id = $this.prop("id");
      var dataSet = that.optionData[currentAwardId][id];

      if (!dataSet) {
        if(that.content[id] !== undefined) {
          that.content[id].destroy();
          delete that.content[id];
        }
        return $this.addClass('displayNone');
      }

      var obj = {
        text: $this.find("input[type='text']").prop('value'),
        x: dataSet.x,
        y: dataSet.y,
        draggable: dataSet.draggable,
        fontFamily: dataSet.fontfamily || that.fontfamily,
        fill: dataSet.fill,
        width: dataSet.width,
        align: (dataSet.align || 'left'),
        fontSize: dataSet.fontsize,
        lineHeight: dataSet.lineheight,
        fontStyle: dataSet.fontstyle
      };

      if (obj.fontFamily !== that.fontfamily) {
        that.fontfamily = obj.fontFamily;
        $("#fontFamily").attr("value", that.fontfamily);
      }

      if (obj.fill) {
        $this.find("input[type=color]").prop('value', obj.fill);
      }

      if (that.content && that.content[id]) {
        for (var prop in obj) {
          that.content[id][prop](obj[prop]);
        }
      } else {
        that.content[$this.prop("id")] = that.addText(obj);
        that.setEvent($this);
      }
    });
  };

  AwardGenerator.prototype.setEvent = function($controlGroup) {
    var that = this;
    $controlGroup.find("input[type='color']").change(function(event) {
      that.content[$controlGroup.prop('id')].fill( event.target.value );
      that.layer.draw();
    });

    $controlGroup.find("input[type='text']").keyup(function(event) {
      that.content[$controlGroup.prop('id')].setText( event.target.value );
      that.layer.draw();
    });

    // To update url when inputs change.
    $controlGroup.find("input[type='color']").on('change', window.R.utils.debounce(function(event) {
      const id = $controlGroup.prop('id');
      const param = `${id}_color_code`;
      updateUrl({[`${param}`]: event.target.value});
    }, 300));

    $controlGroup.find("input[type='text']").on('keyup', window.R.utils.debounce(function(event) {
      const id = $controlGroup.prop('id');
      const param = `${id}`;
      updateUrl({[`${param}`]: event.target.value});
    }, 900));

    $(".certificateThumbnails > ul > li").on('click', function(){
      updateUrl({'style': $(this).prop('id')})
    });
  };

  function updateUrl(param){
    var newURL = window.location.href;
    newURL = window.R.utils.addParamsToUrlString(newURL, param);
    R.utils.pushHistoryWithTurbo(newURL);
  }

  AwardGenerator.prototype.addEvents = function() {
    var that = this;

    if ($(".certificateThumbnails").length) {
      $document.on(R.touchEvent, ".certificateThumbnails li:not(.current)", function() {
        $('.certificateThumbnails li.current').removeClass('current');
        $(this).addClass("current");
        that.getContent();
        that.setBackground( $(this).find('img') );
      });
    }

    $document.on('change', '#langauge-select', function() {
      window.location = R.utils.addParamsToUrlString('./service-anniversary', {'locale': this.value});
    });

    $document.on(R.touchEvent, '#export-pdf', function() {
      htmlToImage();
    });
  };

  function htmlToImage() {
    var pdf;
    var canvas = $("#award-viewpoint canvas")[0];
    var dataURI = canvas.toDataURL();
    console.log(dataURI);


    var dd = {
      pageSize: {
        width: 3300,
        height: 2550
      },

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'landscape',

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [ 0,0,0,0 ],
      content: [
        {
          height: 2550,
          width: 3300,
          image: dataURI,
          fit: [3300, 2550]
        }
      ]
    };

    pdf = pdfMake.createPdf(dd);
    pdf.download();
  }

  AwardGenerator.prototype.setBackground = function($img) {
    var width = window.innerWidth;
    var height = window.innerHeight;
    var that = this;

    if (this.imageNode) {
      this.imageNode.destroy();
    }

    // Refactored away from Konva.Image.fromUrl due to CORS issue
    // See: https://github.com/konvajs/konva/issues/382

      var image = new Konva.Image({
        image: $img[0]
      });
      image.width(3300);
      image.height(2550);
      that.layer.add(image);
      image.moveToBottom();
      that.layer.draw();
      that.imageNode = image;
  };

  AwardGenerator.prototype.addText = function(opts) {
    var that = this;

    var textNode = new Konva.Text(opts);

    this.layer.add(textNode);
    this.layer.draw();

    return textNode;
  };

  return AwardGenerator;
})();
