export default (function() {
  var AccountsOverview = function() {
    this.addEvents();
  };

  AccountsOverview.prototype.addEvents = function() {
    window.R.components.pieChart.call();
  }

  return AccountsOverview;
})();
