import '~/scripts/integrations/jquery-extended';
import FormErrors from '../lib/FormErrors';

window.R.Comments = (function() {
  var NAMESPACE = "#comments-";
  var Comments;
  var turbolinkEvent = "turbo:before-cache";

  Comments = function(afterAddCallback, isEditOnly) {
    this.addEvents(isEditOnly);
    this.afterAddCallback = afterAddCallback || function() {};
  };

  Comments.prototype.addEvents = function(isEditOnly) {
    $body.on(R.touchEvent, '.cancel-edit', function(e){
      var $form = $(e.target).parents('form.edit_comment');
      var loaderSrc = '/assets/icons/ajax-loader-medium.gif';
      var $loader = $("<div class='text-center'><img alt='loader' src='" + loaderSrc + "'></div>");
      // this content get replaced with original comment afterwards by issuing a GET comment request
      // which happens from elsewhere, and only if this form is removed first, so might as well show a loader
      $form.replaceWith($loader);
      $document.trigger("recognition:change");
      e.preventDefault();
    });

    $document.on("comment:change", R.utils.safe_feather_replace);

    if (isEditOnly)
      return;

    $(document).on("ajaxify:success:comment_add", this.add.bind(this));
    $window.bind("ajaxify:errors", this.handleError);

    // Note: these callbacks are relevant for plain-text form only and not wysiwyg
    //       using input tag in the selector here since .comment_content class is also used for the rendered div
    var commentInputSel = ".comments-wrapper input.comment_content";
    $body.on("keyup", commentInputSel, function() {
      var $btn = $(this).parent().next();
      if (this.value.trim() !== "") {
        $btn.attr("disabled", null);
      } else {
        $btn.attr("disabled", "disabled");
      }
    });
    $body.on("focusin", commentInputSel, function() {
      // skip wysiwyg form as the input will be expanded into wysiwyg textarea
      var $btn = $(this).parent().next();
      if ($btn.closest('.comments-form').hasClass('wysiwyg'))
        return;

      if (this.value.length === 0)
        $btn.attr("disabled", "disabled");
    });
    $body.on("focusout", commentInputSel, function() {
      var $btn = $(this).parent().next();
      $btn.attr("disabled", null);
      $btn.removeClass('d-none');
      $btn.css('display','block')
    });

    $document.bind(turbolinkEvent, this.removeEvents.bind(this));
  };

  Comments.prototype.setupCommentsWysiwygForNewFormIfRequired = function() {
    if ((gon.trumbowyg || {}).wysiwyg_editor_enabled) {
      var $newCommentForm = $('.new_comment');
      var $commentInput = $newCommentForm.find('.comment_content');
      // note: currentFormat is not needed here because this is for create flow only
      var inputFormatOpts = {
        $elem: $newCommentForm.find('.comment_input_format')
      }
      R.ui.initWYSIWYG($commentInput, inputFormatOpts, true);
      R.ui.watchCommentsWysiwygModalToDisableCommentSections(true);
    }
  };

  Comments.prototype.removeEvents = function() {
    $(document).off("ajaxify:success:comment_add");
    $window.unbind("ajaxify:errors", this.handleError);
    $document.unbind(turbolinkEvent, this.removeEvents.bind(this));
    $document.off("comment:change", R.utils.safe_feather_replace);
  };

  Comments.prototype.add = function(e, data) {
    var data = data.data.params;
    var wrapperId = NAMESPACE + data.recognition_id;
    var $wrapper = $(wrapperId);

    var $newCommentInput = $wrapper.find(".new_comment .comment_content");
    if ($newCommentInput.data('trumbowyg')) {
      $newCommentInput.trumbowyg('empty');
    } else {
      $newCommentInput.val('');
    }
    $wrapper.find(".comments-list-wrapper")
      .removeClass('hidden')
      .prepend( $( data.comment ) );
    this.afterAddCallback();

    $document.trigger("recognition:change");
  };

  /**
   * Note: this is for the create form only;
   *       the response for edit form is handled by comments/update.js.erb
   *       this handler is not triggered at all in that case because the xhr response code is always 200
   */
  Comments.prototype.handleError = function(e, formId, errors) {
    var errorMessage = (errors.comment_content || [])[0];
    var formErrors;
    if (errorMessage) {
      formErrors = new FormErrors($('#'+formId +' .controls'), {"base": [errorMessage]});
      formErrors.renderErrors();
    }

    $("#"+formId + " .btn").attr("disabled", null);

    $document.trigger("recognition:change");
  };

  return Comments;
})();
