import '~/scripts/integrations/jquery-extended';

export default (function() {
  function Settings() {
    this.turnOnToggles();
  }

  Settings.prototype.turnOnToggles = function() {
    var $inputs = $(".on-off:not(.remoteCheckboxToggle)"), that = this;

    $inputs.iOSCheckbox({
      onChange: function() {
        var $target = $(this.elem), data = {};
        var checkedValue = !!this.elem.prop("checked");
        if ($target.data('invert-values') === true) { // used as `invert_values` in view
          checkedValue = !checkedValue;
        }
        data[$target.data('setting')] = checkedValue;

        $.ajax({
          url: '/' + $body.data('name') + '/company/update_settings',
          type: "POST",
          data: { settings: data },
          success: function() {
            window.R.utils.renderFeedbackMessageAfterSave("Saved!", $target.closest('.checkbox-title'), 3000, "success");
          }
        });
      }
    });
  };

  return Settings;
})();
