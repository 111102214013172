export default (function() {
  var I = function() {
    this.cloneInput = new R.ui.CloneInput();
    this.addEvents();
  };

  I.prototype.addEvents = function() {
  };

  return I;
})();
