export default (function() {
  var Contact = function() {
    this.addEvents();
  }

  Contact.prototype.addEvents = function() {
  };

  return Contact;
})();
