import '~/scripts/integrations/jquery-extended';
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';
import Pagelet from '~/scripts/lib/Pagelet';

export default class TopEmployees {
  constructor() {
    this.addEvents();
  }

  addEvents() {
    new CompanyAdmin();
    this.pagelet = new Pagelet();
    this.dateRange = new window.R.DateRange({ container: $(".company_leaderboard") });

    $document.on(R.touchEvent, "#exportCSV-top-employees", function() {
      R.exportTableToCSV.apply(this, [$('#rank table'), 'recognize-top-employees.csv']);
    });
  }
}
