import '~/scripts/integrations/jquery-extended';
import AwardGenerator from '~/scripts/lib/AwardGenerator';

export default function() {
  var titleColor = $("#title").find("input[type=color]").val();
  var descriptionColor = $("#description").find("input[type=color]").val();
  var managerColor = $("#manager").find("input[type=color]").val();
  var dateColor = $("#date").find("input[type=color]").val();
  var signatureColor = $("#signature").find("input[type=color]").val();
  var nameColor = $("#name").find("input[type=color]").val();

  var awards = {
    calendar: {
      title: {
        x: 1710,
        y: 300,
        fontsize: 220,
        draggable: true,
        fill: titleColor,
        width: 1300,
        lineheight: 1.2,
        fontsytle: 'bold',
        fontfamily: 'Anton'
      },
      description: {
        x: 1710,
        y: 940,
        fontsize: 80,
        draggable: true,
        fill: descriptionColor,
        width: 1200,
        lineheight: 1.2,
        fontfamily: 'Anton'
      },
      manager: {
        align: 'left',
        x: 296,
        y: 2200,
        fontsize: 50,
        draggable: true,
        fill: managerColor,
        width: 500,
        fontfamily: 'Anton'
      },
      date: {
        align: 'center',
        x: 1415,
        y: 2040,
        fontsize: 50,
        draggable: true,
        fill: dateColor,
        width: 500,
        lineheight: 1.2,
        fontfamily: 'Anton'
      },
      signature: {
        align: 'left',
        x: 2545,
        y: 2200,
        fontsize: 50,
        draggable: true,
        fill: signatureColor,
        width: 540,
        fontfamily: 'Anton'
      },
      name: {
        align: 'center',
        x: 1530,
        y: 1600,
        fontsize: 120,
        draggable: true,
        fill: nameColor,
        width: 1500,
        fontfamily: 'Anton'
      },
    },
    classic: {
      title: {
        x: 460,
        y: 650,
        fontsize: 170,
        draggable: true,
        fill: titleColor,
        width: 900,
        lineheight: 1.2,
        fontstyle: 'bold',
        fontfamily: 'Playfair Display',
        align: 'center'
      },
      description: {
        x: 1780,
        y: 440,
        fontsize: 80,
        draggable: true,
        fill: descriptionColor,
        width: 1200,
        lineheight: 1.2,
        fontfamily: 'Playfair Display'
      },
      manager: {
        align: 'center',
        x: 135,
        y: 2250,
        fontsize: 50,
        draggable: true,
        fill: managerColor,
        width: 500,
        fontfamily: 'Playfair Display'
      },
      date: {
        align: 'center',
        x: 1415,
        y: 2050,
        fontsize: 60,
        draggable: true,
        fill: dateColor,
        width: 500,
        lineheight: 1.2,
        fontfamily: 'Playfair Display'
      },
      signature: {
        align: 'center',
        x: 2670,
        y: 2250,
        fontsize: 50,
        draggable: true,
        fill: signatureColor,
        width: 540,
        fontfamily: 'Playfair Display'
      },
      name: {
        align: 'center',
        x: 1730,
        y: 1500,
        fontsize: 120,
        draggable: true,
        fill: nameColor,
        width: 1500,
        fontfamily: 'Playfair Display'
      },
    },
    modern: {
      title: {
        x: 500,
        y: 540,
        fontsize: 220,
        draggable: true,
        fill: titleColor,
        width: 1300,
        lineheight: 1.2,
        fontsytle: 'bold',
        fontfamily: 'Anton'
      },

      date: {
        align: 'center',
        x: 1215,
        y: 1810,
        fontsize: 50,
        draggable: true,
        fill: dateColor,
        width: 500,
        lineheight: 1.2,
        fontfamily: 'Anton'
      },
      signature: {
        align: 'center',
        x: 545,
        y: 1900,
        fontsize: 50,
        draggable: true,
        fill: signatureColor,
        width: 540,
        fontfamily: 'Anton'
      },
      name: {
        align: 'center',
        x: 530,
        y: 1350,
        fontsize: 120,
        draggable: true,
        fill: nameColor,
        width: 1500,
        fontfamily: 'Anton'
      },
    }
  };

  this.awardCert = new AwardGenerator(awards);
};
