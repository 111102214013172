import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.bindRemoteCheckbox();
  }
  
  bindRemoteCheckbox = function() {
    $(this.element).on('change', (e) => {
      let data = {};
      let endpoint = this.element.dataset.endpoint;
      let checkedValue = this.element.checked;
      data[this.element.name] = checkedValue;

      if(endpoint){
        $.ajax({
          url: endpoint,
          type: this.element.dataset.method,
          data: data
        })
      } else {
        console.warn("Endpoint data attribute is missing on checkbox");
      }
    })
  }

  disconnect() {
    $(this.element).off('change');
  }
}
