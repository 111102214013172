import '~/scripts/integrations/jquery-extended';
import Swal from 'sweetalert2';
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';

export default (function() {
  var Rewards = function() {
    this.addEvents();
  };

  Rewards.prototype.addEvents = function() {
    var companyAdmin = new CompanyAdmin();

    $document.on(R.touchEvent, "#exportCSV-redeemed", function() {
      R.exportTableToCSV.apply(this, [$('#reward-redeemed-table'), 'recognize-rewards-redemptions.csv']);
    });

    $document.on(R.touchEvent, "#exportCSV-rewards", function() {
      R.exportTableToCSV.apply(this, [$('#rewards'), 'recognize-rewards-catalog.csv']);
    });
    $document.on("ajax:complete", "a.reward-status-toggle[data-remote]", rewardsStatusToggle);

    this.$table = R.LitaTables['rewards'].$table;

    this.bindCategorySelect2();

  };

  Rewards.prototype.bindCategorySelect2 = function() {
    this.$table.on('draw.dt', function (e, settings, json, xhr) {
      R.utils.remoteSelect2();
    });
  }

  function rewardsStatusToggle(e, response) {
    "use strict";
    if (response.status === 200) {
      var $this = $(e.target);
      $this.text($this.data('reward-status'));
    } else if (response.responseJSON && response.responseJSON.errors) {
      var title = response.responseJSON.errorTitle || 'An error occurred.';
      var errors = "<ul>" + response.responseJSON.errors.map(function(i){
        return '<li>' + i + '</li>';
      }).join("") + "</ul>";
      Swal.fire({icon: "error", title: title, html: errors});
    }
  }

  return Rewards;
})();
