import '~/scripts/integrations/jquery-extended';
import Swal from 'sweetalert2';
import 'animate.css';

window.R.GatedFeature = (function()  {
  $document.on("turbo:load", function() {
    var hasGatedFeatureModal = $(".gated-feature").length > 0;

    if(hasGatedFeatureModal) {
      $(".gated-feature [data-remote=true]:not(.bypass-feature-gating)").data('remote', false);
      // unbind existing touchevent before adding the event to avoid attaching the event several times.
      $document.off(R.touchEvent, '.gated-feature').on(R.touchEvent, '.gated-feature', function(e){
        getGatedFeature(e);
      });
    }
  });

  function getGatedFeature(e){
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    var html = '';
    var selector = $(e.currentTarget);
    var data = {
      title: selector.attr('data-title'),
      text: selector.attr('data-text'),
      video_url: selector.attr('data-video'),
      image_url: selector.attr('data-image'),
      confirm_btn: selector.attr('data-confirm'),
      cancel_btn: selector.attr('data-cancel')
    }
    if (data.video_url && data.video_url.length > 0){
      html = '<iframe src="' + data.video_url + '" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="width: 100%; height: 280px;"></iframe>'
    } else if (data.image_url && data.image_url.length > 0) {
      html = '<img src="' + data.image_url + '" style="max-width:350px" class="marginTop10">'
    }

    Swal.fire({
      title: data.title,
      html: '<div><strong>'+ data.text +'</strong></div>' + html,
      showCancelButton: data.cancel_btn,
      showCloseButton: true,
      customClass: "upgrade-confirm-button",
      cancelButtonText: data.cancel_btn, // application_helper.rb generates these
      confirmButtonText: data.confirm_btn, // in order to have the buttons localized
      showClass: { popup: 'animate__animated animate__fadeInDown animate__faster' },
      hideClass: { popup: 'animate__animated animate__fadeOutUp animate__faster' }
    }).then(function(result) {
      if (result.value) {
        window.open('/contact?body=' + selector.attr('data-upgrademsg'), '_blank');
      }
    });
  }
})();
