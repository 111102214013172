export default (function() {
  var Failure = function() {
    var currentDate = new Date();
    var options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    var userTime = currentDate.toLocaleTimeString('en-US', options);
    var userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    document.getElementById('timestamp').textContent = userTime + ' ' + userTimeZone;
  };

  return Failure;
});
