import '~/scripts/integrations/jquery-extended';
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';
import Select2 from '~/scripts/lib/Select2.js'


export default class {
  constructor() {
    this.pageId = $("body").prop('id');
    this.addEvents();
  }

  addEvents() {
    new CompanyAdmin();
    this.initGroupSegmentEvents();
    this.initChannelToggleEvents();
    this.initDynamicContentTagEvents();
    this.initWysiwigs();
  }

  removeEvents() {
    $(this.pageId + " .content-wrapper *").off();
    R.ui.destroyWYSIWYG($('#announcement_post_body'));
    R.ui.destroyWYSIWYG($('#announcement_email_body'));
    $document.off('click', '#template-tags .badge');
    $document.off('change', '#announcement_send_email');
    $document.off('click', '#sms-template-tags .badge');
  }

  initWysiwigs() {
    if ((gon.trumbowyg || {}).wysiwyg_editor_enabled || gon.input_format) {
      var inputFormatOpts = {
        $elem: $("#announcement_input_format")
      };
      R.ui.initWYSIWYG($('#announcement_post_body'), inputFormatOpts);
      R.ui.initWYSIWYG($('#announcement_email_body'), inputFormatOpts, false, false);
    }
  }

  initDynamicContentTagEvents() {
    $document.on('click', '#template-tags .badge', function () {
      var isBroadcastsNewPage = $body.attr("id") === "company_admin_accounts_broadcasts-new";
      var emailEnableToggledOn = $(this).parent().parent().find("#announcement_send_email").prop('checked');
      if (isBroadcastsNewPage || emailEnableToggledOn) {
        var template = $(this).data('url');
        var $announcementEmailBody = $("#announcement_email_body");
        if ($('.trumbowyg')[0] === undefined) {
          return $announcementEmailBody[0].innerHTML += template + "\n";
        }
        $announcementEmailBody.trumbowyg('html', $announcementEmailBody.trumbowyg('html') + "<p>"+template+"</p>");
      } else {
        return false;
      }
    });

    $document.on('click', '#sms-template-tags .badge', function () {
      var smsEnableToggledOn = $(this).parent().parent().find("#announcement_send_sms").prop('checked');
      if (smsEnableToggledOn) {
        var template = $(this).data('url');
        var $announcementSmsBody = $("#announcement_sms_body");
        var existingContent = $announcementSmsBody.val();
        $announcementSmsBody.val(existingContent + template + "\n");
      } else {
        return false;
      }
    });
  }

  initGroupSegmentEvents() {
    new Select2(function () {
      $('#by_role .select2').select2(R.utils.companyRoleSelect2TemplateOpts());
    });
    $document.on(R.touchEvent, '#announcement_group_segment_everyone', function() {
      $('#post-stream-inputs').show().removeClass('d-none');
      $('.group-segment-details').hide();
      $('#everyone').show();
    });

    $document.on(R.touchEvent, '#announcement_group_segment_pending', function() {
      $('#post-stream-inputs').hide();
      $('.group-segment-details').hide();
      $('#pending').show().removeClass('d-none');
    });

    $document.on(R.touchEvent, '#announcement_group_segment_by_role', function() {
      $('#post-stream-inputs').show().removeClass('d-none');
      $('.group-segment-details').hide();
      $('#by_role').show().removeClass('d-none');
    });

    $document.on(R.touchEvent, '#announcement_group_segment_by_status', function() {
      $('#post-stream-inputs').hide();
      $('.group-segment-details').hide();
      $('.select2').select2();
      $('#by_status').show().removeClass('d-none');
    });

    $document.on(R.touchEvent, '#announcement_group_segment_by_team', function() {
      $('#post-stream-inputs').show().removeClass('d-none');
      $('.group-segment-details').hide();
      $('.select2').select2();
      $('#by_team').show().removeClass('d-none');
    });
  }

  initChannelToggleEvents() {
    $document.on('change', '#announcement_send_email', function () {
      var checked = this.checked;
      var $wysiwygEl = $('#announcement_email_body');

      $('#announcement_email_subject').prop('disabled', !checked);
      $('#announcement_email_body').prop('disabled', !checked);

      if(checked) {
        R.ui.enableWYSIWYG($wysiwygEl);
      } else {
        R.ui.disableWYSIWYG($wysiwygEl);
      }
    });

    $document.on('change', '#announcement_send_sms', function () {
      var checked = this.checked;
      $('#announcement_sms_body').prop('disabled', !checked);
    });
  }
}
