import ReadMore from '@stimulus-components/read-more'
import { useResize } from "stimulus-use"

export default class extends ReadMore {
  static targets = ["button"]

  connect(){
    super.connect();
    useResize(this);
  }

  resize() {
    let overflowHeight = this.contentTarget.scrollHeight - this.contentTarget.offsetHeight;

    if (overflowHeight >= 10) { this.buttonTarget.style.display = 'block' }
  }
}
