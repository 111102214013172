import '~/scripts/integrations/jquery-extended';

export default (function() {
  var TeamsIndex = function() {
    this.favoriteTeam = this.setupFavoriteTeam();
    this.clearQueryInput();
  };

  TeamsIndex.prototype.removeEvents = function() {
    this.clearQueryInput();
    this.favoriteTeam.removeEvents();
  };

  TeamsIndex.prototype.clearQueryInput = function() {
    $('#query').val('');
  };

  TeamsIndex.prototype.setupFavoriteTeam = function() {
    return new window.R.ui.FavoriteTeam();
  };

  return TeamsIndex;

})();
