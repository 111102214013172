import '~/scripts/integrations/jquery-extended';
import Select2 from '~/scripts/lib/Select2.js'
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';

export default (function() {
  var AnniversaryCalendar = function() {
    this.addEvents();
  };

  AnniversaryCalendar.prototype.addEvents = function() {
    var companyAdmin;

    companyAdmin = new CompanyAdmin();
    new Select2(function(){
      $(".select2").select2();
    });
  };

  AnniversaryCalendar.prototype.removeEvents = function() {
  };

  return AnniversaryCalendar;
})();
