import { Controller } from "@hotwired/stimulus"
import Select2 from '~/scripts/lib/Select2.js'

export default class extends Controller {
  connect() {
    R.utils.safe_feather_replace();

    new Select2(function(){
      let $catalogSelect = $("#rewards_search_catalog_id").select2();
      $catalogSelect.on('select2:select', this.visitCatalog);

      let $countrySelect = $("#rewards_search_country").select2();
      $countrySelect.on('select2:select', function() {this.submitForm()}.bind(this));
    }.bind(this));

    this.element.querySelectorAll('.pagination a').forEach(link => {
      link.setAttribute('data-turbo-action', 'advance');
    });

    document.addEventListener('turbo:frame-load', () => {
      // reload select2 when a component connected to stimulus is replaced with turbo
      $('.select2').select2();
    });
  }

  showAllRewardCategories(evt) {
    evt.preventDefault();
    var $target = $(evt.target);
    $target.siblings("a.showNoneRewardCategories").removeClass('hidden');
    $target.addClass('hidden');
    $("#rewardCategories input[type=checkbox]").prop('checked', true);
    this.submitForm();
  }

  showNoneRewardCategories(evt) {
    evt.preventDefault();
    var $target = $(evt.target);
    $target.siblings("a.showAllRewardCategories").removeClass('hidden');
    $target.addClass('hidden');
    $("#rewardCategories input[type=checkbox]").prop('checked', false);
    this.submitForm();
  }

  toggleFilter(evt) {
    this.submitForm();
  }

  toggleSingleSelectFilter(evt) {
    this.highlightSingleSelectCategory(evt.target.value);
    this.submitForm();
  }

  landingPageCategoryLink(evt) {
    var category = evt.target.getAttribute('data-categoryname');
    if (gon.multipleSelectCategories) {
      this.highlightMultiSelectCategory(category);
    } else {
      this.highlightSingleSelectCategory(category);
    }
  }

  highlightSingleSelectCategory(category) {
    var highlightClass = "active-category-link";
    $("#rewardCategories label[for=" + category + "]").addClass(highlightClass);
    $("#rewardCategories label").not("[for=" + category + "]").removeClass(highlightClass);
    $("#clearCategories").removeClass('hidden');
  }

  highlightMultiSelectCategory(category) {
    $("#rewardCategories input[value='" + category + "']").prop('checked', true);
  }

  keywordSearch(evt) {
    // only if enter key is pressed
    if(evt.keyCode !== 13) return;

    evt.preventDefault();
    this.submitForm();
  }

  submitForm() {
    $("#rewards-cards").css({opacity: 0.5})
    $("#landing-page-categories-container").css({opacity: 0.5})
    this.element.requestSubmit();
  }

  visitCatalog() {
    var basepath = this.dataset.redirectpath;
    // R.utils.addParamsToUrlString doesn't seem to support nested keys
    // so we pass 'rewards_search[catalog_id]' as a string
    var updatedPath = R.utils.addParamsToUrlString(basepath, {'rewards_search[catalog_id]': this.value});
    Turbo.visit(updatedPath);
  }

  clearCategorySelectionFromSidebar(e) {
    e.preventDefault();

    $('#rewardCategories input').prop('checked', false).removeAttr('checked');
    $("#categories_sidebar label").removeClass("active-category-link");
    this.submitForm();
    $("#clearCategories").addClass('hidden');
  }
}
