import '~/scripts/integrations/jquery-extended';

// This handles mention tagging in html-editors.
// This can be initialized for any editor that supports
// `Window.getSelection()`.
// see: https://developer.mozilla.org/en-US/docs/Web/API/Window/getSelection

window.R.MentionTagging = function () {
  var MentionTagging = function(targetEl, isTrumbowyg) {
    this.targetEl = targetEl;
    this.isTrumbowyg = isTrumbowyg;
    this.editor = isTrumbowyg ? this.targetEl.siblings(".trumbowyg-editor") : this.targetEl;
    this.symbol = '@';
    this.showingDropdown = false;
    // To set the position where the dropdown is to be shown.
    this.dropdownRenderPosX = undefined;
    this.dropdownRenderPosY = undefined;
    this.dropdownSelector = ".mentionable-users";

    // keys supported in mention dropdown
    // for navigating, selecting or canceling.
    this.arrowUpKey = "ArrowUp";
    this.arrowDownKey = "ArrowDown";
    this.escapeKey = "Escape";
    this.tabKey = "Tab";
    this.enterKey = "Enter";
    this.selectionKeys = [this.arrowUpKey, this.arrowDownKey, this.escapeKey, this.tabKey, this.enterKey];
    this.addEvents();
  };

  MentionTagging.prototype.addEvents = function() {
    this.listenToEditorChanges();
    $document.one("turbo:before-cache", this.removeEvents.bind(this));
  };

  MentionTagging.prototype.removeEvents = function() {
    $(document).off('mousedown');
    this.removeMentionDropdown();
  };

  MentionTagging.prototype.listenToEditorChanges = function() {
    var mt = this;
    this.editor.on("keydown", function(event) {
      if (mt.showingDropdown) {
        if (mt.selectionKeyPressed(event.key)) {
          event.preventDefault();
          event.stopPropagation();
          mt.handleSelectionKeyPressed(event);
        }
      }
      if(event.key === 'Backspace'){
        mt.handleBackspaceOnMentionComponent(event);
      }
    });

    mt.handleTriggeringMentionDropdown();

    this.listenChangesToMentions();
  };

  MentionTagging.prototype.listenChangesToMentions = function(){
    var mt = this;
    this.editor.find("a[name='mentioned-user']").each(function(index, element){
      mt.listenToChangesToMentionComponent(element);
    });
  }

  MentionTagging.prototype.selectionKeyPressed = function(key) {
    return this.selectionKeys.includes(key);
  };

  MentionTagging.prototype.handleSelectionKeyPressed = function(event) {
    var dropdown = $(this.dropdownSelector);
    var selectedOption = dropdown.find('li.active')[0];
    var indexForSelectedOption = dropdown.find('li.active').index();
    var totalOptions = dropdown.children('li').length;
    var indexToBeSelected;
    if (event.key === this.arrowUpKey) {
      indexToBeSelected = (indexForSelectedOption === 0) ? totalOptions - 1 : indexForSelectedOption - 1;
      handleArrowUpDownSelection(dropdown, selectedOption, indexToBeSelected);
    }
    if (event.key === this.arrowDownKey) {
      indexToBeSelected = (indexForSelectedOption === totalOptions - 1) ? 0 : indexForSelectedOption + 1;
      handleArrowUpDownSelection(dropdown, selectedOption, indexToBeSelected);
    }
    if ([this.tabKey, this.enterKey].includes(event.key)) {
      $(selectedOption).trigger("click");
    }
    if (event.key === this.escapeKey) {
      this.removeMentionDropdown();
    }
  };

  function handleArrowUpDownSelection(dropdown, selectedOption, indexToBeSelected) {
    $(selectedOption).removeClass("active");
    var element = dropdown.children('li').get(indexToBeSelected);
    if (!$(element).hasClass('active')) {
      $(element).addClass('active');
    }
    $(element).get(0).scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start'
    });
  }
  MentionTagging.prototype.handleTriggeringMentionDropdown = function(){
    var mt = this;
    // Using debounce to prevent queries for
    // each keypress after '@' is triggered.
    this.editor.on('keyup', window.R.utils.debounce(function(event) {
      if (!mt.selectionKeyPressed(event.key)) {
        // Need to save the range to retrieve it later when listening to
        // mention dropdown selection (monitorUserOptionSelection).
        // Clicking(mouse) in the mention option behaves weirdly otherwise.
        mt.range = window.getSelection().getRangeAt(0);
        // read word if the parentnode for the range is not the link element
        var currentElement = getElementInRange(mt.range);
        if(!isMentionComponent(currentElement)){
          var word = mt.readWordAtCaret();
          if (word.startsWith(mt.symbol)) {
            mt.setDropdownRenderPosition();
            var searchTerm = word.replace(mt.symbol, '');
            mt.queryCoworkers(searchTerm);
          } else {
            mt.removeMentionDropdown();
          }
        }
        else {
          mt.removeMentionDropdown();
        }
      }
    }, 400));

    $(document).on('mousedown', function(e) {
      if (mt.showingDropdown) {
        if ($(e.target).closest(mt.dropdownSelector).length === 0) {
          mt.removeMentionDropdown();
        }
      }
    });
  }

  MentionTagging.prototype.handleBackspaceOnMentionComponent = function(event){
    var selection = window.getSelection();
    var range = selection.getRangeAt(0);
    var currentElement = getElementInRange(range);

    if (isMentionComponent(currentElement)) {
      event.preventDefault();
      if(range.startContainer.nodeType !== 3){
        range.selectNodeContents(currentElement.childNodes[0]);
        range.collapse(false);
      }
      var textNode = range.startContainer;
      var text = textNode.textContent;
      var start = range.startOffset;
      var end = range.endOffset;
      // See `listenToChangesToMentionComponent`
      $(currentElement).addClass("deleting-word");
      // Go back from caret until you have encountered space or '@'
      while (start > 1 && !/\s/.test(text[start - 1])) {
        start--;
      }
      // Go forwards from caret until you have encountered space
      while (end < text.length && !/\s/.test(text[end])) {
        end++;
      }
      // The next two loops is to clear out any extra spaces between words.
      while(start > 1 && /\s/.test(text[start - 1])){
        start--;
      }
      while (end < text.length && /\s/.test(text[end + 1])) {
        end++;
      }
      // This is to clear out space after '@' and the next word
      if(start === 1 && end < text.length){
        end++;
      }
      range.setStart(textNode, start);
      range.setEnd(textNode, end);
      range.deleteContents();
      if(currentElement.innerHTML === this.symbol){
        $(currentElement).remove();
        if(this.isTrumbowyg){
          // If this was the only element in the first line without any siblings (text or otherwise)
          // and you remove it, the subsequent character insertion will be outside of the p tag
          // which presents issues. This is only needed for chrome/safari (webkit) browsers. 
          document.execCommand('formatBlock', false, 'p');
        }
      }
      else{
        var text = range.startContainer.textContent;
        // set caret at the end of the element if there is only one word after removal.
        if(text.trim().split(' ').length === 1){
          range.setStartAfter(currentElement);
        }
        addRangeToSelection(range);
      }
    }
  }

  function getElementInRange(range){
    var element;
    if (rangeSelectsSingleNode(range)) {
      // Selection encompasses a single element
      element = range.startContainer.childNodes[range.startOffset - 1];
    } else if (range.startContainer.nodeType === 3) {
      // Selection range starts inside a text node, so get its parent
      element = range.startContainer.parentNode;
    } else {
      // Selection starts inside an element
      element = range.startContainer;
    }
    return element;
  }

  function rangeSelectsSingleNode(range) {
    var startNode = range.startContainer;
    return startNode === range.endContainer &&
           startNode.hasChildNodes() &&
           range.endOffset === range.startOffset;
  }

  function isMentionComponent(element){
    if(element){
      return element.tagName === "A" && element.getAttribute("name") === "mentioned-user";
    } else{
      return false;
    }
  }

  MentionTagging.prototype.listenToChangesToMentionComponent = function(mentionElement){
    var callback = function(mutationList, observer) {
      // To check if there are additional nodes created.
      // For example: When pressing 'Enter' on the component.
      var childMutation = mutationList.find(function (x) {
        return x.type === 'childList';
      });
      // To check if the content of the mention component is edited.
      var characterMutation = mutationList.find(function (x) {
        return x.type === 'characterData';
      });
      // To check if there has been changes to the attributes of the component.
      var attributesMutation = mutationList.find(function (x) {
        return x.type === 'attributes';
      });

      // Checks if the change in content is due to backspace word removal.
      // If yes, then it simply removes the class identifier for it else
      // it unwraps the content of the element.
      if(attributesMutation && $(attributesMutation.target).hasClass("deleting-word")){
        var element = attributesMutation.target;
        $(element).removeClass('deleting-word');
      }
      else{
        if(characterMutation){
          var element = characterMutation.target.parentNode;
          var sel = window.getSelection();
          var range = sel.getRangeAt(0);
          var start = range.startOffset;
          var end = range.endOffset;
          var node = $(element).contents().unwrap();
          range.selectNodeContents(node[0]);
          range.setStart(node[0], start);
          range.setEnd(node[0], end);
          addRangeToSelection(range);
        }
        if(childMutation){
          var addedNodes = childMutation.addedNodes;
          addedNodes.forEach(function(node){
            var element = node.parentNode;
            $(element).contents().unwrap();
          });
        }
      }
    };

    var observer = new MutationObserver(callback);

    observer.observe(mentionElement, {
      childList: true,
      attributes: true,
      attributeFilter: ['class'],
      characterData: true,
      subtree: true
    });
  }

  MentionTagging.prototype.readWordAtCaret = function(range) {
    var range = range || this.range;
    var start = this.getRangeBoundariesAndText(range)[0];
    var end = this.getRangeBoundariesAndText(range)[1];
    var text = this.getRangeBoundariesAndText(range)[2];
    // From caret position go back until you encounter space
    while (start > 0 && !/\s/.test(text[start - 1])) {
      start--;
    }
    // From caret position go forward until you encounter space
    while (end < text.length && !/\s/.test(text[end])) {
      end++;
    }
    // substring word from text with the new boundary
    var word = text.substring(start, end);
    return word;
  };

  // Sets the `left` and `top` positions for the dropdown
  // to be displayed with respect to the position of '@'.
  MentionTagging.prototype.setDropdownRenderPosition = function() {
    var start = this.getRangeBoundariesAndText()[0];
    var end = this.getRangeBoundariesAndText()[1];
    var text = this.getRangeBoundariesAndText()[2];
    var tempStart = start;
    // From caret position go back until you encounter ' @'
    if(/\s/.test(text[tempStart - 1]) || tempStart === 0) {
      tempStart++;
    } else if (tempStart !== 1) {
      while (tempStart > 0 && !/\s/.test(text[tempStart - 1]) && !(/@/.test(text[tempStart - 1]) && /\s/.test(text[tempStart - 2]))) {
        tempStart--;
      }
    }
    var range = this.range;
    var startContainer = range.startContainer;
    // Temporarily set the range's boundaries
    // (caret position) to be just after @
    range.setStart(startContainer, tempStart);
    range.setEnd(startContainer, tempStart);
    // get its position relative to the viewport
    // and set the position
    var rect = range.getBoundingClientRect();
    var editorRect = this.editor.get(0).getBoundingClientRect();
    var offsetX = 0;
    var offsetY = 0;
    if (this.isTrumbowyg) {
      var trumbowygButtonPaneRect = this.editor.siblings('.trumbowyg-button-pane').get(0).getBoundingClientRect();
      var fontSize = this.editor.find('p').css('font-size');
      offsetY = trumbowygButtonPaneRect.height + parseInt(fontSize) + 5;
    }
    this.dropdownRenderPosX = rect.x - editorRect.x + offsetX;
    this.dropdownRenderPosY = rect.y - editorRect.y + offsetY;
    // restore range boundaries
    range.setStart(startContainer, start);
    range.setEnd(startContainer, end);
  };

  MentionTagging.prototype.getRangeBoundariesAndText = function (range) {
    var range = range || this.range;
    var start = range.startOffset;
    var end = range.endOffset;
    var text = range.startContainer.textContent || '';
    return [start, end, text];
  };

  MentionTagging.prototype.queryCoworkers = function (searchTerm) {
    var mt = this;
    var isPrivate = mt.targetEl.data("is-private");
    var recognitionId = mt.targetEl.data("recognition-id");
    var queryCoworkersUrl = "/coworkers?include_self=false&term=".concat(searchTerm, "&limit=30");
    if(isPrivate) { queryCoworkersUrl += `&is_private=true&recognition_id=${recognitionId}` }

    $.ajax({
      url: queryCoworkersUrl,
      dataType: 'json',
      method: "post",
      success: function(res) {
        // Reading word here again because we query Coworkers
        // 0.4 secs after user input to prevent redundant queries
        // after each key press and there might be instances where
        // a user selects a user from dropdown while another query is running
        // which will display another dropdown from the new requests alongside
        // the new user link.
        var word = mt.readWordAtCaret();
        if (res.length && word.startsWith(mt.symbol)) {
          mt.createAndShowDropdown(res);
        } else {
          mt.removeMentionDropdown();
        }
      }
    });
  };

  MentionTagging.prototype.createAndShowDropdown = function (res) {
    var selectList = this.createDropdown(res);
    this.showDropdown(selectList);

    // This causes the whole page to move in IE.
    // TODO: Remove the conditional after we have deprecated IE fully.
    if(!window.R.utils.isIE()){
      selectList.get(0).scrollIntoView({ behavior: 'smooth', block: 'nearest'});
    }
    // Fix for chrome
    if(scrollbarVisible(selectList.get(0))){
      selectList.find('li:first-child').css({'border-top-right-radius': 'unset' })
      selectList.find('li:last-child').css({'border-bottom-right-radius': 'unset'})
    }
  };

  MentionTagging.prototype.createDropdown = function(res){
    var selectList = $(this.dropdownSelector).length ? $(this.dropdownSelector) : $('<ul role="listbox" class="mentionable-users"></ul>');
    var listArr = [];
    res.forEach(function (userData, index) {
      var userLabel = userData.label;
      if (userData.avatar_thumb_url === R.defaultAvatarPath) {
        userData.avatar_thumb_url = "/assets/" + R.defaultAvatarPath;
      }
      var listClasses = ['mention-option'];
      if (index === 0) {
        listClasses.push('active');
      }
      // Instead of storing fixed urls in db, user mentions are linked when rendering.
      // The reason we are using an `<a>` tag here instead of something else like `<span>`
      // is because of the follwing issue with other tags and webkit browsers (chrome/safari):
      // Typing anything after dropping the tag would put the characters inside the span tag instead
      // of outside of it. see: https://stackoverflow.com/a/15814297/10057223
      var listElement = "<li role=\"option\" class='" + listClasses.join(' ') + "' id=user_" + userData.recognize_hashid +
        " data-value=\"<a href='#' data-mentionable_user_id=" + userData.recognize_hashid + " name='mentioned-user'>@" + userLabel +
        "</a>\"><img src=" + userData.avatar_thumb_url + " /><p>" + userLabel + "</p></li>";
      listArr.push(listElement);
    });
    selectList.html(listArr.join(' '));
    selectList.css({
      "position": "absolute",
      "left": "".concat(this.dropdownRenderPosX, "px"),
      "top": "".concat(this.dropdownRenderPosY, "px")
    });
    return selectList;
  }

  MentionTagging.prototype.showDropdown = function(selectList){
    this.editor.parent().append(selectList);
    this.monitorUserOptionSelection();
    this.showingDropdown = true;

    this.commentableCard = selectList.closest(".commentable-card")
    if(this.commentableCard.length){
      this.commentableCard.first().addClass("mention-select-open");
    }
  }

  function scrollbarVisible(element) {
    return element.scrollHeight > element.clientHeight;
  }

  MentionTagging.prototype.removeMentionDropdown = function () {
    this.showingDropdown = false;
    $(this.dropdownSelector).remove();
    if(this.commentableCard && this.commentableCard.length){
      this.commentableCard.first().removeClass("mention-select-open");
    }
  };

  MentionTagging.prototype.monitorUserOptionSelection = function () {
    var mt = this;
    $(this.dropdownSelector + " li").on("click", function (e) {
      mt.removeMentionDropdown();
      var word = $(this).data("value");
      var mentionNode = $(word).get(0);
      var range = mt.range;
      var start = mt.getRangeBoundariesAndText()[0];
      var end = mt.getRangeBoundariesAndText()[1];
      var text = mt.getRangeBoundariesAndText()[2];
      while (start > 0 && !/\s/.test(text[start - 1])) {
        start--;
      }
      while (end < text.length && !/\s/.test(text[end])) {
        end++;
      }
      range.setStart(range.startContainer, start);
      range.setEnd(range.startContainer, end);
      range.deleteContents();

      if(mt.isTrumbowyg){
        // Trumbowyg by default wraps the characters entered in the editor in '<p>' tags. It also
        // uses them to separate lines. But sometimes, you can enter characters outside
        // of the p tags and directly inside the 'div.trumbowyg-editor'. For example:
        //
        // In firefox: Click on the editor, inspect the element and you will see a <p> tag.
        //             Press backspace, you can see the p tags get removed and anything typed
        //             will be added as a direct child to the div.trumbowyg-editor
        // In Chrome: There is no direct way to test this in chrome because pressing backspace deletes
        // (webkit)   the <p> tag but it also gets immediately added back. This, however, is still an
        //            issue because if there is a mention component in the first line without any other
        //            elements or text and you remove it with backspace (which prevents the default behaviour),
        //            anything else you type after the removal is placed outside of the p tag.
        //            To see the issue in action, you can search and comment out the
        //            `document.execCommand('formatBlock', false, 'p')` instances in this file.
        // Note: Pressing enter automatically seems to wrap the contents of the line in a p tag but
        //       there will be issues with the position of caret because the node for the selection is not correct
        //       on account of the textnode and link node being a direct child of the div.trumbowyg-editor.
        // The `document.execCommand('formatBlock', false, 'p')` is directly taken from the trumbowyg.js code.
        // and although it is deprecated, there isn't a better alternative yet and as long as trumbowyg uses it
        // we can use it.
        if(range.startContainer.parentNode.nodeName === "DIV"){
          range.insertNode(mentionNode);
          document.execCommand('formatBlock', false, 'p')
        } else {
          range.insertNode(mentionNode);
        }
        // syncs the content of trumbowyg-editor to textarea
        mt.targetEl.data('trumbowyg').syncCode();
      } else {
        range.insertNode(mentionNode);
      }
      range.setStartAfter(mentionNode);
      range.setEndAfter(mentionNode);
      // Required to set the caret position when user selects with
      // a mouse click. Need to delete all ranges and reattach it.
      addRangeToSelection(range);
      mt.editor.focus();
      mt.listenToChangesToMentionComponent(mentionNode);
    });
  };

  // Any changes to range should be followed by this
  // otherwise there will be issues in safari.
  function addRangeToSelection(range){
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }

  return MentionTagging;
}();
