import Select2 from '~/scripts/lib/Select2.js'
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';

export default (function() {
    var Catalog = function() {
        this.addEvents();
    };

    Catalog.prototype.addEvents = function() {
        new CompanyAdmin();
        new Select2(function() { $("#filter_catalog_status").select2(); });
    };

    Catalog.prototype.removeEvents = function() {};

    return Catalog;
})();

