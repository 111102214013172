import { Controller } from "@hotwired/stimulus"
import Select2 from '~/scripts/lib/Select2.js'

export default class extends Controller {
  connect() {
    this.bindSyncGroupsSelect();
    this.bindSyncGroupFilterSettings();
    R.utils.safe_feather_replace();
  }

  disconnect() {
    this.unbindSyncGroupsSelect();
  }

  selectSyncProvider(event) {
    $(event.target).parent("form")[0].requestSubmit();
  }

  bindSyncGroupsSelect() {
    $(".company_setting_sync_groups_container").bind('pageletLoaded', function(evt) {

      var $target = $(evt.target);

      new Select2(function() {
        var $selectWidget = $target.find('select.company-setting-sync-groups');

        var config = {}; // default
        var url = $selectWidget.data('url');
        var lazyLoad = $selectWidget.data('lazyload');

        // Sync Providers specify if their groups need to be Lazy loaded
        // Microsoft Graph only for the time being
        if (lazyLoad) {
          var remoteConfig = getConfigForRemoteSelect2(url, $selectWidget);
          $.extend(config, remoteConfig);
        }

        $selectWidget.select2(config);

        $selectWidget.bind('select2:select', '.company-setting-sync-groups', function(event) {
          var groupId = event.params.data.id;
          $.ajax({
            url: url,
            data: { group_id: groupId },
            method: "post"
          });
        }.bind(this));

        $selectWidget.bind('select2:unselect', '.company-setting-sync-groups', function(event) {
          var groupId = event.params.data.id;
          var parts = url.split("?");
          var baseUrl = parts[0];
          var queryParams = window.R.utils.paramStringToObject(parts[1]);

          $.ajax({
            url: baseUrl + "/" + groupId + "?" + $.param(queryParams),
            data: { _method: "delete" },
            type: 'post'
          });
        });
      });

      function getConfigForRemoteSelect2(url, $select) {
        var remoteParamsKey = 'remoteParams'; // for data attribute
        return {
          minimumInputLength: 1,
          ajax: {
            url: url,
            dataType: 'json',
            delay: 250, // wait before triggering the request on keypress
            data: function (params) { // ajax params
              var customParams = {
                term: params.term.trim() // user search term,
              };

              if (params.page){ // pagination request: send previously stored skip token
                customParams.remote_params = $select.data(remoteParamsKey);
              } else { // new search request: clear previous token if any
                $select.removeData(remoteParamsKey);
              }

              return customParams;
            },
            processResults: function (data) { // transform ajax response into Select2 format
              var formattedData = data.values
                .map(function (group){
                  return {
                    id: group.id,
                    text: group.displayName
                  };
                });
              return {
                results: formattedData,
                pagination: {
                  more: !!data.has_more
                }
              };
            },
            success: function (response) {
              if (response.has_more){
                // store remote params (eg. skip_token) to be used in next pagination request
                $select.data(remoteParamsKey, response.remote_params);
              }
            },
            error: function (xhr) {
              if (xhr.statusText === 'abort') { return }

              var errorMessage = (xhr.responseJSON && xhr.responseJSON.error) ||
                'There was a problem loading groups. Please try again later.';
              var $loadingIndicator = $(".select2-results__option.loading-results");
              $loadingIndicator.text(errorMessage);
            }
          },
          templateResult: formatGroupResult
        };
      }

      // for consistent loading message
      function formatGroupResult(group) {
        if (group.loading) {
          return "Please wait";
        }

        return group.text;
      }

    });
  }

  bindSyncGroupFilterSettings() {
    var speed = 300;

    $document.on('change', "#sync-teams .form-check-input", function(e) {
      $("#advanced-teams-sync-config").toggle(speed);
      let selector = $(e.target).parent().parent().next();
      $(selector).toggle(speed);
    });
  
    $document.on('change', '#company_sync_teams_from_members_associations', function(){
      $("#sync-group-filter-options").toggle(speed);
    })
  }

  unbindSyncGroupsSelect() {
    $(document).off('change', "#sync-teams .form-check-input");
  }
}
