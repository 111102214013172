import '~/scripts/integrations/jquery-extended';

export default (function() {
  var AdminUsers = function() {
    this.addEvents();
  };

  AdminUsers.prototype.addEvents = function() {
    this.usersDatatableUtils = new R.UsersDatatableUtils();
  };


  AdminUsers.prototype.initializeTeamsSelect2WithFnArgs = function ($target) {
    // site admin is intentionally not being allowed to create teams
    // the reason is simply simplicity - no need to support that
  }

  // See `Accounts.prototype.updateUserRow` for comments.
  AdminUsers.prototype.updateUserRow = function(rowSelector, updatedUserRowData) {
    var table = R.LitaTables['users_admin'].dataTable;
    table.row(rowSelector).data(updatedUserRowData);
    this.usersDatatableUtils.reinitializeRowComponents(rowSelector, this, true);
  };

  return AdminUsers;
})();
