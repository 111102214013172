import '~/scripts/integrations/jquery-extended';
import Highcharts from '~/scripts/integrations/highcharts';

(function () {
  window.R.gage = function (selector, opts) {
    var that = this;
    var items = [];
    $(selector).each(function () {
      var id = $(this).prop('id');
      items.push(initialize(id, opts));
    });

    return items;
  };

  function initialize(id, options) {
    options = options || {};
    options.data = options.data || $("#" + id).data('res');

    var gaugeOptions = optionsWithDefaults(options);
    return Highcharts.chart(id, gaugeOptions)
  }

  function optionsWithDefaults(options) {
    var gaugeOptions = {
      chart: {
        type: 'solidgauge',
        backgoundColor: 'none',
        height: options.chart_height || 150,
      },
      title: null,
      pane: {
        center: options.pane_center || ['50%', '80%'],
        size: options.pane_size || '140%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc'
        }
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            borderWidth: 0,
            useHTML: true
          }
        }
      },
      yAxis: {
        min: 0,
        max: 100,
        labels: {
          y: 10,
          style: {
            'font-size': options.range_font_size || 10
          }
        },
        stops: [
          [0.1, options.gauge_color || '#FFff00'],
          [0.5, options.gauge_color || '#06ff00'],
          [0.9, options.gauge_color || '#41a0d9']
        ],
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 0,
        title: {
          y: -70
        },
      },
      credits: {
        enabled: false
      },
      series: [{
        data: [Number(options.data) || 0],
        dataLabels: {
          useHTML: true,
          format: '<div style="text-align:center">' +
            '<span style="font-size:18px">{y}%</span><br/>' +
            '</div>'
        }
      }]
    };
    return gaugeOptions;
  }
})();
