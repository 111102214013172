import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  connect() {
    this.url = this.element.dataset.url
    this.checkBoxToggle()
    this.selectChange()
  }
  checkBoxToggle() {
    $('.delegation-toggle').on('change', (e) => {
      const isChecked = e.target.checked
      let userId;
      if (isChecked) {
        userId = this.element.querySelector('.sender-select').value
      } else {
        userId = this.element.dataset.currentUserId
      }
      const url = this.url + `?user_id=${userId}`
      this.fetchData(url)
    })
  }

  selectChange() {
    $('.sender-select').on('change', (e) => {
      const userId = e.target.value
      const url = this.url + `?user_id=${userId}`
      this.fetchData(url)
    })
  }

  fetchData(url) {
    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
    })
  }
}