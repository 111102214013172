import '../integrations/jquery-extended';
import Swal from 'sweetalert2';

// default confirmation being replaced by swal
(function () {
  //Override the default confirm dialog by rails
  if (window.Swal && window.R.confirmationDialog) {
    $.rails.allowAction = function(link){
      if (link.data("confirm") === undefined || link.data("confirm") === null ){
        return true;
      }
      $.rails.showConfirmationDialog(link);
      return false;
    };

    //User click confirm button
    $.rails.confirmed = function(link){
      $(link).one("ajax:complete", function(e) {
        $(e.target).data("confirm", link.data('confirmText'));
      });

      link.data("confirmText", link.data('confirm'));
      link.data("confirm", null);
      link.trigger("click.rails");
    };

    //Display the confirmation dialog
    $.rails.showConfirmationDialog = function(link){
      var message = link.data("confirm"),
          title = link.data("title") || window.R.confirmationDialog.confirmationTitle,
          customClass = link.data("custom-class"),
          iconType = 'warning',
          confirmButtonText = link.data("confirm-button-text") || window.R.confirmationDialog.confirmButtonText,
          reverseButtons = link.data("reverse-buttons");
      Swal.fire({
        title: title,
        text: message,
        icon: iconType,
        confirmButtonText: confirmButtonText,
        customClass: customClass,
        showCancelButton: true,
        reverseButtons: reverseButtons
      }).then(function(result) {
        if (result.value){
          $.rails.confirmed(link);
        }
      });
    };

    // The new Turbo way of handling confirmations
    // ex. Turbo stream delete buttons with confirmation
    Turbo.setConfirmMethod((message, element) => {
      var link = $(element);
      var title = link.data("title") || window.R.confirmationDialog.confirmationTitle,
          customClass = link.data("custom-class"),
          iconType = 'warning',
          confirmButtonText = link.data("confirm-button-text") || window.R.confirmationDialog.confirmButtonText,
          reverseButtons = link.data("reverse-buttons");

      return new Promise((resolveTurboStreamRequest) => {
        Swal.fire({
          title: title,
          text: message,
          icon: iconType,
          confirmButtonText: confirmButtonText,
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            // NOTE: this preconfirm here is a little unique
            //       we immediately call resolve() to trigger the TurboStream request
            //       then we wait until a timeout. While there is a timeout,
            //       the response should have a turbostream with reset-form-loading controller
            //       which will close the Swal. If for any reason the request doesn't come
            //       back in 5 seconds, it will close anyway. We certainly don't want to
            //       wait forever
            resolveTurboStreamRequest(true);
            return new Promise((resolveSwal) => {
              setTimeout(() => {
                resolveSwal(true);
              }, 5000);
            });
          },
          customClass: customClass,
          showCancelButton: true,
          reverseButtons: reverseButtons
          });
      });

    });
  }
}());
