import '~/scripts/integrations/jquery-extended';

(function() {
  function StarsFadeAway(el, message) {
    this.$element = $(el || ".starFadeAway");
    this.message = message || null;
    this.init();
  }

  StarsFadeAway.prototype = {
    constructor: StarsFadeAway,
    init: function() {
      this.showStars();
    },
    showStars: function() {
      // append stars to element
      // add CSS class to animate stars
      this.$element.append(this.createStars());
      setTimeout(function() {
        this.$element.find('.starsInFadeAway').remove();
      }.bind(this), 2000);
    },
    createStars: function() {
      // create stars
      var plus = '＋';
      var stars = '<div class="starsInFadeAway"><span class="animating-star">' + '👍🏻' + '</span><span class="animating-star">' + '👍🏿' + '</span><span class="animating-star">' + plus + '</span><span class="animating-star">' + plus + '</span><span class="animating-star">' + '👍' + '</span><span class="animating-star">' + plus + '</span></div>';
      return stars;
    }
  };

  window.R.ui.StarsFadeAway = StarsFadeAway;
})();
