import '~/scripts/integrations/jquery-extended';

R.ui.queuedImportProgress = (function() {

  var $progress,
  $progressBar,
  $progressMessage,
  $progressBarMessage;

  return function(container, options) {
    var $container;

    options = options || {};

    container = container || "#company_admin_accounts_spreadsheet_importers-new";
    $container = $(container);

    $progress = $container.find('.progress-wrapper');
    $progressBar = $progress.find('.progress-bar');
    $progressMessage = $progress.find('.message');
    $progressBarMessage = $progress.find('span');

    R.utils.progressJob(options.queued_job_id, () => {
      setProgressBarToUICompleted(options.completeText);
      options.successCallback();
    }, () => {
      setProgressBarToUICompleted(options.completeText);
      options.failureCallback();
    } 
    );
  }

  function setProgressBarToUICompleted(completeString) {
    completeString = completeString || "Complete";

    $progressMessage.text(completeString);
    $progressBarMessage.text(completeString);
    $progressBar.css('width', '100%');
    $progressBar.text("100%");
    $progress.find('.spinner').css('display', 'none');
  }

})();