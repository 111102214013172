import '~/scripts/integrations/jquery-extended';
import Select2 from '~/scripts/lib/Select2.js'
import HandlebarsTemplates from '~/scripts/integrations/handlebars-templates';
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';

export default class Rewards {
  constructor() {
    this.variantListener = window.R.rewards.variantListener();
    this.initializeCategorySelect2();
    this.addEvents();
  }

  static formatUser(user) {
    if (user.loading) {
      return "Please wait";
    }

    if (user.avatar_thumb_url === R.defaultAvatarPath) {
      user.avatar_thumb_url = "/assets/" + R.defaultAvatarPath;
    }

    const template = HandlebarsTemplates['user/userAvatar'];
    return template({
      "avatar_url": user.avatar_thumb_url,
      "name": user.label
    });
  }

  static formatUserSelection(user) {
    return user.label || user.text;
  }

  static addRewardImage(e, data) {
    $document.find(".reward_image").attr("src", data.reward.image.url);
  }

  initializeCategorySelect2() {
    const categoriesSelector = "#reward_categories";
    if ($(categoriesSelector).is('select')) {
      new Select2(() => {
        $(categoriesSelector).select2({tags: true});
      });
    }
  }

  addEvents() {
    new CompanyAdmin();
    new Select2(this.bindRewardManagerAutocomplete);

    try {
      const $relevantContainer = $($document.find(".reward-card"));
      const uploader = new window.R.Uploader(
        $relevantContainer,
        Rewards.addRewardImage,
        {
          submitBtn: $relevantContainer.find("input[type=submit]")
        }
      );
    } catch(e) {
      console.warn(e);
    }

    this.turnOnToggles();
  }

  removeEvents() {
    $(".reward-manager-select").off();
    $document.off('click', 'form .new_reward, form.edit_reward');
    this.variantListener.off();
  }

  bindRewardManagerAutocomplete() {
    let url = "/coworkers";
    const dept = window.R.utils.queryParams().dept;
    if (dept) {
      url += "?dept=" + dept;
    }
    $(".reward-manager-select").select2({
      placeholder: "Please select a manager",
      allowClear: true,
      ajax: {
        url: url,
        dataType: 'json',
        delay: 250,
        data: params => ({
          term: params.term, // search term
          page: params.page,
          include_self: true
        }),
        processResults: (data, page) => ({
          results: data
        }),
        cache: true
      },
      escapeMarkup: markup => markup,
      minimumInputLength: 1,
      templateResult: Rewards.formatUser,
      templateSelection: Rewards.formatUserSelection
    });
  }

  turnOnToggles() {
    const $inputs = $(".on-off:not(.remoteCheckboxToggle)");
    $inputs.iOSCheckbox({
      onChange: function () {
        const $target = $(this.elem);
        const checkedValue = this.elem.prop("checked");

        if ($target.prop('name') === "reward[requires_approval]") {
          $("#rewards-manager-select-wrapper").toggleClass('hidden');
        }
      }
    });
  }
}
