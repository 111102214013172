import { Controller } from "@hotwired/stimulus"

// The purpose of this controller is to be added to a turbo stream
// so that when the turbo stream is rendered, the controller will
// connect and we will reset the form
export default class extends Controller {
  connect() {
    window.recognize.patterns.formLoading.resetButton();
    Swal.close();
  }
}
