import { Controller } from "@hotwired/stimulus"
import Pagelet from '~/scripts/lib/Pagelet';

export default class extends Controller {
  connect() {
    console.log("company settings stimulus controller connected")
    this.turnOnToggles();
    this.pagelet = new Pagelet();
  }

  disconnect() {
    $document.off('change', ".form-check-input[data-autosave=true]");
  }

  turnOnToggles() {
    // FIXME: this is a straight copy from company_admin/settings/index.js
    //       Once the settings page is migrated to Stimulus, that other
    //       method should be removed
    let that = this;

    var autosaveCheckbox = function(elem) {
        var $target = $(elem), data = {};
        var checkedValue = !!$target.prop("checked");
        if ($target.data('invert-values') === true) { // used as `invert_values` in view
          checkedValue = !checkedValue;
        }
        data[$target.data('setting')] = checkedValue;
        that.updateSettings(data);
    }

    // handle bootstrap checkboxes as well
    $document.on('change', ".form-check-input[data-autosave=true]", function(e) {
      const checkbox = e.target;
      autosaveCheckbox(checkbox);

      const isGlobalSMS = checkbox.getAttribute('data-setting') === "settings[global_redemption_reminder_sms_notification]";

      if (isGlobalSMS) {
        const newUserSMS = $('#new-user-redemption-reminder-sms-notification-checkbox');
        const newUserSMSLabel = $('#new_user_redemption_reminder_sms_notification label');
        const isChecked = checkbox.checked;
        newUserSMS.prop("disabled", !isChecked);
        newUserSMSLabel.toggleClass('disabled', !isChecked);
      }
    });
  }


  // FIXME: this is a straight copy from company_admin/settings/index.js
  //       Once the settings page is migrated to Stimulus, that other
  //       method should be removed
  updateSettings(data) {
    var isResetInterval = "reset_interval" in data;
    var isChangeCurrency = "currency" in data;
    var isShowRecognitionTags = "show_recognition_tags" in data;
    $.ajax({
      url: this.getURL('/company/update_settings'),
      type: "POST",
      data: { settings: data },
      success: function() {
        if (isResetInterval) {
          // no op
          // this used to update the header when it had interval points.
        }
        else if (isChangeCurrency) {
          updateCurrencySymbol();
        }
        else if(isShowRecognitionTags) {
          updateRequireRecognitionTagsToggleDisplay(data)
        }
      },
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
    });
  }

  getURL(path) {
    return '/' + $body.data('companyScope') + path;
  }
}
