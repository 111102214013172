import '~/scripts/integrations/jquery-extended';

export default (function() {
  var S = function() {
    this.initDataTable();
  };

  S.prototype.removeEvents = function() {
  };

  S.prototype.initDataTable = function() {
    $('table').DataTable( {
      ordering: true,
      paging: true,
      searching: true,
      responsive: false,
      pageLength: 100
    });
  };

  return S;
})();
