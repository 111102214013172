import '~/scripts/integrations/jquery-extended';
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';
import Pagelet from '~/scripts/lib/Pagelet';

export default (function() {
  var PointsOverview = function () {
    this.addEvents();
    this.reloadifRestore();
  };

  PointsOverview.prototype.addEvents = function() {
    this.companyAdmin = new CompanyAdmin();
    this.pagelet = new Pagelet();
    this.dateRange = new window.R.DateRange({refresh: 'turbo'});
  };

  // It reloads the page to update the points section alert div after changing the settings and clicking the back button.
  PointsOverview.prototype.reloadifRestore = function() {
    if ($body.data('page-previously-loaded')) {
      window.location.reload();
    }
    $body.attr('data-page-previously-loaded', 'true');
  };

  return PointsOverview;
})();
