import '~/scripts/integrations/jquery-extended';

export default (function () {
  var AdminRewards = function () {
    this.addEvents();
  };

  AdminRewards.prototype.addEvents = function () {
    this.bindRewardsDiscrepancyCalculator();
  };

  AdminRewards.prototype.removeEvents = function () {
    $document.off('change', '#bank_rewards_balance');
  };

  AdminRewards.prototype.bindRewardsDiscrepancyCalculator = function () {
    $document.on('keyup input', '#bank_rewards_balance', function() {
      var bankBalance = parseFloat($("#bank_rewards_balance").val());
      if(bankBalance) {
        var container = $("#rewardBalanceContainer");
        var tangoBalance = parseFloat(container.data('tangobalance'));
        var xoxodayBalance = parseFloat(container.data('xoxodaybalance'));
        var liabilityBalance = parseFloat(container.data('liabilitybalance'));
        var totalAssets = bankBalance + tangoBalance + xoxodayBalance;
        var discrepancy = totalAssets - liabilityBalance;
        $("#totalAssetsBalance").html(R.utils.formatCurrency(totalAssets));

        if (discrepancy > 0) {
          var discrepancySuffix = "<br> (surplus)";
        } else {
          var discrepancySuffix = "<br> (deficit)";
        }

        $("#rewardsDiscrepancy").html(R.utils.formatCurrency(discrepancy) + discrepancySuffix);
      }
    });
  }

  return AdminRewards;
})();
