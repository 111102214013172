import '~/scripts/integrations/jquery-extended';
import moment from 'moment-timezone';

window.R.DatePicker = function(callback) {
  'use strict';

  //console.log('DatePicker constructor');
  if (typeof ($.fn.datepicker) === 'function') {
    //console.log('DatePicker is loaded');
    callback();
  } else {
    //console.log('DatePicker is NOT loaded');
    $('head').append($('<link rel="stylesheet" type="text/css" integrity="sha256-siyOpF/pBWUPgIcQi17TLBkjvNgNQArcmwJB8YvkAgg=" crossorigin="anonymous" />').attr('href', 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.9.0/css/bootstrap-datepicker.min.css'));
    $.getScriptWithOpts("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.9.0/js/bootstrap-datepicker.min.js", {
      scriptAttrs: {
        integrity: "sha256-bqVeqGdJ7h/lYPq6xrPv/YGzMEb6dNxlfiTUHSgRCp8=",
        crossorigin: "anonymous"
      }
    }, function () {
      var languageScript;
      var lang = $('html').attr('lang');

      moment.tz.setDefault("America/Los_Angeles");

      // Asset resource for French Canadian (fr-CA) not found on bootstrap-datepicker so resorting to default.
      if (lang === undefined || lang === 'en' || lang === "fr-CA") {
        callback();
      } else {
        languageScript = document.createElement('script');
        languageScript.onerror = callback;
        languageScript.onload = callback;
        languageScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.9.0/locales/bootstrap-datepicker.' + lang + '.min.js';
        document.body.appendChild(languageScript);
        fetchAndSetIntegrityHash(lang, languageScript);
      }
    });
  }
};

// Setup the integrity hash for the locale dynamically
// Data fetched from the api.cdnjs.com - Uses SHA-512 hash function

function setSriHashForLanguage(language, script, data) {
  'use strict';

  var langFileName = "locales/bootstrap-datepicker." + language + ".min.js";
  var localesKeys = Object.keys(data["sri"]);
  if (localesKeys.includes(langFileName)) {
    script.integrity = data["sri"][langFileName];
    script.crossOrigin = "anonymous";
    script.nonce = true;
  }
}

function fetchAndSetIntegrityHash(language, script) {
  'use strict';
  var url = "https://api.cdnjs.com/libraries/bootstrap-datepicker/1.9.0";
  $.get(url, function (data) {
    setSriHashForLanguage(language, script, data);
  });
}
