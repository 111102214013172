import '~/scripts/integrations/jquery-extended';
import AutoSaver from '~/scripts/lib/AutoSaver.js';

export default (function() {
  var AdminUsers = function() {
    this.addEvents();
  };

  AdminUsers.prototype.addEvents = function() {
    this.autoSaver = new AutoSaver();
    this.usersDatatableUtils = new R.UsersDatatableUtils();
    this.initUsersDatatable();
    this.bindAutoSave();
  };

  AdminUsers.prototype.removeEvents = function() {
    $("#users_admin-table").off('column-visibility.dt');
    $("#settings").off('column-visibility.dt');
    $document.off('click', '#upload-invoice');
    $document.off('click', '.delete-invoice');
  };

  AdminUsers.prototype.initUsersDatatable = function() {
    //FIXME - add column for network if company is in family(ie depts)
    if($("#users_admin-table th.dept").length === 1) {
      // Attention: If adding/removing columns affect desired position of 'network' column,
      // readjust index in the 'splice' function below.
      columns.splice(4, 0, {data: "network", className: "not-mobile"});
    }
  };

  AdminUsers.prototype.initializeTeamsSelect2WithFnArgs = function ($target) {
    // site admin is intentionally not being allowed to create teams
    // the reason is simply simplicity - no need to support that
  }

  // See `Accounts.prototype.updateUserRow` for comments.
  AdminUsers.prototype.updateUserRow = function(rowSelector, updatedUserRowData) {
    var table = R.LitaTables['users_admin'].dataTable;
    table.row(rowSelector).data(updatedUserRowData);
    this.usersDatatableUtils.reinitializeRowComponents(rowSelector, this, true);
  };

  // AdminUsers.prototype.bindRewardProviderSelect = function() {

  AdminUsers.prototype.bindAutoSave = function() {
    this.autoSaver = new AutoSaver();
    this.autoSaver.autoSave(".autosave-setting-form input, .autosave-setting-form select, .autosave-setting-form textarea")
  }

  return AdminUsers;
})();
