import { Controller } from "@hotwired/stimulus"

// The purpose of this controller is to be added to a turbo stream
// so that when the turbo stream is rendered, the controller will
// connect and we will reset the form
export default class extends Controller {
  static values = {
    delay: Number,
    duration: Number,
    easing: String
  }

  connect() {
    const delay = this.delayValue || 0;
    const duration = this.durationValue || 2000;
    const easing = this.easingValue || 'swing';
    setTimeout(() => $(this.element).fadeOut(duration, easing), delay);

  }
}
