import '~/scripts/integrations/jquery-extended';
import Select2 from '~/scripts/lib/Select2.js';
import HandlebarsTemplates from '~/scripts/integrations/handlebars-templates';

export default (function() {

  var T = function() {
    this.addEvents();
  };

  T.prototype.addEvents = function() {
    this.addSwalEvents();
    this.addRadioButtonStateEvents();
    this.initializeSelect2();
  };

  T.prototype.initializeSelect2 = function() {
    var settingSelector = ".select2";
    new Select2(function () {
      $(settingSelector).select2();
    });
  };

  T.prototype.renderGroupRow = function(rowGroup, rows, group) {

    if(rowGroup['c']['dataSrc'] === 'id'){
      return;
    }
    var rowsData = rows.data().reduce(function(a,b){a.push(b);return a}, [])
    var template = HandlebarsTemplates['litatables/companyAlarmsRowGroup'];
    var row = rowsData[0];
    return template({"group_name": group});
  };

  T.prototype.addRadioButtonStateEvents = function() {
    $(document).on('change', '.alarm_state > .btn-group input[type="radio"]', function() {
      var selectedValue = $(this).val();
      var index = $(this).closest('.btn-group').data('index');

      $.ajax({
        url: '/admin/company_alarms/',
        method: 'PATCH',
        data: {
          index: index,
          selected_value: selectedValue
        }
      });
    });
  };

  T.prototype.addSwalEvents = function() {

    function humanizeString(str) {
      return str.replace(/_/g, ' ').replace(/\b\w/g, function(c) {
        return c.toUpperCase();
      });
    }
    
    $document.on('click', '.display-alert', function(event){
      event.preventDefault();
      var target = $(event.target);
      var alertMessages = target.data('alert-messages');

      alertMessages = alertMessages.map(item => {
        if (item.name) {
          item.humanizedName = humanizeString(item.name);
        }
        return item;
      });

      var html = HandlebarsTemplates['company_alarm/alertMessage'](alertMessages);

      Swal.fire({
        title: 'Company Alert',
        html: html,
        icon: 'info'
      });
    });
  };

  return T;

})();