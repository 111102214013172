import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  disconnect() {
  }

  verifyEnabledToggle() {
    var $enabledToggle = $("#is_enabled");
    var $groupUid = $("#catalog_amazon_business_authentication_group_uid");
    var $groupIdentity = $("#catalog_amazon_business_authentication_group_identity");

    var groupUidLength = $groupUid.val().length;
    var groupIdentityLength = $groupIdentity.val().length;

    if (groupUidLength > 0 && groupIdentityLength > 0) {
      $enabledToggle.removeAttr("disabled")
    } else {
      $enabledToggle.attr("disabled", "disabled")
      $enabledToggle.prop("checked", false)
    }
  }
}
