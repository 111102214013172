import '~/scripts/integrations/jquery-extended';
import Select2 from '~/scripts/lib/Select2.js'
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';

export default (function () {
  var CustomBadges = function () {
    this.addEvents();

    setBadgeTypeForNewBadgeDrawer();
    addNewBadgeToDatatableTop();

    this.companyAdmin = new CompanyAdmin();
  };

  CustomBadges.prototype.addEvents = function () {
    this.bindBadgeRoleSelect2();
  };

  CustomBadges.prototype.bindBadgeRoleSelect2 = function () {
    new Select2(function () {
      $("#filter_by_status").select2({
        placeholder: "Select company role"
      });
    });
  };

  function addNewBadgeToDatatableTop() {
    var $relevantNewBadgeContainer = $("#new_badge");
    new window.R.Uploader(
      $relevantNewBadgeContainer,
      function (e, json) {
        var $newBadgeDataFromPartial;

        $relevantNewBadgeContainer[0].reset();
        $newBadgeDataFromPartial = $(json.partial);
        placeNewBadgeToTop($newBadgeDataFromPartial);
      },
      {
        submitBtn: $relevantNewBadgeContainer.find("input[type=submit]")
      }
    );
  }

  function placeNewBadgeToTop(newBadgeData) {
    var customBadgesDT, dt, aiDisplayMaster, irow;

    // Placing new Row to the top of the datatable.
    customBadgesDT = $("#custom_badges-table").dataTable();
    dt = customBadgesDT.api();
    dt.row.add(newBadgeData);
    aiDisplayMaster = customBadgesDT.fnSettings()["aiDisplayMaster"];
    irow = aiDisplayMaster.pop();
    aiDisplayMaster.unshift(irow);
    dt.draw();
  }

  function setBadgeTypeForNewBadgeDrawer() {
    var badgeTypeObjectFromUrl = window.R.utils.queryParams();
    var badgeTypeFromUrl = badgeTypeObjectFromUrl["type"];
    $("#current_badge_type").val(badgeTypeFromUrl);
  }

  return CustomBadges;
})();
