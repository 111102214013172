import '~/scripts/integrations/jquery-extended';
import Select2 from '~/scripts/lib/Select2.js'

export default (function() {
  var RedemptionsIndex = function() {

  };

  return RedemptionsIndex;
})();
