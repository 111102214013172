import CompanyAdmin from '~/scripts/lib/CompanyAdmin';

export default (function() {
  function SyncSettings() {
    this.companyAdmin = new CompanyAdmin();
    this.companyAdmin.autosaveSetting(".form-check-input");
  }

  return SyncSettings;
})();
