import '~/scripts/integrations/jquery-extended';
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';

export default (function() {
  var AccountsEdit = function() {
    this.accountsDataTable = null;
    this.usersDatatableUtils = new R.UsersDatatableUtils();
    this.addEvents();
  };

  AccountsEdit.prototype.addEvents = function() {
    var companyAdmin = new CompanyAdmin();

    this.initializeEditAccountDataTable();
    this.bindBulkEditPageEvents();
  };

  AccountsEdit.prototype.bindBulkEditPageEvents = function() {
    this.bindAccountsSuccess();
    this.bindAccountsError();
    this.bindAddAccountBtn();
  };

  AccountsEdit.prototype.removeEvents = function() {
    $document.off(R.touchEvent, "#add-account");
    $('#response-feedback-wrapper').empty();

    $document.off('ajaxify:success', '.edit_bulk_user_updater');
    $document.unbind('ajaxify:error:edit_bulk_user_updater');

    this.editAccountsDatatable.destroy();
  };

  AccountsEdit.prototype.initializeEditAccountDataTable = function() {
    var that = this;
    var $table = $("#edit-accounts table");

    this.editAccountsDatatable = $table.DataTable({
      ordering: true,
      paging: true,
      searching: true,
      responsive: false,
      pageLength: 100,
      "columnDefs": [{ "orderDataType": "dom-checkbox", targets: [3] }],
      // this adds forced preliminary sorting (for 3rd column), for positioning new unsaved rows at the top
      "aaSortingFixed": [[2, 'desc']]
    });

    $table.dataTable().fnSetFilteringDelay();

    $table.on("draw.dt", function(){
      $("#edit-accounts").off("change keyup", "input:not([type=checkbox]), select");
      that.setDatePicker();
    });

    $table.on("order.dt", function(){
      $.each($(this).find("tbody tr"), function(){
        $(this).removeClass("selected");
      });
    });
    that.setDatePicker();
  };

  AccountsEdit.prototype.bindAccountsSuccess = function() {
    var that = this;
    $document.on('ajaxify:success', '.edit_bulk_user_updater', function(e, successObj) {
      var numAccountsCreated = successObj.data.bulk_user_updater.created_users.length;
      var numAccountsUpdated = successObj.data.bulk_user_updater.updated_users.length;
      var msg;
      var table = $("#edit-accounts table").dataTable().api();
      if (numAccountsCreated > 0) {
        // need to update the rows for created records and make them "updates"
        $.each(successObj.data.bulk_user_updater.created_users, function() {
          var $row = $("#user-row-" + this.temporary_id),
            temporaryId = this.temporary_id,
            actualId = this.id,
            birthday = this.birthday,
            startDate = this.start_date;

          $row.find("td").each(function() {
            var $cellEl = $(this);
            $cellEl.find("input").each(function() {
              var $this = $(this);
              var val = $this.val();

              $this.attr("value", val);
              // set sort value for cell (if relevant sort attr present and current input is visible)
              if ($cellEl.attr('data-order') !== undefined && $this.attr('type') !== 'hidden') {
                var $parentTd = $(this).parent("td");

                var dataOrder = val;
                var attrId = $this.attr("id");
                // sorting and searching works with dd-mm-yyyy format
                if (attrId === "bulk_user_updater_"+ temporaryId +"_birthday"){
                  dataOrder = birthday;
                } else if (attrId === "bulk_user_updater_"+ temporaryId +"_start_date"){
                  dataOrder = startDate;
                }
                $parentTd.attr("data-order", dataOrder);
              }
            });

            var cellHTMLStr = $cellEl.html().replace(new RegExp(temporaryId, 'g'), actualId);
            cellHTMLStr = cellHTMLStr.replace(new RegExp('create', 'g'), 'update');
            table.cell(this).data(cellHTMLStr);
          });
          $row.attr("id", "user-row-" + actualId);

          // set new row column value to false, post user creation
          $row.find('td.is_new_row').html(0);
        });
        that.setDatePicker();
      }

      if (numAccountsUpdated > 0) {
        $.each(successObj.data.bulk_user_updater.updated_users, function() {
          var $row = $("#user-row-" + this.id);

          var userId = this.id;
          delete this.id;
          delete this.network;

          $.each(this, function(key, value){
            var attrId = "#bulk_user_updater_" + userId +"_"+ key;
            var $inputEl = $row.find(attrId);
            $inputEl.parent("td").attr("data-order", value);
          });
        });
      }

      /**
        need to reset datatable cache for sorting to work for the updated cells
        source: https://datatables.net/reference/api/row().invalidate()
       **/

      table.rows().invalidate('dom').draw();

      if (numAccountsCreated === 0 && numAccountsUpdated === 0) {
        msg = "No accounts were modified";
      } else if (numAccountsCreated > 0 && numAccountsUpdated > 0) {
        msg = numAccountsCreated + " accounts created, and " + numAccountsUpdated + " accounts updated";
      } else if (numAccountsCreated > 0) {
        msg = numAccountsCreated + " accounts created";
      } else {
        msg = numAccountsUpdated + " accounts updated";
      }

      successFeedback(msg, $("#edit-accounts #response-feedback-wrapper"));
    });
  };

  AccountsEdit.prototype.bindAccountsError = function() {
    $window.bind('ajaxify:errors:edit_bulk_user_updater', function(e, successObj) {
      $("#edit-accounts table").dataTable().fnSort([2, 'desc']);
    });
  };

  AccountsEdit.prototype.bindAccountsFormChangeHandler = function() {
    $document.on("change", "input[type=checkbox]", function() {
      $(this).parents("tr").removeClass("selected");
    });

    $("#edit-accounts").on("change keyup", "input:not([type=checkbox]), select", function() {
      var $row = $(this).parents("tr");
      $row.addClass("selected");
      $row.find("input[type=checkbox]").attr('checked', true);
    });
  };

  AccountsEdit.prototype.bindAddAccountBtn = function() {
    var that = this;
    $document.on(R.touchEvent, "#add-account", function(evt) {
      evt.preventDefault();
      var $addBtn = $(this);
      var time = new Date().getTime();

      var id = $addBtn.data('id');
      var template = $addBtn.data('newAccountTemplate');

      var regexp = new RegExp(id, 'g');

      var table = $("#edit-accounts table").dataTable();
      var rowHtml = template.replace(regexp, time);
      rowHtml = rowHtml.replace(/SK\-.*\-SK/, "_SK-" + time + "-SK");

      table.api().row.add($(rowHtml)[0]).draw();
      that.setDatePicker();
    });
  };

  AccountsEdit.prototype.setDatePicker = function() {
    var that = this;
    new window.R.DatePicker(function(){
      $(".datepicker:not(.noYear) input").datepicker({
        format: "mm/dd/yyyy",
        autoclose: true
      });

      $(".datepicker.noYear input").datepicker({
        format: "mm/dd",
        viewMode: "months",
        autoclose: true
      });
      // this has to come after datepicker is setup
      // otherwise, it seems, adding datepicker,
      // triggers change handler causing change events to
      // fire that we don't want to yet
      that.bindAccountsFormChangeHandler();
    });
  };

  function successFeedback(message, element) {
    var $existingButtons = element.find(".success-mention");
    $existingButtons.remove();
    var $successButton = $('<p class="success-mention success-text">' + message + '</p>');
    element.append($successButton);
    $successButton.fadeOut(4000);
  }

  return AccountsEdit;
})();
