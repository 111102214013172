import '~/scripts/integrations/jquery-extended';

(function() {
  function BigLettersAnimation(text) {
    this.animate(this.separateWords(text));
  }

  BigLettersAnimation.prototype = {
    constructor: BigLettersAnimation,
    separateWords: function(text) {
      var words = text.split(' ');
      var html = "";

      for (var i = 0; i < words.length; i++) {
        html += "<span class='big-word'>" + words[i] + "</span>";
      }

      return html;
    },
    animate: function(wordsArray) {
      var $words = $(wordsArray);
      var $bigLettersWrapper = $("<div id='big-letters-animate'></div>");

      $body.append($bigLettersWrapper);
      // append words to dom
      $bigLettersWrapper.append($words);

      if ($bigLettersWrapper.find('.active').length) {
        return;
      }

      // remove array items that don't include letters
      $words = $words.filter(function() {
        return $(this).text().match(/[a-z]/i);
      });

      $words.each(function(i) {
        var $el = $(this);

        setTimeout(function() {
          $el.addClass('active');

          // if last item, remove all items
          if (i === $words.length - 1) {
            setTimeout(function() {
              $bigLettersWrapper.remove();
            }, 5000);
          }
        }, i * 1000);
      });

      setTimeout(function() {
        window.R.utils.confetti();
      }, 700);
    }
  };

  window.R.ui.BigLettersAnimation = BigLettersAnimation;
})();
