import '~/scripts/integrations/jquery-extended';
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';

export default (function() {
  var AccountsImporter = function() {
    this.addEvents();
  };

  AccountsImporter.prototype.addEvents = function() {
    var companyAdmin = new CompanyAdmin();
    this.bindAccountsSpreadSheetImporter();
    this.bindUpdateOnlyCheckbox();
    this.bindProgressUploadComplete();
  };

  AccountsImporter.prototype.bindAccountsSpreadSheetImporter = function() {
    var $container = $("#accounts_spreadsheet_importer");
    var uploader = new window.R.Uploader(
      $container,
      redirectToProgressPage,
      {
        submitBtn: $container.find("input[type=submit]")
      }
    );
  };

  AccountsImporter.prototype.bindUpdateOnlyCheckbox = function() {
    $document.on('change', "#accounts_spreadsheet_import_import_service_update_only", function() {
      var $invitesDiv = $("#accounts_spreadsheet_import_import_service_send_invites").parent("label");
      $invitesDiv.toggle();
    })
  };

  AccountsImporter.prototype.bindProgressUploadComplete = function() {
    $document.on('progress-upload:complete', function() {
      Turbo.visit(gon.redirect_url);
    });
  };

  function redirectToProgressPage() {
    location.reload();
  }

  return AccountsImporter;
})();
