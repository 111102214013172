import '~/scripts/integrations/jquery-extended';
import Swal from 'sweetalert2';
import HandlebarsTemplates from '~/scripts/integrations/handlebars-templates';
import FormErrors from '~/scripts/lib/FormErrors';
import Select2 from '~/scripts/lib/Select2.js'
import remoteCheckboxToggle from '~/scripts/lib/ui/remoteCheckboxToggle';

export default (function() {
  var AdminCompany = function() {
    this.addEvents();
  };

  AdminCompany.prototype.addEvents = function() {
    remoteCheckboxToggle();
    this.autoSaver = new window.R.AutoSaver();
    this.usersDatatableUtils = new R.UsersDatatableUtils();
    this.initUsersDatatable();
    this.bindPricePackageSelect();
    this.bindSyncFrequencySelect();
    this.bindAddonSelect();
    this.bindInvoiceAdder();
    this.bindInvoiceDeleter();
    this.bindStylesheetEditor();
    this.bindRewardProviderSelect();
    this.bindAutoSave();
  };

  AdminCompany.prototype.removeEvents = function() {
    $("#users_admin-table").off('column-visibility.dt');
    $("#settings").off('column-visibility.dt');
    $document.off('click', '#upload-invoice');
    $document.off('click', '.delete-invoice');
  };

  AdminCompany.prototype.bindStylesheetEditor = function() {

    function saveFail(xhr, status, error) {
      try {
        var errors = new FormErrors(null, xhr.responseJSON.errors);
        Swal.showValidationMessage('Request failed: ' + errors.getErrorMessagesFromErrorsObject());
        Swal.disableLoading();
        return error;
      } catch(e) {
        Swal.showValidationMessage('Request failed: ' + e);
        Swal.disableLoading();
      }
    }

    function saveStylesheet(data) {
      var ajax_data = {
        _method: 'put',
        company_customization: { stylesheet: data }
      };
      return new Promise(function(resolve, reject){
        var urlParts = $("#edit-theme-link").data('endpoint').split("?");
        var formUrl = urlParts[0] + ".js?" + urlParts[1]
        $.ajax({
          url:  formUrl,
          type: 'post',
          data: ajax_data
        }).then(function(){
          $("#edit-theme-link").data('stylesheet', data);
        }).then(resolve).fail(reject);
      }).catch(saveFail);
    }

    function compileStylesheet() {
      var urlParts = $("#edit-theme-link").data('compileendpoint').split("?");
      var formUrl = urlParts[0] + ".js?" + urlParts[1]
      return new Promise(function(resolve, reject){
        $.ajax({
          url: formUrl,
          type: 'post'
        }).then(resolve).then(function(response){
          $("#theme-edit-message").html("Theme compilation has started. <a href='/admin/queue?queue=themes'>Check status</a>")
        }).fail(reject)
      }).catch(saveFail);
    }

    $document.on(R.touchEvent, '#edit-theme-link', function(evt) {
      evt.preventDefault();
      var inputPlaceholder = "$headerBg:;\n$linkColor:;\n$buttonPrimaryBg:;\n$gray:;\n@import \"application\";";
      var swalOpts = {
        icon: "info",
        title: "Update Stylesheet",
        inputValue: $("#edit-theme-link").data('stylesheet') || inputPlaceholder,
        input: "textarea",
        showCancelButton: true,
        confirmButtonText: "Save",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        allowOutsideClick: function() {!Swal.isLoading()},
        preConfirm: saveStylesheet
      };
      try {
      Swal.fire(swalOpts).then(function(result){
        if(result.value) {
          Swal.fire({
            title: 'Stylesheet has been saved.',
            text: 'Press compile to deploy the new theme.',
            showCancelButton: true,
            confirmButtonText: "Compile",
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: function () { !Swal.isLoading() },
            preConfirm: compileStylesheet
          }).then(function(result){
            if(result.value) {
              Swal.fire({
                title: 'Compilation in progress.',
                text: 'It should be complete in a few minutes. Check the bg tasks.'
              });
            }
          });
        }
      }).catch(function(e){
        console.log("failed here!");
      });
      } catch(e) {
        console.log("wrapped caught error");
      }
      return false;
    })
  }

  AdminCompany.prototype.bindPricePackageSelect = function() {
    this.autoSaver.autoSave("#company_price_package");
  };

  AdminCompany.prototype.bindInvoiceAdder = function(){
    var that = this;
    $document.on('click', '#upload-invoice', function(){
      var form = $(this).data('form');
      Swal.fire({
        title: 'Upload invoice',
        html: form,
        showCancelButton: false,
        showConfirmButton: false,
        willOpen: function(el) {
          that.bindInvoiceUploader();
        }
      })
    });
  };

  AdminCompany.prototype.bindInvoiceUploader = function() {
    var $uploaderForm = $("#invoice_uploader_form");
    var uploaderOpts = {
      submitBtn: $uploaderForm.find("input[type=submit]"),
      excludedCallbacks: ["add"]
    };
    new window.R.Uploader($uploaderForm, this.invoiceUploaderSuccessCallback , uploaderOpts);
  };

  AdminCompany.prototype.invoiceUploaderSuccessCallback = function(e, json) {
    Swal.close();
    Swal.fire('Success', "Your invoice has been uploaded!", 'success').then(function() {
      location.reload();
    });
  };

  AdminCompany.prototype.bindInvoiceDeleter = function() {
    $document.on('click', '.delete-invoice', function() {
      var endpoint = $(this).data('endpoint');
      Swal.fire({
        title: 'Delete invoice',
        text: "Are you sure you want to delete this Invoice?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: "destroy-confirm-button",
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            deleteInvoice(resolve, reject, endpoint);
          }).catch(function (reason) {
            Swal.showValidationMessage(reason);
          });
        },
      });
    });
  };

  function deleteInvoice(resolve, reject, endpoint) {
    $.ajax({
      url: endpoint,
      type: "DELETE",
    }).done(function(){
      Swal.fire('Success', 'Your invoice has been deleted.', 'success').then(function(){
        location.reload();
      });
    }).fail(function(_event, request){
      var message = (request.status === 422) ?
                    'Could not delete invoice.':
                    'Something went wrong.';
      reject(message);
    })
  }

  AdminCompany.prototype.initUsersDatatable = function() {
    var that = this;
    var $table = $('#users_admin-table');
    //FIXME - add column for network if company is in family(ie depts)
    if($("#users_admin-table th.dept").length === 1) {
      // Attention: If adding/removing columns affect desired position of 'network' column,
      // readjust index in the 'splice' function below.
      columns.splice(4, 0, {data: "network", className: "not-mobile"});
    }

    $table.on( 'draw.dt column-visibility.dt', function () {
      that.addTableEvents();
    });
  };

  AdminCompany.prototype.bindTeamsSelect = function() {
    new Select2(function() {
      this.initializeTeamsSelect2WithFnArgs();

      if (!AdminCompany.prototype.bindTeamsSelect.loadedEvents) {
        $document.on('select2:select', '.user-team-select', function(event) {
          $.ajax({
            url: $(this).data("url"),
            data: { team_id: event.params.data.id },
            method: "post"
          });
        });

        $document.on('select2:unselect', '.user-team-select', function(event) {
          $.ajax({
            url: $(this).data("url"),
            data: { team_id: event.params.data.id, _method: "delete" },
            type: "post"
          });
        });

        AdminCompany.prototype.bindTeamsSelect.loadedEvents = true;
      }
    }.bind(this));
  };

  AdminCompany.prototype.bindManagerAutocomplete = function($target) {
    new Select2(function () {
      var $table = $('#users_admin-table');

      var url = "/coworkers";
      var network = $table.data('network');
      if(network) {
        url = url + "?network="+network;
      }

      if ($target === undefined) {
        $target = $(".manager-select");
      }

      $target.filter(':not(.select2-hidden-accessible)').select2({
        ajax: {
          url: url,
          dataType: 'json',
          delay: 250,
          data: function (params) {
            return {
              term: params.term, // search term
              page: params.page,
              include_self: true
            };
          },
          processResults: function (data, page) {
            return {
              results: data
            };
          },
          cache: true
        },
        escapeMarkup: function (markup) {
          return markup;
        },
        minimumInputLength: 1,
        templateResult: formatUser,
        templateSelection: formatUserSelection,
        allowClear: true,
        placeholder: ''
      });
    });

  };

  AdminCompany.prototype.bindManagerSelect = function() {
    if (!AdminCompany.prototype.bindManagerSelect.loadedEvents) {
      $document.on('select2:select', '.manager-select', function (e) {
        $.ajax({
          url: $(this).data("url"),
          data: { manager_id: e.params.data.id, user_id: $(this).data("user") },
          method: "post"
        });
      });
      $document.on('select2:unselect', '.manager-select', function(event) {
        $.ajax({
          url: $(this).data("url"),
          type: "post"
        });
      });
      AdminCompany.prototype.bindManagerSelect.loadedEvents = true;
    }
  };

  AdminCompany.prototype.bindSyncFrequencySelect = function() {
    this.autoSaver.autoSave("#sync_frequency");
  };

  AdminCompany.prototype.bindAddonSelect = function() {
    var settingSelector = "#company_setting_addon_features";
    new Select2(function () {
      $(settingSelector).select2();
    });
    this.autoSaver.autoSave(settingSelector);
  };

  AdminCompany.prototype.bindRewardProviderSelect = function() {
    var settingSelector = "#company_setting_reward_provider_ids";
    new Select2(function () {
      $(settingSelector).select2();
    });
    this.autoSaver.autoSave(settingSelector);
  };

  AdminCompany.prototype.initializeTeamsSelect2WithFnArgs = function ($target) {
    // site admin is intentionally not being allowed to create teams
    // the reason is simply simplicity - no need to support that
    this.usersDatatableUtils.initializeTeamsSelect2($target, formatTeam)
  }

  // See `Accounts.prototype.updateUserRow` for comments.
  AdminCompany.prototype.updateUserRow = function(rowSelector, updatedUserRowData) {
    var table = R.LitaTables['users_admin'].dataTable;
    table.row(rowSelector).data(updatedUserRowData);
    this.usersDatatableUtils.reinitializeRowComponents(rowSelector, this);
  };

  AdminCompany.prototype.addTableEvents = function() {
    this.usersDatatableUtils.bindRolesSelect();
    this.usersDatatableUtils.bindDelegatesSelect();
    this.bindTeamsSelect();
    this.bindManagerAutocomplete();
    this.bindManagerSelect();
  };

  // AdminCompany.prototype.bindRewardProviderSelect = function() {

  AdminCompany.prototype.bindAutoSave = function() {
    this.autoSaver = new R.AutoSaver();
    this.autoSaver.autoSave(".autosave-setting-form input, .autosave-setting-form select, .autosave-setting-form textarea")
  }

  function formatUser(user) {
    if (user.loading) {
      return "Please wait";
    }

    if (user.avatar_thumb_url === R.defaultAvatarPath) {
      user.avatar_thumb_url = "/assets/" + R.defaultAvatarPath;
    }

    var template = HandlebarsTemplates['user/userAvatar'];
    return template({
      "avatar_url": user.avatar_thumb_url,
      "name": user.label
    });
  }

  function formatUserSelection(user) {
    return user.label || user.text;
  }

  function formatTeam(team) {
    return $("<span />").attr('class', 'team-' + team.id).text(team.label || team.text || team.name);
  }

  return AdminCompany;
})();
