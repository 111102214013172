import '~/scripts/integrations/jquery-extended';
import Select2 from '~/scripts/lib/Select2.js'
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';

export default class Catalog {
  constructor() {
    this.addEvents();
  }

  addEvents() {
    new CompanyAdmin();
    this.bindRolesSelect();
    this.bindCurrencySelect2();
    this.bindRatioHinter();
    this.bindRequireApproval();
    this.bindCategorysSelect();
    this.bindCountriesSelect();
    this.bindCurrencyCountriesSelect2();
  }

  removeEvents() {
    var id = $('#new_catalog').length > 0 ? '#new_catalog' : '#edit_catalog';
    R.utils.saveSelectValuesToDom(id);
  }

  bindRolesSelect() {
    new Select2(() => {
      $('.company-role-select').select2(R.utils.companyRoleSelect2TemplateOpts({
        tokenSeparators: [',', ' ']
      }));
    });
  }

  bindCurrencySelect2() {
    new Select2(() => {
      var selectElement = $("#catalog_currency");
      var element = selectElement[0];
      if (!element || element.tagName != 'SELECT') {
        var defaultCurrency = $("#catalog_currency_form_input").val();
        this.updateCountrySelect2(defaultCurrency);
        return;
      }
      $(selectElement).select2();
    });
  }

  bindCategorysSelect() {
    new Select2(() => {
      $('.categories-select').select2();
    });
  };

  bindCountriesSelect() {
    new Select2(() => {
      $('.countries-select').select2();
    });
  };

  bindCurrencyCountriesSelect2() {
    new Select2(() => {
      var that = this;
      $("select#catalog_currency").select2().on('select2:select', function (e) {
        var currencyCode = $(this).val();
        that.updateCountrySelect2(currencyCode);
      });
    });
  };

  bindRatioHinter() {
    this.initializeRatioHint();
    $('#catalog_points_to_currency_ratio').on('input', this.updateRatioHint.bind(this));
    $('#catalog_currency').on('change', this.updateRatioTexts.bind(this));
  }

  bindRequireApproval() {
    $('#auto_catalog').on('change', () => {
      var autoCatalogToggledOn = $("#auto_catalog").prop('checked');
      if (autoCatalogToggledOn) {
        $('#require_approval').addClass('d-none');
      } else {
        $('#require_approval').removeClass('d-none');
      }
    });
  }

  initializeRatioHint() {
    $('#ratio-hint').removeClass('hidden');
    this.updateRatioTexts();
  }

  updateCountrySelect2(currencyCode) {
    var gon_currency_country_map = gon.currency_country_map;
    if (!gon_currency_country_map) {
      return;
    }
    if (!$("#catalog_currency").hasClass("static-field")) {
      var countriesForCurrency = gon_currency_country_map[currencyCode];
      $("#catalog_countries").val(countriesForCurrency).trigger('change');
    }
  }

  updateRatioHint(_event) {
    var ptcScale = Math.pow(10, 5);
    var ratioHint = '';
    var currencySymbol = $('#catalog_currency').find(':selected').text().trim().split(' ')[0];
    if (currencySymbol !== 'Select') {
      var pointsToCurrencyRatio = parseFloat($('#catalog_points_to_currency_ratio').val());
      if (!isNaN(pointsToCurrencyRatio) && pointsToCurrencyRatio > 0) {
        var roundedPointsToCurrencyRatio = Math.round(pointsToCurrencyRatio * ptcScale) / ptcScale;
        if (roundedPointsToCurrencyRatio > 0) {
          var pointsToCurrencyRatio = 1 / roundedPointsToCurrencyRatio;
          var roundedCurrencyToPointsRatio = Math.round(pointsToCurrencyRatio * ptcScale) / ptcScale;
          if (roundedCurrencyToPointsRatio) {
            ratioHint = '1 pt = ' + currencySymbol + roundedCurrencyToPointsRatio;
          }
        }
      }
    }
    $('#ratio-hint').text(ratioHint);
  }

  updateRatioLabel(_event) {
    var ratioCurrencyLabel = '';
    var currencySymbol = $('#catalog_currency').find(':selected').text().trim().split(' ')[0];
    if (currencySymbol !== 'Select') {
      ratioCurrencyLabel = currencySymbol;
    }
    $('#ratio-currency-symbol').text(ratioCurrencyLabel);
  }

  updateRatioTexts(_event) {
    this.updateRatioHint(_event);
    this.updateRatioLabel(_event);
  }
}
