import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $("#rewards_container_turbo_frame .rewards").css({opacity: 1})
    window.addEventListener("popstate", this.reloadPage);
  }

  disconnect() {
    $(".select2.select2-container.select2-container--default").remove();
    window.removeEventListener("popstate", this.reloadPage);
  }

  reloadPage(_event) {
    window.location.reload();
  }
}
