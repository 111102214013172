import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ "source" ];

  connect() {
    // console.log("connected to copy_text_controller");
  }

  copy(event) {
    event.preventDefault();
    let copiedText = this.sourceTarget.innerHTML;
    navigator.clipboard.writeText(copiedText);
    // TODO: Once surveys is merged, Alex made some changes to copy text functionality in utils.js
    //       The two should be DRY'd up. (IMO, existing usages should be moved to this Stimulus controller)
    R.utils.writeToast("Copied");
  }
}
