import { Controller } from "@hotwired/stimulus"
import React from "react"
import { createRoot } from "react-dom/client"
import AddressAutocomplete from "../entrypoints/components/address_autocomplete.jsx"

export default class extends Controller {
  connect() {
    const rootElement = document.getElementById('address-autocomplete');
    if (rootElement) {
      const root = createRoot(rootElement);
      root.render(
        React.createElement(React.StrictMode, null,
          React.createElement(AddressAutocomplete, null)
        )
      );
    }
  }

  disconnect() {
  }
}
