import Select2 from '~/scripts/lib/Select2.js'
import Redemptions from '../../../company_admin/redemptions';

export default class AdminRedemptions extends Redemptions {
  addEvents() {
    this.addSwalEvents();
    this.bindRedemptionViewLink();
    this.bindRedemptionAdditionalInstructionsLink();
    this.bindCurrencyFilter();
    this.bindCatalogCompanyAndRewardProviderFilter();
  }

  bindCatalogCompanyAndRewardProviderFilter() {
    new Select2(function(){
      $("#filter_catalog_company").select2();
      $("#filter_reward_provider").select2();
    });
  }
}
