import '~/scripts/integrations/jquery-extended';
import Select2 from '~/scripts/lib/Select2.js'

window.R.DateRange = (function () {
  var D = function (options) {
      this.options = options || {};
      this.paramScope = this.options.paramScope;
      this.$container = options.container || $(document);
      this.loadLibraries(this.addEvents.bind(this));
  };

  D.prototype.loadLibraries = function(callback) {
    new Select2(function(){
      //console.log('select2 callback');
      new window.R.DatePicker(callback.bind(this));
    }.bind(this));
  };

  D.prototype.addEvents = function() {
    this.bindDateRangeSelect();
    this.bindCustomDateRange();
    $document.on("turbo:before-fetch-request", this.removeEvents.bind(this));
  };

  D.prototype.removeEvents = function() {
    this.$container.off(R.touchEvent, ".date-range .button-group a");
    this.$container.off(R.touchEvent, ".date-range-select--1 input[type=submit]");
    this.$container.off('change', ".date-range-select select");
    this.$container.off('change', ".date-range-select.active select");
    $document.off("turbo:before-fetch-request", this.removeEvents.bind(this));
  };

  D.prototype.bindDateRangeSelect = function() {
    var that = this;

    // activate select2
    this.$container.find(".date-range-select select").select2();

    // bind selecting an option and redirecting
    this.$container.on('change', ".date-range-select.active select", function(){
      var $this = $(this);
      that.rangeSelected($this.find("option:selected").text(), $this.val());
    });

  };

  D.prototype.bindCustomDateRange = function() {
    this.$container.find('.date-range .datepicker').datepicker({
        autoclose: true,
        format: $body.data('slashdateFormat'),
        language: $html.attr('lang'),
        endDate: "0d",
        rtl: $html.attr('dir') === 'rtl' || false,
        // added for some litatable scenarios where this was being oriented at the top and overlapped by the navbar.
        orientation: "bottom"
    });

    var that = this;

    this.$container.on(R.touchEvent, '.date-range-select--1 input[type=submit]', function(evt){
      evt.preventDefault();
      if(that.validateCustomDateRange()) {
        that.rangeSelected(null, that.getCustomDateRange());
      } else {
        window.recognize.patterns.formLoading.resetButton();
      }
      return false;
    });
  };

  D.prototype.visitPageWithParams = function (params) {
    // delete existing page param so that it starts from page 1
    // instead of a later page where data may not be available for the new date range
    var paramsToDelete = ['page'];
    var url = window.R.utils.locationWithNewParams(params, paramsToDelete);

    if (this.options.refresh === 'turbo') {
      Turbo.visit(url);
    } else {
      window.location = url;
    }
  };

  D.prototype.rangeSelected = function(label, selectedValues) {
    if(typeof(selectedValues) === "string") {
      selectedValues = window.R.utils.paramStringToObject(selectedValues);
    }

      // if selection will update view via ajax
      // update label immediately, rather than waiting
      // for page refresh
    // also make sure the old option is not selected(if in another select)
    if(this.options.ajax) {
      this.setHeading(label);
      this.clearOldSelection();
    }

    if(this.options.rangeSelected) {
      this.options.rangeSelected.apply(selectedValues);
    } else {
      this.visitPageWithParams(selectedValues);
    }
  };

  D.prototype.validateCustomDateRange = function() {
    var $customDateRangeContainer = this.$container.find(".date-range-select--1");
    var startControl = $customDateRangeContainer.find('.form-control[name=from]');
    var endControl = $customDateRangeContainer.find('.form-control[name=to]');
    var valid = true;

    if(!startControl.val()) {
      startControl.css({border: "1px solid red"});
      valid = false;
    } else {
      startControl.css({border: "inherit"});
    }

    if(!endControl.val()) {
      endControl.css({border: "1px solid red"});
      valid = false;
    } else {
      endControl.css({border: "inherit"});
    }

    return valid;
  };

  D.prototype.getCustomDateRange = function() {
    var start, end, paramsObject;
    var $customDateRangeContainer = this.$container.find(".date-range-select--1");

    start = $customDateRangeContainer.find('.form-control[name=from]').val();
    end = $customDateRangeContainer.find('.form-control[name=to]').val();

    start = window.R.utils.formatDate(start);
    end = window.R.utils.formatDate(end);

    paramsObject = {start_date: start.unix(), end_date: end.unix(), interval: -1};

    if(this.paramScope) {
      for(var property in paramsObject) {
        if (paramsObject.hasOwnProperty(property)) {
          paramsObject[this.paramScope+"["+property+"]"] = paramsObject[property];
          delete paramsObject[property];
        }
      }
    }
    return paramsObject;
  };

  D.prototype.selectedRange = function() {
    var $selectedContainer = this.selectedContainer();
    if($selectedContainer.hasClass('date-range-select--1')) {
      return this.getCustomDateRange();
    } else {
      return window.R.utils.paramStringToObject($selectedContainer.find('select').val());
    }
  };

  D.prototype.selectedContainer = function() {
    return this.$container.find(".date-range-select.active");
  };

  D.prototype.setHeading = function(label) {
    var $selectedContainer = this.selectedContainer();
    var $heading = this.$container.find(".selected-heading *");

    if($selectedContainer.hasClass('date-range-select--1')) {
      var start, end;
      var $customDateRangeContainer = this.$container.find(".date-range-select--1");

      start = $customDateRangeContainer.find('.form-control[name=from]').val();
      end = $customDateRangeContainer.find('.form-control[name=to]').val();
      $heading.html(start+" - "+end);
    } else {
        $heading.html(label);
    }
  };

    D.prototype.clearOldSelection = function() {
    this.$container.find(".date-range-select:not(.active) select").each(function(){
      $(this).val('');
    });
  };

  D.prototype.from = function() {
    return this.selectedRange()[this.scopedParam("start_date")];
  };

  D.prototype.to = function() {
    return this.selectedRange()[this.scopedParam("end_date")];
  };

  D.prototype.scopedParam = function(param) {
    if(this.paramScope) {
      return this.paramScope+"["+param+"]";
    } else {
      return param;
    }
  };

  return D;
})();
