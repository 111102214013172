import CompanyAdmin from '~/scripts/lib/CompanyAdmin';

export default (function() {
  var CompanyRoles = function() {
    this.addEvents();
  };

  CompanyRoles.prototype.addEvents = function() {
    var companyAdmin = new CompanyAdmin();
  };

  return CompanyRoles;
})();
