import '~/scripts/integrations/jquery-extended';
import Select2 from '~/scripts/lib/Select2.js'

window.R.UsersDatatableUtils = (function() {
  var UsersDatatableUtils = function() {
  };

  // Used by `activate` and `destroy` actions
  UsersDatatableUtils.prototype.reinitializeRowComponents = function(row, that, admin_users) {
    var $row = $(row) // ensure jquery object
    var $companyRoleSelect = $row.find("select.user-company-role-select");
    var $delegateRoleSelect = $row.find("select.delegate-role-select");
    var $teamSelect = $row.find("select.user-team-select");
    var $managerSelect = $row.find("select.manager-select");
    this.initializeCompanyRolesSelect2($companyRoleSelect);
    this.initializeDelegatesSelect2($delegateRoleSelect);
    // the following methods are defined in the
    // respective callers
    that.initializeTeamsSelect2WithFnArgs($teamSelect);
    if (admin_users == undefined) {
      that.bindManagerAutocomplete($managerSelect);
    }
  };

  UsersDatatableUtils.prototype.initializeRolesSelect2 = function($target, allowClear) {
    if (allowClear === undefined)
      allowClear = false;

    var $table = $target.parents("table");

    function company_role_select2_template(cr) {
      var result = $("<span />").attr('class', 'company_role-' + cr.id).text(cr.name || cr.text);
      if (cr.element && cr.element.attributes.locked) {
        result.addClass('select2-selection__choice__locked');
        result.append($("<span />").attr({
          title: gon.dynamic_roles_info,
          class: "dynamic-roles-info-tooltip"
        }).append("<sup><i>"+cr.element.attributes.sync_source.value+"</i></sup>"));
      }
      return result;
    }

    $target.filter(':not(.select2-hidden-accessible)').select2({
      minimumInputLength: 1,
      allowClear: allowClear,
      placeholder: '',
      templateResult: company_role_select2_template,
      templateSelection: company_role_select2_template,
      ajax: {
        url: '/autocomplete/company_roles',
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            term: params.term, // search term
            network: $table.data('network'),
            page: params.page,
          };
        },
        processResults: function (data, page) {
          return {
            results: data
          };
        },
        cache: true
      },
    });
  }

  UsersDatatableUtils.prototype.initializeCompanyRolesSelect2 = function($target) {
    if ($target === undefined) {
      $target = $(".user-company-role-select");
    }
    this.initializeRolesSelect2($target)
    this.ensureRemoveIconHiddenForLockedItems();
  };

  UsersDatatableUtils.prototype.initializeDelegatesSelect2 = function($target) {
    if ($target === undefined) {
      $target = $(".delegate-role-select");
    }

    this.initializeRolesSelect2($target, true);
  };

  UsersDatatableUtils.prototype.initializeTeamsSelect2 = function($target, formatTeamTagFn, createTeamTagFn) {
    var that = this;
    if ($target === undefined) {
      $target = $(".user-team-select");
    }

    var $table = $target.parents("table");

    var opts = {
      minimumInputLength: 1,
      ajax: {
        url: '/autocomplete/teams',
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            term: params.term, // search term
            network: $table.data('network'),
            page: params.page,
          };
        },
        processResults: function (data, page) {
          return {
            results: data
          };
        },
        cache: true
      }
    };

    if (formatTeamTagFn) {
      opts['templateResult'] = formatTeamTagFn;
      opts['templateSelection'] = formatTeamTagFn;
    }
    if (createTeamTagFn) {
        opts['tags'] = true;
        opts['createTag'] = createTeamTagFn;
    }

    $target.filter(':not(.select2-hidden-accessible)').select2(opts)

  };

  UsersDatatableUtils.prototype.bindRolesSelect = function($target){
    var that = this;
    new Select2(function () {
      that.initializeCompanyRolesSelect2();

      if (!UsersDatatableUtils.prototype.bindRolesSelect.loadedEvents) {
        $document.on('select2:select', '.user-company-role-select', function(event) {
          var url = $(this).data("url");
          var data = { role_id: event.params.data.id };
          window.R.utils.simpleAjaxPostWithoutCallbacks(url, data);
          that.ensureRemoveIconHiddenForLockedItems();
        });

        $document.on('select2:unselect', '.user-company-role-select', function(event) {
          var url = $(this).data("url");
          var data = { role_id: event.params.data.id, _method: "delete" };
          window.R.utils.simpleAjaxPostWithoutCallbacks(url, data);
          that.ensureRemoveIconHiddenForLockedItems();
        });
        UsersDatatableUtils.prototype.bindRolesSelect.loadedEvents = true;
      }
    });
  };

  UsersDatatableUtils.prototype.bindDelegatesSelect = function($target) {
    var that = this;
    new Select2(function () {
      UsersDatatableUtils.prototype.initializeDelegatesSelect2();
      if (!UsersDatatableUtils.prototype.bindDelegatesSelect.loadedEvents) {
        $document.on('select2:select', '.delegate-role-select', function(event) {
          var url = $(this).data("url");
          var data = { delegated_company_role_id: event.params.data.id };
          window.R.utils.simpleAjaxPostWithoutCallbacks(url, data);
        });

        $document.on('select2:unselect', '.delegate-role-select', function(event) {
          var url = $(this).data("url");
          var data = {};
          window.R.utils.simpleAjaxPostWithoutCallbacks(url, data);
        });
        UsersDatatableUtils.prototype.bindDelegatesSelect.loadedEvents = true;
      }
    });
  };

  // This hiding is also done via css but it uses the new :has() selector 
  // which is not supported for firefox or older browser
  // Note: When cross icon is visible, it is not functional due to our custom fix.
  UsersDatatableUtils.prototype.ensureRemoveIconHiddenForLockedItems = function() {
    var child = $('table').find('.select2-selection__choice__locked')
    child.each(function(index, element) {
      element.parentElement.children[0].classList.add('d-none')
    });
  }

  return UsersDatatableUtils;

})();
