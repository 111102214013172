export default class PR {
  constructor() {
    this.addEvents();
  }

  addEvents() {
    var pu = new R.ui.PasswordUtils();
    pu.bindAll();
  }
}
