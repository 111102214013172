// NOTE: somewhat adapted from published ChartJS Stimulus controller
//       https://github.com/stimulus-components/stimulus-chartjs/blob/master/src/index.ts
//       Intention is to be the base application class for HighCharts controllers
import { Controller } from "@hotwired/stimulus"

export default class ApplicationChartController extends Controller {
  static values = {
    type: {
      type: String,
      default: "line",
    },
    config: Object,
  }

  connect() {

    this.chart = Highcharts.chart(this.element, this.getConfig())
  }

  disconnect() {
    this.chart.destroy()
    this.chart = undefined
  }

  getConfig() {
    return this.configValue
  }
}
