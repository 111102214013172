import '~/scripts/integrations/jquery-extended';
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';

export default (function() {
  var Tasks = function() {
    this.addEvents();
  };

  Tasks.prototype.addEvents = function() {
    new CompanyAdmin();
    $document.on("ajax:complete", "a.task-status-toggle[data-remote]", taskStatusToggle);
  };
  return Tasks;
})();

function taskStatusToggle(e) {
  "use strict";
  var $this = $(e.target);
  $this.text($this.data('task-status'));
}
