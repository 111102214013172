import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  connect() {
    const elementId = '#' + this.element.id;
    window.R.gage(elementId);
    $(elementId).tooltip();
  }

  disconnect() {
    const elementId = '#' + this.element.id;
    $(elementId).tooltip('dispose');
  }
}