/**
 *  This remote toggle class is deprecated in favour of new stimulus controller [app/frontend/controllers/remote_toggle_controller.js]
 *  - However, this file handles iOS checkboxes which is still being used in site admin's pages
 *  - And company admin's Points and Rewards pages
 */

import Swal from 'sweetalert2';

export default function remoteCheckboxToggle() {
  $(".remoteCheckboxToggle").each(function(_, el) {
    $(el).iOSCheckbox({
      onChange: handleChange
    });

  }.bind(this));
}

function handleChange() {
  let $target = $(this.elem);
  let endpoint = $target.data('endpoint');
  let checkedValue = !!this.elem.prop("checked");
  let confirmText = (checkedValue ? 'Are you sure you want to enable this theme?' : 'Are you sure you want to disable this theme?');

  if ($target.data('confirm-text')){
    Swal.fire({
      title: confirmText,
      confirmButtonText: 'Yes',
      showCancelButton: true,
      showLoaderOnConfirm: true,
    }).then( function(result) {
      if (!result.value) {
        reverseToggle($target, checkedValue)
        return false;
      }
      else{
        sendRequest($target, endpoint, checkedValue)
      }
    })
  }
  else{
    sendRequest($target, endpoint, checkedValue)
  }
}

function sendRequest(target, endpoint, checkedValue){
  var data = {}, type = null;
  if (target.data('invert-values') === true) { // used as `invert_values` in view
    checkedValue = !checkedValue;
  }
  data[target.prop('name')] = checkedValue;
  type = target.data('method') || "POST"

  if(endpoint) {
    $.ajax({
      url: endpoint,
      type: type,
      data: data
    })
  } else {
    console.warn("Endpoint data attribute is missing on checkbox")
  }
}

function reverseToggle(target, checkedValue){
  let parentId = target.parents()[2].id;

  let $LabelOn = $('#' + parentId + '.iOSCheckLabelOn');
  let $LabelOnSpan = $('#' + parentId + '.iOSCheckLabelOn span');
  let $Handle = $('#' + parentId + '.iOSCheckHandle');
  let $LabelOffSpan = $('#' + parentId + '.iOSCheckLabelOff  span');

  // Setting the UI back
  if (!checkedValue){ //ON
    $('#' + parentId + '.on-off.remoteCheckboxToggle').attr('checked', 'checked');
    $LabelOn.css('width', '51px');
    $Handle.css('left', '47px');
    $LabelOffSpan.css('margin-right', '-47px');
    $LabelOnSpan.css('margin-left', '0px');
  }
  else{ // OFF
    $('#' + parentId + '.on-off.remoteCheckboxToggle').removeAttr('checked');
    $LabelOffSpan.css('margin-right', '0px');
    $LabelOn.css('width', '0px');
    $LabelOnSpan.css('margin-left', '-47px');
    $Handle.css('left', '0px');
  }
}
