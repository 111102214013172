export default function() {
  this.recognitionCards = new R.Cards("#anniversariesWrapper", {
    layoutMode: 'fitRows',
    gridView: false,
    skipGlobalCallbacks: false
  });

  new window.R.DatePicker(function(){
    $(".datepicker").datepicker({
      format: $body.data('slashdateFormat'),
      autoclose: true,
      clearBtn: true,
      endDate: "0d",
      language: $html.attr('lang'),
      rtl: $html.attr('dir') === 'rtl' || false
    });
  });
};
