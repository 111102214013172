import '~/scripts/integrations/jquery-extended';
import DOMPurify from 'dompurify';
import Pagelet from '~/scripts/lib/Pagelet';
import Select2 from '~/scripts/lib/Select2.js'

export default (function() {
  var H = function() {
    this.scrollingElement = ".time-periods-wrapper";

    this.addEvents();
  };

  H.prototype.addEvents = function() {
    new Select2(bindSelect2);
    this.pagelet = new Pagelet();
  };

  H.prototype.moreResultsAjax = function(el, callback) {
      var $el = $(el);
      var $parent = $el.closest(".time-periods-wrapper");
      var endpoint;

      $el = $parent.find('.see-more-button:last-child');

      // If no load more button just hide it and stop.
      if (!$el.length || $parent.hasClass('loading')) {
        return $($el.context).addClass('hidden');
      } else {
        $parent.addClass('loading');
        endpoint = $el.data().endpoint;
      }

      // If there is a load more button there is mor to load.
      $.get(endpoint, function(data) {
        var sanitizedData = DOMPurify.sanitize(data);
        var $seeMoreButton;
        $parent.find(".see-more-wrapper").remove(); // remove old see more li
        $parent.find('.pagelet').append(sanitizedData);
        $parent.removeClass('loading');

        // get next button
        $seeMoreButton = $parent.find(".see-more-wrapper .see-more-button");

        // if there isn't a button, there is no more to load
        // so hide the arrow
        if (!$seeMoreButton.length) {
          return $($el.context).addClass('hidden');
        }

        if ( callback && (typeof callback === "function") ) { callback(); }
      });
  };

  H.prototype.removeEvents = function() {
    $('.param-select').off('select2:select');
  };

  function bindSelect2() {
    var $selects = $('.param-select').select2();
    $selects.on('select2:select', function(e){
      var $this = $(this),
          queryObj;

      if($this.data('reset-tools')) {
        queryObj = {};
        queryObj[$this.prop('name')] = $this.val();

        // also manually carry over viewer
        var viewer = window.R.utils.queryParams()['viewer']
        if(viewer) {
          queryObj['viewer'] = viewer;
        }

      } else {
        queryObj = window.R.utils.queryParams();
        queryObj[$this.prop('name')] = $this.val();
      }

      Turbo.visit(window.location.pathname+"?"+$.param(queryObj));

    });
  }

  return H;
})();
