import '~/scripts/integrations/jquery-extended';

window.Autocomplete = (function() {
  var cachedResultsHash = {};

  var A = function(config) {
    var $input = $(config.input), options;
    config.$input = $input;
    this.createUsers = config.createUsers || true;
    this.includeSelf = config.includeSelf || false;
    this.includeTeams = (typeof config.includeTeams === "undefined") ? true : config.includeTeams;
    this.hasSubscription = !!$("body.subscription-active").length;

    options = this.extendOptions(config);

    $.widget("custom.recognizeAutoComplete", $.ui.autocomplete, window.Autocomplete.recognizeAutocomplete());
    $input.recognizeAutoComplete(options);

    $window.on('unload', function() {
      cachedResultsHash = null;
    });

    if ($html.hasClass("ie9")) {
      $input.click(function() {
        $(this).val("");
      });
    }

    // load Yammer autocomplete
    if (!this.hasSubscription && this.createUsers && window.yammerSettings && window.yammerSettings.accessToken) {
      window.R.y = new window.Yammer();
    }

    $document.bind("turbo:before-fetch-request", function() {
      cachedResultsHash = {};
      $input = null;
    });
  };

  A.prototype.extendOptions = function(config) {
    var extendedOptions = window.Autocomplete.options;

    var options = {
      minLength: 2,
      autoFocus: true,
      appendTo: config.appendTo || "#recognition-new-autocomplete-wrapper",
      response: function() {
        // Fix bug where the spinner gets stuck
        $(".ui-autocomplete-loading").removeClass("ui-autocomplete-loading");
      },
      select: function(evt, ui) {
        var item = ui.item;
        if (item.label == 'No Match' && !item.value) {
          return false;
        }

        var email = item.email;

        var successOpts = {
          avatar_thumb_url: item.avatar_thumb_url,
          name: item.label || email,
          id: item.id,
          email: email,
          type: item.type
        };

        // Add recognition api
        if(item.web_url) {
          successOpts.web_url = item.web_url;
        }
        config.success(successOpts);

        return false;
      },

      close: function() {
        config.$input.val('').data().customRecognizeAutoComplete.term = null;
      },
      source: function(request, response) {
        var term = request.term;
        var cachedResults = cachedResultsHash[term];

        if (cachedResults) {
          return callResponse(response, cachedResults, term, this.includeTeams);
        }

        request.limit = 20;

        if (this.createUsers && term.indexOf("@") > -1) {
          response([term]);
        } else {
          let previousRequest = window.Autocomplete.currentRequest;
          if( previousRequest ) {
              // a previous request has been made.
              // though we don't know if it's concluded
              // we can try and kill it in case it hasn't
              previousRequest.abort();
          }
          var ajaxRequest = this.getCoworkers(request, response, term);
          window.Autocomplete.currentRequest = ajaxRequest;
        }
      }.bind(this) // source: function
    };

    return options;
  };

  A.prototype.getCoworkers = function(request, response, term) {
    return $.getJSON("/coworkers?include_self="+this.includeSelf, request, function(data) {
        var suggestions = [];
        //TODO: if list is greater than X
        //      prompt user to type more letters
        $.each(data, function(i, val) {
          suggestions.push(val);
        });

        if (suggestions.length) {
          cachedResultsHash[term] = suggestions;
        }

        suggestions.push(term);

        //response(suggestions);
        callResponse(response, suggestions, term, this.includeTeams);
      }.bind(this));
  };

  function callResponse(response, suggestions, term, includeTeams) {
    var teams = window.R.teams.filter(function(team) {
      return (team.label.toLowerCase().indexOf(term.toLowerCase()) !== -1);
    });
    if (includeTeams) {
      return response(suggestions.concat(teams));
    }

    return response(suggestions);
  }
  return A;
})();
