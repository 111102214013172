import '~/scripts/integrations/jquery-extended';
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';
import FormErrors from '~/scripts/lib/FormErrors';
import Select2 from '~/scripts/lib/Select2.js'
import HandlebarsTemplates from '~/scripts/integrations/handlebars-templates';

export default class Page {
  static tableId = '#recognitions-table';
  static select2ParticipantFilterClass = ".filter_recognition_participant"
  static otherSelect2FilterClasses = ".filter_company_role, .filter_country, .filter_department, .filter_team"

  constructor() {
    'use strict';
    this.$table = $(Page.tableId);
    $("#recognitions-table").on('draw.dt', function(){
      this.addEvents();
    }.bind(this));
    this.bindParticipantAutocompletes();
  }

  addEvents() {
    new CompanyAdmin();
    this.addSwalEvents();
    window.R.QuickNominations();

    // add select2 to filters
    new Select2(function(){
      $(Page.otherSelect2FilterClasses).select2(R.utils.companyRoleSelect2TemplateOpts());
    });

    // END - FIXME: obsolete code
    $('sup[title]').tooltip();
  }

  bindParticipantAutocompletes() {
    var select2ParticipantFilterSelect = $(Page.select2ParticipantFilterClass);
    var opts = { "avatar_col": 'col-2', "name_col": 'col-10' }
    window.R.utils.select2RemoteUsers(select2ParticipantFilterSelect, opts);

    select2ParticipantFilterSelect.on("select2:select", function(e) {
      var elementsToDisable = $(Page.otherSelect2FilterClasses);
      elementsToDisable.prop("disabled", true).val(null);
    });

    select2ParticipantFilterSelect.on("select2:unselect", function() {
      var elementsToDisable = $(Page.otherSelect2FilterClasses);

      var allSelectedValues = select2ParticipantFilterSelect.map(function() {
        return $(this).val();
      }).get();

      var senderValues = allSelectedValues[0];
      var receiverValues = allSelectedValues[1];
      var shouldDisable = senderValues != undefined || receiverValues != undefined;

      elementsToDisable.prop("disabled", shouldDisable);
    });
  }

  renderGroupRow(rowGroup, rows, group) {
    var totalColumns = rowGroup.s.dt.columns()[0].length;
    var rowsData = rows.data().reduce(function(a,b){a.push(b);return a}, [])
    var actions = rowsData[0].actions; // same for all rows in group
    if(actions) {
      return  $('<tr/>').prop('id', group)
        .append('<td colspan='+totalColumns+'>' +
                  '<p>'+group+'</p>' +
                  '<div id="recognition-actions-'+group+'">'+actions+'</div>' +
                ' </td>');
    } else {
      return $('<tr/>').prop('id', group)
        .append('<td colspan='+totalColumns+'>' +
                  '<p id="'+group+'">'+group+'</p>' +
                '</td> ');
    }
  }

  removeEvents() {}

  addSwalEvents() {
    $body.on(R.touchEvent, ".approve-button", this.approveButton.bind(this));
    $body.on(R.touchEvent, ".deny-button", this.denyButton.bind(this));
    $body.on(R.touchEvent, "#points-slider", this.updateSliderLabel.bind(this));
  }

  updateSliderLabel(e) {
    $('#slider-label').text(e.target.value);
  }

  approveButton(e) {
    e.preventDefault();
    this.verifyApprovePopup(e.target, "Approve Recognition");
  }

  approval_instruction(diffPoints){
    return diffPoints ? '<div>Review the message and adjust points accordingly.</div>' : 'Review recognition message'
  }

  verifyApprovePopup(el, title) {
    var $this = $(el);
    let opts = { ...$this.data() }
    opts.diffPoints = $this.data("minPoints") !== $this.data("maxPoints");
    opts.pointsDeducted = $this.data("maxPoints") != $this.data("pointsGivenBySender");

    var recognitionMessage = $this.data("message") || '';
    var swalOpts = this.getGenericSwalOptsForApprovalWorkflow('Approve', 'recognition-approval-swal', this.submitApprovalForm.bind(el));

    if (!opts.pointsAllocationEnabled || opts.minPoints  <= opts.maxPoints) {
      swalOpts.html += `<div><img src='${opts.badgeImage}' height="100px" width="100px"></div>`;
      swalOpts.html += `<h2 class="mb-1 mt-1">${title}</h2>`
      swalOpts.html += this.approval_instruction(opts.diffPoints);
      swalOpts.html += this.formatSwalInputGroupForRecognitionAttributes(opts);
      swalOpts.html += this.formatSwalInputGroupForRecognitionMessage(recognitionMessage);
      swalOpts.html += this.sliderContents(opts)
    } else {
      swalOpts['title'] = 'Cannot approve recognition!';
      swalOpts.html += '<p> Sender needs at least ' + opts.minPoints + ' points to send this recognition.</p>';
      swalOpts['showConfirmButton'] = false;
    }

    var inputFormat = $this.data('inputFormat');
    // this field is mutated when the wysiwyg editor is initialized
    swalOpts.html += '<input name="input_format" type="hidden" value="' + inputFormat + '"/>';

    var wysiwygEditorInitialized;
    var callbackOpts = {
      didOpen: function(modalEl) {
        var $modalEl = $(modalEl);
        if ((gon.trumbowyg || {}).wysiwyg_editor_enabled || inputFormat === 'html') {
          var $message = $modalEl.find('#message');
          var inputFormatOpts = {
            $elem: $modalEl.find('input[name="input_format"]'),
            currentFormat: inputFormat
          };

          R.ui.initWYSIWYG($message, inputFormatOpts);
          wysiwygEditorInitialized = true;
        }
      },
      willClose: function (modalEl) {
        if (wysiwygEditorInitialized) {
          R.ui.destroyWYSIWYG($(modalEl).find('#message'));
        }
      }
    };
    $.extend(swalOpts, callbackOpts);

    Swal.fire(swalOpts).then(function(result) {
      if (result.value !== undefined) {
        var $prc = $("#pending-recognition-count");
        $prc.text(parseInt($prc.text()) - 1);
        Swal.fire('Recognition Approved!', 'Recognition has been successfully approved.', 'success');
      }
    });
  }

  denyButton(e) {
    e.preventDefault();
    this.verifyDenyPopup(e.target, "Deny recognition?", "Double checking you want to deny this recognition.");
  }

  verifyDenyPopup(el, title, description) {
    var $this = $(el);

    var swalOpts = this.getGenericSwalOptsForApprovalWorkflow('Deny', 'recognition-denial-swal', this.submitDenialForm.bind(el))
    swalOpts['title'] = title;
    swalOpts.html += description;
    swalOpts.html += this.formatSwalInputGroupForRecognitionAttributes($this.data(), true);

    var inputAttributes = {
      input: 'text',
      inputPlaceholder: 'Reason for denial',
      inputAttributes:  {name: 'denial_message'}
    };
    $.extend(swalOpts, inputAttributes);

    Swal.fire(swalOpts).then(function(result) {
      if (result.value !== undefined) {
        var $prc = $("#pending-recognition-count");
        $prc.text(parseInt($prc.text()) - 1);
        Swal.fire('Recognition Denied!', 'Recognition has been successfully denied.', 'success');
      }
    });
  }

  submitApprovalForm() {
    return new Promise(function(resolve, reject) {
      var $swalContainer = $('.swal2-container');
      var ajaxData = {
        message: $swalContainer.find('textarea[name="message"]').val(),
        points_by_approver: $swalContainer.find('input[name="points_by_approver"]').val(),
        input_format: $swalContainer.find('input[name="input_format"]').val(),
        request_form_id: $(this).data('request-form-id')
      };

      postToEndpointAndSettlePromise(this, ajaxData, resolve, reject);
    }.bind(this)).catch(function (reason) {
      // this prevents the modal from closing when there is an error (eg. validation errors)
      Swal.showValidationMessage(reason);
    });
  }

  formatSwalInputGroupForRecognitionAttributes(opts, includeMessage) {
    // Used existing includeMessage true sent via deny form to manipulte deny recognition swal
    let recipientLabel = /,| and /.test(opts.recipients) ? 'Recipients: ' : 'Recipient: ';
    if (typeof includeMessage === "undefined") includeMessage = false;

    opts["recipientLabel"] = recipientLabel;
    opts["isDenyForm"] = includeMessage;

    // Recognition message for deny form is displayed without wysiwyg editor like a sentence or a paragraph
    // Render inside swal instead via handlebar template for unescaped recognition message
    if(opts["isDenyForm"]) {
      var message = '<div><span>Message: </span>' +
                      '<div class="message">"' + (opts.message || '') + '"</div></div>';
    } else {
      message = '';
    }

    var content = '<div class="recognition-attributes-wrapper well width100">' +
                    HandlebarsTemplates['recognition_approval/approvalSwalCopy'](opts) + message +
                  '</div>';

    return content;
  }

  formatSwalInputGroupForRecognitionMessage(message) {
    var label = "Message:";
    return '<div class="message-wrapper">' +
             '<p class="fs-6 fw-bold mb-0">' + label + '<p>' +
             '<textarea id="message" name="message">' + message + '</textarea>' +
           '</div>';
  }

  sliderContents(opts) {
    return HandlebarsTemplates['recognition_approval/slider'](opts)
  }

  arrayToHash(arr) {
    return arr.reduce(function(map, val){
      map[val] = val.toString();
      return map;
    }, {});
  }

  submitDenialForm() {
    return new Promise(function(resolve, reject) {
      var $swalContainer = $('.swal2-container');
      var ajaxData = {
        denial_message: $swalContainer.find('input[name="denial_message"]').val()
      };

      postToEndpointAndSettlePromise(this, ajaxData, resolve, reject);
    }.bind(this)).catch(function (reason) {
      // this prevents the modal from closing when there is an error (eg. validation errors)
      Swal.showValidationMessage(reason);
    });
  }

  getGenericSwalOptsForApprovalWorkflow(confirmBtnText, customClass, preConfirmFn) {
    return {
      icon: `${(customClass == 'recognition-denial-swal') ? "warning" : ""}`,
      title: '',
      html: '',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: confirmBtnText,
      customClass: customClass,
      preConfirm: preConfirmFn,
      allowOutsideClick: function () {
        return !Swal.isLoading();
      }
    };
  }
}


function postToEndpointAndSettlePromise(element, ajaxData, resolve, reject) {
  var defaultAjaxData = {_method: 'put'};
  var data = $.extend({}, defaultAjaxData, ajaxData);

  $.ajax({
    url: $(element).data('endpoint'),
    type: 'post',
    data: data
  }).then(
    function success(response) { resolve() },
    function failure(error) {
      var errorMessage = 'Sorry, something went wrong. Please try again later.';

      if (error.status === 422 && error.responseJSON) {
        var errors = error.responseJSON.errors;
        errorMessage = new FormErrors(null, errors).getErrorMessagesFromErrorsObject().join("<br>");
      }
      reject(errorMessage);
    }
  );
}
