import '~/scripts/integrations/jquery-extended';
import Swal from 'sweetalert2';

export default (function() {
  'use strict';
  var RedemptionsIndex = function() {
    var layoutMode = "fitRows";
    this.$container = $body;
    this.addEvents();
    this.updateAllRewardCardAsPerSelectedVariantQuantity();
  };

  RedemptionsIndex.prototype.addEvents = function() {
    this.bindRewardVariantSelection();
    this.bindRewardDimensionSelection();
    this.updateRewardImagesOnHover();
    this.removeBlurOverlayOnContainer();

    $window.bind("ajaxify:success:updatedRewards", function(evt, response) {
      this.rewardRedeemed(evt, response);
    }.bind(this));
  };

  RedemptionsIndex.prototype.updateAllRewardCardAsPerSelectedVariantQuantity = function() {
    var $rewardCard = $(".reward-card");
    this.updateRewardCardAsPerSelectedVariantQuantity($rewardCard);
  };

  RedemptionsIndex.prototype.bindRewardVariantSelection = function() {
    var that = this;
    $("select.redemption-variant-select").change(function() {
      var $rewardCard= $(this).closest(".reward-card");
      that.updateRewardCardAsPerSelectedVariantQuantity($rewardCard);
    });
  };

  RedemptionsIndex.prototype.updateRewardCardAsPerSelectedVariantQuantity = function($rewardCard) {
    var rewardHasVariantsWithQuantity = $rewardCard.hasClass('has-variants-with-quantity') === true;
    var rewardHasVariantsSelect = $rewardCard.find("select.redemption-variant-select").length !== 0;
    // Caution: A reward with one variant with quantity doesn't have `select`.
    if(rewardHasVariantsWithQuantity && rewardHasVariantsSelect) {
      var $selectedVariant = $rewardCard.find("select.redemption-variant-select").find("option:selected");
      this.updateAvailabilityStatusInRewardCard($rewardCard, $selectedVariant);
    }
  };

  RedemptionsIndex.prototype.updateAvailabilityStatusInRewardCard = function ($rewardCard, $selectedVariant) {
    var selectedVariantAvailabilityStatus = $selectedVariant.data("variant-availability-status");
    var selectedVariantIsRedeemable = selectedVariantAvailabilityStatus === "redeemable";
    if (selectedVariantIsRedeemable) {
      $rewardCard.find('button').prop('disabled', false).removeClass('disabled');
      $rewardCard.removeClass("unredeemable").addClass("redeemable");
    } else {
      $rewardCard.find('button').prop('disabled', true).addClass('disabled');
      $rewardCard.removeClass("redeemable").addClass(selectedVariantAvailabilityStatus).addClass("unredeemable");
    }
  };

  function formattedTerms(label, terms){
    var message = '<div class="alignLeft">' + "<h4>"+ label +"</h4>" + terms + '</div>';
    message = message + "<hr>";
    return message;
  }

  function formattedLabel(label){
    var message = "<p><i>" + label + "</i></p>";
    return message;
  }

  RedemptionsIndex.prototype.rewardRedeemed = function(evt, response) {
    // Fix for extracting the main reward card directly instead from the form.
    // The refactor is needed as it no longer points as the parent of the form
    var $wrapper = $(".reward-card");
    var quantity = 0;

    var swal = Swal.fire({
      title: response.data.params.content.title,
      footer: response.data.params.content.description,
      imageUrl: response.data.params.redemption.image_url,
      icon: 'success',
      showCancelButton: false,
      confirmButtonText: response.data.params.content.confirm_button_text
    });

    if(response.data.params.content.confirm_button_url) {
      swal.then(function(result) {
        if(result.value) {
          window.location.href = response.data.params.content.confirm_button_url;
        }
      });
    }

    $wrapper.removeClass("redeemable").addClass("redeemed");

    $wrapper.find(".reward-form-wrapper").hide();

    quantity = response.data.params.remaining_quantity <= 0 ? 0 : response.data.params.remaining_quantity;
    $wrapper.find(".reward-quantity").text(quantity);

    $wrapper.addClass("redeemed-success");

    var userRedeemablePoints = response.data.params.redeemable_points;
    this.updatePointsAvailableToRedeem(userRedeemablePoints);
    this.updateAvailabilityOfRewards(userRedeemablePoints);
  };

  RedemptionsIndex.prototype.updatePointsAvailableToRedeem = function(userRedeemablePoints) {
    $("span.redeemable_points_total").html(userRedeemablePoints);
  };

  RedemptionsIndex.prototype.updateAvailabilityOfRewards = function(userRedeemablePoints) {
    var that = this;
    $(".reward-card").each(function(){
      var $this = $(this);

      var pointsNeeded = -(userRedeemablePoints - parseInt($this.data('points')));

      if(pointsNeeded > 0) {
        $this.removeClass("redeemable").addClass("unredeemable");
        $this.find(".points-needed").html(pointsNeeded);
      } else {
        $this.removeClass("unredeemable").addClass("redeemable");
      }

      that.updateAvailabilityOfRewardVariant($this, userRedeemablePoints);
    });
  };

  //Remove variants unreedemable by points.
  RedemptionsIndex.prototype.updateAvailabilityOfRewardVariant = function($rewardCard, userRedeemablePoints) {
    var $variantSelectOptions = $rewardCard.find("select.redemption-variant-select option");
    $variantSelectOptions.each(function(){
      var $option = $(this);
      var variantPoint = $option.data('points');
      var pointsNeeded = -(userRedeemablePoints - parseInt(variantPoint));
      if (pointsNeeded > 0) {
        $option.remove();
        var variantSelectOptionsNoMore= $rewardCard.find("select.redemption-variant-select option").length === 0;
        if (variantSelectOptionsNoMore) {
          $rewardCard.removeClass('has-variants-with-quantity');
        }
      }
    });
  };

  RedemptionsIndex.prototype.bindRewardDimensionSelection = function() {
    new window.R.Select2(function(){
      $(".select2").select2().on("select2:select", function(e) {
        var $this = $(this),
          queryObj,
          url;

        queryObj = window.R.utils.queryParams();
        var queryParams = $.param(queryObj);

        url = window.location.pathname.replace(/\/[^\/]*$/, '/' + $this.val()) + "?" + queryParams;

        if (window.location.hash !== "") {
          url += window.location.hash;
        }

        // window.location = url;
        $body.find(".reward-center-right-cols").addClass('blur-loader');
        Turbo.visit(url);

      });
    });
  };

  RedemptionsIndex.prototype.updateRewardImagesOnHover = function () {
    var imgThumbnail = $(".img-thumbnail");

    imgThumbnail.on("mouseover", function () {
      var $this = $(this);
      imgThumbnail.removeClass("active");
      $this.addClass("active");

      var imgSrcToReplace = $this.parent().find(".img-large").find('img').attr('src');
      var mainImgWrapper = $this.parents().next(".img-wrapper");
      mainImgWrapper.find("img").attr("src", imgSrcToReplace);
    });
  };

  // Clearing blur on browser back button press.
  RedemptionsIndex.prototype.removeBlurOverlayOnContainer = function() {
    if ($(".blur-loader").length > 0) {
      $body.find(".reward-center-right-cols").removeClass('blur-loader');
    }
  };

  RedemptionsIndex.prototype.removeEvents = function() {
    $document.off(R.touchEvent, "#redemptions-show .confirmForm button");
    $window.unbind("ajaxify:success:updatedRewards");
  };

  return RedemptionsIndex;
})();
