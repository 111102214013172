import '~/scripts/integrations/jquery-extended';
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';

export default (function() {
  var Tr = function() {
    new CompanyAdmin();
    this.addEvents();
  };

  Tr.prototype.addEvents = function() {
    if (false && gon.balance_info) {
      var balanceInfoElem = '<h3 class="txn-available-balance">' + gon.balance_info + '</h3>';
      $('#transactions-table_length').after(balanceInfoElem);
    }
  };

  return Tr;
})();
