import '~/scripts/integrations/jquery-extended';
import Select2 from '~/scripts/lib/Select2.js'

export default class Page {
  constructor() {
    this.addEvents();
  }

  addEvents() {
    var $initialTasks = $(Page.taskListSelector).find('.task');
    var $refTaskElem = Page.cloneInitialTask($initialTasks.first());
    Page.initializeSelect2($initialTasks);

    $(Page.addTaskSelector).click(function (e) {
      e.preventDefault();
      Page.addNewTask($refTaskElem);
    });

    $(Page.taskListSelector).on('click', '.remove-task', function () {
      $(this).parent().remove();
    });

    $("#task-list textarea.comment").each(function (index, elem) {
      Page.initTaskWYSIWYG(elem)
    });
  }

  removeEvents() {
    $(Page.addTaskSelector).off('click');
    $(Page.taskListSelector).off('click');

    // preserve task selections on page restore
    R.utils.saveSelectValuesToDom(Page.taskListSelector);

    $("#task-list textarea.comment").each(function (index, elem) {
      R.ui.destroyWYSIWYG($(elem));
    });
  }

  static addTaskSelector = '#add-task';
  static taskListSelector = '#task-list';
  static taskCount = 1;

  static initializeSelect2($taskElem) {
    new Select2(function () {
      $taskElem.find('.name').select2();
    });
  }

  static addNewTask($refTaskElem) {
    var $newTaskElem = Page.cloneTask($refTaskElem);
    $(Page.taskListSelector).append($newTaskElem);
    Page.taskCount += 1;
    Page.initializeSelect2($newTaskElem);
    Page.initTaskWYSIWYG($newTaskElem.find('textarea.comment')[0]);
    feather.replace();
  }

  static cloneTask($elem) {
    $elem = $elem.clone();
    $elem.find('input, select, textarea').each(function() {
      if (this.name) // update actual name value to be unique
        this.name = this.name.replace('[0]', '[' + Page.taskCount + ']');
      if (this.id)   // also update default id coming from rails
        this.id = this.id.replace(/^task_submission_tasks_0_/, 'task_submission_tasks_' + Page.taskCount + '_');
    });
    return $elem;
  }

  static cloneInitialTask($elem) {
    var $clonedElem = $elem.clone();
    $clonedElem.children('input, select').each(function () {
      // ids are not used currently, so avoid the duplication by removing them entirely
      this.removeAttribute('id');
      // remove selected values from any previous navigation
      $(this).val('');
    });
    $clonedElem.find('select > option').each(function () {
        $(this).attr('selected', null);
    });
    return $clonedElem;
  }

  static initTaskWYSIWYG(elem) {
    if ((gon.trumbowyg || {}).wysiwyg_editor_enabled) {
      var inputFormatOpts = {
        $elem: $(elem).siblings('input[name*=input_format]')
      };
      R.ui.initWYSIWYG($(elem), inputFormatOpts, false, false);
    }
  }
}
