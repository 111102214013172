import '~/scripts/integrations/jquery-extended';

window.R.Announcements = (function() {
  "use strict";

  var Announcements;

  Announcements = function(container) {
    this.container = container || "#announcements";
    this.$container = $(this.container);
    this.initCards(container);
    this.addEvents();
  };

  Announcements.prototype.addEvents = function() {
    if ($body.is('#announcements-index')){
      this.dateRange = new window.R.DateRange({refresh: 'turbolinks'});
    }

    if ($body.is('#recognitions-index')) {
      this.reactions = new window.R.Reactions();
      if (!R.reactionsSupported){
        $('.reactions').hide();
      }
    } else {
      this.comments = new R.Comments(null, false);
      if ($body.is("#announcements-show")) {
        this.comments.setupCommentsWysiwygForNewFormIfRequired();
      }
    }
  };

  Announcements.prototype.removeEvents = function() {};

  Announcements.prototype.initCards = function(container) {
    var opts = {};
    var layoutMode = 'fitRows';
    var skipGlobalCallbacks = false;
    this.announcementCards = new R.Cards(container, {
      layoutMode: layoutMode,
      gridView: false,
      skipGlobalCallbacks: skipGlobalCallbacks,
      skipInfiniteScroll: true
    });
  };

  return Announcements;

})();