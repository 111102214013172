import CompanyAdmin from '~/scripts/lib/CompanyAdmin';

export default (function() {
  var Comments = function() {
    this.addEvents();
  };

  Comments.prototype.addEvents = function() {
    new CompanyAdmin();
  };

  Comments.prototype.removeEvents = function() {
  };

  return Comments;
})();
