import '~/scripts/integrations/jquery-extended';
import Swal from 'sweetalert2';

(function() {
  var reviewCssClass = ".reviewRecognize";

  $document.on("turbo:load", function() {

    if ( $(reviewCssClass).length ) {
      $document.on(R.touchEvent, reviewCssClass, function() {
        Swal.fire({
          title: 'Help Recognize',
          text: "Help spread the word about Recognize! Leave a review on G2. It only takes a few minutes and it helps us a lot. Thank you!",
          imageUrl: '/assets/3p/review-us-g2.png',

          imageAlt: 'Review Us',
          showCancelButton: false,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Review Recognize'
        }).then(function (result) {
          console.log(result);
          if (result.value === true) {
            window.open("https://www.g2.com/products/recognize/take_survey");
          }
        });
      }).one("turbo:before-fetch-request", function() {
        $document.off(R.touchEvent, ".reviewRecognize");
      });
    }
  });
})();
