import { Controller } from "@hotwired/stimulus"
import Select2 from '~/scripts/lib/Select2.js'

// NOTE: This is just an initial Stimulus implementation of select2
//       The intention is to make it dead simple to make select2's
//       via annotations (data attributes). Eg, no javascript or even specific Stimulus controllers
//
//       I could see additional use cases such:
//       * redirecting to a url
//       * providing a customizable sweet alert
//       * remote loading of data
//
// NOTE: https://github.com/joargp/stimulus-select2/blob/master/src/index.js
//       this has an implementation that seems to scan for select2 data attributes
//       and pass into the select2 options, which is interesting
//       Also, there is this and other open source approaches to this, but
//       I think its better to roll our own and cherry pick what we need
//       as we need it.
export default class extends Controller {

  connect() {
    new Select2(function(){
      this.select.select2();
      if(this.select.data('autosave')) {
        this.select.on('select2:select', function() { this.submitForm() }.bind(this));
      }

      if(this.select.data('redirecttovalue')) {
        this.select.on('select2:select', function() {
          window.location = this.value
        });
      }
    }.bind(this));
  }

  disconnect() {
    this.select.select2('destroy');
  }

  submitForm() {
    this.select.parents('form')[0].requestSubmit();
  }

  get select() {
    return $(this.element);
  }

}
