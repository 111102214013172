import '../controllers';
import '../scripts/integrations/jquery-extended';
import '../scripts/integrations/turbo';
import '../scripts/integrations/sweetalert2';
import '../scripts/integrations/highcharts';
import '../scripts/integrations/balance-text';
import '../scripts/integrations/set-global-legacy-namespaces';

import '../scripts/3p/appcues/appCuesIntegrityChecker';
import '../scripts/3p/export-csv-html5';
import '../scripts/lib-legacy/confetti';
import '../scripts/lib-legacy/globalWordsCount';
import '../scripts/lib-legacy/cookies';
import '../scripts/lib-legacy/components/pie_chart_component';
import '../scripts/lib-legacy/bootstrapTurbolinksLoader';
import '../scripts/lib-legacy/dataTableFilterDelay';
import '../scripts/lib-legacy/swalHelpers';
import '../scripts/lib-legacy/utils';
import '../scripts/lib-legacy/browserDetector';
import '../scripts/lib-legacy/mobile/base';
import '../scripts/lib-legacy/Ajaxify';
import '../scripts/lib-legacy/IdpRedirecter';
import '../scripts/lib-legacy/LitaTable';
import '../scripts/lib-legacy/destroyIosCheckboxes';
import '../scripts/lib-legacy/saveSelectValuesToDom';
import '../scripts/lib-legacy/UsersDatatableUtils';
import '../scripts/lib-legacy/ScheduleDemo';
import '../scripts/lib-legacy/yammer/api';
import '../scripts/lib-legacy/ui/teams/teams';
import '../scripts/lib-legacy/ui/teams/teams-inline';
import '../scripts/lib-legacy/ui/header';
import '../scripts/lib-legacy/ui/cloneInput';
import '../scripts/lib-legacy/ui/buttons';
import '../scripts/lib-legacy/ui/barNotifications';
import '../scripts/lib-legacy/ui/passwordToggle';
import '../scripts/lib-legacy/ui/graphData';
import '../scripts/lib-legacy/ui/remoteOverlay';
import '../scripts/lib-legacy/ui/formLoading';
import '../scripts/lib-legacy/ui/BadgeList';
import '../scripts/lib-legacy/ui/RecognitionPrivacyEnforcer';
import '../scripts/lib-legacy/ui/wysiwyg';
import '../scripts/lib-legacy/ui/YammerGroupsSelect2';
import '../scripts/lib-legacy/ui/PasswordUtils';
import '../scripts/lib-legacy/ui/queuedImportProgress';
import '../scripts/lib-legacy/Cards';
import '../scripts/lib-legacy/Comments';
import '../scripts/lib-legacy/errorList';
import '../scripts/lib-legacy/CreditCardValidation';
import '../scripts/lib-legacy/Uploader';
import '../scripts/lib-legacy/gage';
import '../scripts/lib-legacy/DatePicker';
import '../scripts/lib-legacy/DateRange';
import '../scripts/lib-legacy/Post';
import '../scripts/lib-legacy/ui/PointsRemainingToGive';
import '../scripts/lib-legacy/ui/fadeAwayStars';
import '../scripts/lib-legacy/Stream';
import '../scripts/lib-legacy/QuickNominations';
import '../scripts/lib-legacy/analytics/piechart';
import '../scripts/lib-legacy/rewards/variantListener';
import '../scripts/lib-legacy/integrations';
import '../scripts/lib-legacy/BadgesRemaining';
import '../scripts/lib-legacy/policyPopup';
import '../scripts/lib-legacy/AutoSaver';
import '../scripts/lib-legacy/reviewUs';
import '../scripts/lib-legacy/GatedFeature';
import '../scripts/lib-legacy/MentionTagging';
import '../scripts/lib-legacy/affiliateTracker';
import '../scripts/lib-legacy/Reactions';
import '../scripts/lib-legacy/Announcements';
import '../scripts/lib-legacy/ui/BigLettersAnimation';
import '../scripts/lib-legacy/PointsRemaining';
import '../scripts/lib-legacy/paymentConfirmation';
import '../scripts/lib-legacy/favorite_team';
import '../scripts/lib-legacy/autocomplete/autocompleteInit';
import '../scripts/lib-legacy/autocomplete/recognizeAutocomplete';
import '../scripts/lib-legacy/autocomplete/simpleAutocomplete';
import '../scripts/lib-legacy/ui/drawer';
import '../scripts/lib-legacy/oldBrowserMessage';
import '../scripts/lib-legacy/defaultSwalConfirmation';

import loadApp from '../scripts/init';

const pages = import.meta.glob([
  '../scripts/pages/account_chooser/show.js',
  '../scripts/pages/admin/users/index.js',
  '../scripts/pages/admin/companies/show.js',
  '../scripts/pages/admin/companies/users.js',
  '../scripts/pages/admin/feature_flags/show.js',
  '../scripts/pages/admin/company_alarms/show.js',
  '../scripts/pages/admin/user_activity_migrations/new.js',
  '../scripts/pages/admin/index/engagement.js',
  '../scripts/pages/admin/index/index.js',
  '../scripts/pages/admin/index/queue.js',
  '../scripts/pages/admin/provider/balances/index.js',
  '../scripts/pages/admin/provider/catalogs/index.js',
  '../scripts/pages/admin/provider/rewards/index.js',
  '../scripts/pages/admin/provider/rewards/migrations/index.js',
  '../scripts/pages/admin/settings/index.js',
  '../scripts/pages/admin/subscriptions/edit.js',
  '../scripts/pages/admin/subscriptions/index.js',
  '../scripts/pages/admin/subscriptions/new.js',
  '../scripts/pages/admin/bulk_recognitions/show.js',
  '../scripts/pages/anniversaries/index.js',
  '../scripts/pages/announcements/index.js',
  '../scripts/pages/announcements/show.js',
  '../scripts/pages/authentications/failure.js',
  '../scripts/pages/award_generator/employee_of_year.js',
  '../scripts/pages/award_generator/service_anniversary.js',
  '../scripts/pages/badges/show.js',
  '../scripts/pages/companies/show.js',
  '../scripts/pages/company_admin/accounts/broadcasts/new.js',
  '../scripts/pages/company_admin/accounts/edit.js',
  '../scripts/pages/company_admin/accounts/overviews/show.js',
  '../scripts/pages/company_admin/accounts/show.js',
  '../scripts/pages/company_admin/accounts_spreadsheet_importers/new.js',
  '../scripts/pages/company_admin/anniversaries/calendars/show.js',
  '../scripts/pages/company_admin/anniversaries/notifications/show.js',
  '../scripts/pages/company_admin/anniversaries/settings/index.js',
  '../scripts/pages/company_admin/announcements/edit.js',
  '../scripts/pages/company_admin/announcements/index.js',
  '../scripts/pages/company_admin/announcements/new.js',
  '../scripts/pages/company_admin/announcements/notify.js',
  '../scripts/pages/company_admin/catalogs/edit.js',
  '../scripts/pages/company_admin/catalogs/index.js',
  '../scripts/pages/company_admin/catalogs/new.js',
  '../scripts/pages/company_admin/comments/index.js',
  '../scripts/pages/company_admin/custom_badges/edit.js',
  '../scripts/pages/company_admin/custom_badges/index.js',
  '../scripts/pages/company_admin/custom_field_mappings/show.js',
  '../scripts/pages/company_admin/customizations/show.js',
  '../scripts/pages/company_admin/dashboards/show.js',
  '../scripts/pages/company_admin/documents/index.js',
  '../scripts/pages/company_admin/nominations/index.js',
  '../scripts/pages/company_admin/points/allocation.js',
  '../scripts/pages/company_admin/points/overview.js',
  '../scripts/pages/company_admin/points/settings.js',
  '../scripts/pages/company_admin/recognitions/index.js',
  '../scripts/pages/company_admin/redemptions/index.js',
  '../scripts/pages/admin/rewards/redemptions/index.js',
  '../scripts/pages/company_admin/res/index.js',
  '../scripts/pages/company_admin/rewards/dashboard.js',
  '../scripts/pages/company_admin/rewards/edit.js',
  '../scripts/pages/company_admin/rewards/index.js',
  '../scripts/pages/company_admin/rewards/new.js',
  '../scripts/pages/company_admin/rewards/transactions/index.js',
  '../scripts/pages/company_admin/rewards_budgets/index.js',
  '../scripts/pages/company_admin/roles/index.js',
  '../scripts/pages/company_admin/roles/sync_settings.js',
  '../scripts/pages/company_admin/teams/index.js',
  '../scripts/pages/company_admin/teams/sync_settings.js',
  '../scripts/pages/company_admin/settings/index.js',
  '../scripts/pages/company_admin/surveys/index/settings.js',
  '../scripts/pages/company_admin/surveys/survey_periods/show.js',
  '../scripts/pages/company_admin/surveys/index/index.js',
  '../scripts/pages/company_admin/tags/index.js',
  '../scripts/pages/company_admin/top_employees/index.js',
  '../scripts/pages/company_admin/tskz/completed_tasks/index.js',
  '../scripts/pages/company_admin/tskz/tasks/edit.js',
  '../scripts/pages/company_admin/tskz/tasks/index.js',
  '../scripts/pages/company_admin/tskz/tasks/new.js',
  '../scripts/pages/company_admin/surveys/index/show.js',
  '../scripts/pages/departments/index.js',
  '../scripts/pages/hall_of_fame/index.js',
  '../scripts/pages/home/awards.js',
  '../scripts/pages/home/rewards.js',
  '../scripts/pages/identity_providers/show.js',
  '../scripts/pages/index.js',
  '../scripts/pages/manager_admin/dashboards/show.js',
  '../scripts/pages/manager_admin/recognitions/index.js',
  '../scripts/pages/manager_admin/redemptions/index.js',
  '../scripts/pages/manager_admin/res/index.js',
  '../scripts/pages/manager_admin/top_employees/index.js',
  '../scripts/pages/manager_admin/tskz/completed_tasks/index.js',
  '../scripts/pages/nominations/create.js',
  '../scripts/pages/nominations/edit.js',
  '../scripts/pages/nominations/new.js',
  '../scripts/pages/nominations/new_chromeless.js',
  '../scripts/pages/password_resets/edit.js',
  '../scripts/pages/password_resets/update.js',
  '../scripts/pages/recognitions/create.js',
  '../scripts/pages/recognitions/edit.js',
  '../scripts/pages/recognitions/grid.js',
  '../scripts/pages/recognitions/index.js',
  '../scripts/pages/recognitions/new.js',
  '../scripts/pages/recognitions/new_chromeless.js',
  '../scripts/pages/recognitions/show.js',
  '../scripts/pages/redemptions/index.js',
  '../scripts/pages/redemptions/show.js',
  '../scripts/pages/reports/index.js',
  '../scripts/pages/signups/welcome.js',
  '../scripts/pages/subscriptions/create.js',
  '../scripts/pages/subscriptions/new.js',
  '../scripts/pages/subscriptions/show.js',
  '../scripts/pages/subscriptions/update.js',
  '../scripts/pages/support_emails/new.js',
  '../scripts/pages/task_submissions/new.js',
  '../scripts/pages/task_submissions/new_chromeless.js',
  '../scripts/pages/teams/index.js',
  '../scripts/pages/teams/show.js',
  '../scripts/pages/user_sessions/create.js',
  '../scripts/pages/users/edit.js',
  '../scripts/pages/users/index.js',
  '../scripts/pages/users/invite.js',
  '../scripts/pages/users/show.js',
  '../scripts/pages/welcome/show.js',
], { eager: true });

document.addEventListener("turbo:load", function() {
  loadApp(pages);
});

// marketing now has async javascript (in production env only)
// Turbolinks load event doesn't fire with deferred scripts
// so we also need to add standard window load event.
if ($('html.marketing').length) {
  document.addEventListener("load", function() {
    loadApp(pages);
  });
}
