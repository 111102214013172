import '~/scripts/integrations/jquery-extended';

export default (function() {
  var select2FilterClass = ".user-merger-select"
  var T = function() {
    this.addEvents();
  };

  T.prototype.addEvents = function() {
    var network = gon.company_network;
    var opts = { "avatar_col": 'col-2', "name_col": 'col-10', "include_email": true, network: network, "include_disabled": true }
    window.R.utils.select2RemoteUsers($(select2FilterClass), opts)
  };

  return T;
})();
