import '~/scripts/integrations/jquery-extended';

window.R.setupKiosk = function(cards) {
  var waitOnDetailView = 7000;
  var waitOnKiosk = 4000;
  var storageKey = "currentKioskItem";

  if (window.R.setupKiosk.enabled) {return;}

  function loop() {
    var offset = 0;
    var nextItem = window.localStorage[storageKey] ? parseInt(window.localStorage[storageKey]) : 1;
    var $recognition;
    var scrollTop;

    nextItem += 1;
    $recognition = $("#stream .recognition-card").eq(nextItem);

    if ($recognition.find('.recognition-inner').length === 0) {
      nextItem += 1;
      $recognition = $("#stream .recognition-card").eq(nextItem);
    }

    if (!$recognition.length) {
      nextItem = 0;
      $recognition = $("#stream .recognition-card").eq(nextItem);
    }

    setTimeout(function() {
      $recognition.addClass("kioskSelected");

      $(cards.container).isotope("reLayout");

      setTimeout(function() {
        offset = $recognition.find('.recognition-inner').offset().top;

        $("html, body").animate({
          scrollTop: (offset - 100) +"px"
        });
      }, 500);

    }, 500);


    setTimeout(function() {
      $recognition.removeClass("kioskSelected");
      // $recognition.css('transform', 'translate('+matrix[4]+'px, '+translationY+'px)');
      loop();

    }, waitOnDetailView);

    window.localStorage[storageKey] = nextItem;
    window.R.setupKiosk.enabled = true;
  }

  setTimeout(function() {
    loop();
  }, waitOnKiosk);
};

window.R.setupKiosk.enabled = false;

/*
 * Stream.js
 * This script is used by the default stream page as well as grid page
 **/

window.R.ui.Stream = (function($, window, body, R, undefined) {
  var COLUMN_SWITCH_ELEMENT = "#stream-teams-mobile-button";
  // Padding top of stream's left and right sidebar
  // this factoring is needed to eliminate the page-wide vertical scrollbar.
  var sidebarPaddingTop = 18.4; // px

  function isDesktop() {
    return $window.outerWidth() > 768;
  }

  var Stream = function(opts) {
    opts = opts || {};
    var layoutMode = 'fitRows';

    window.R.utils.listenForWindowMessage();

    this.favoriteTeam = this.setupFavoriteTeam();

    this.gridMode = !!opts.grid;
    this.$container = $('#stream');

    // there are two different list elements for mobile and desktop
    this.$filterByLists = $('.filter-by-list');

    this.initCards({layoutMode: layoutMode});

    var isCommentsEditOnly = false;

    // GRID MODE
    //
    if (this.gridMode) {

      // Kiosk / grid mode
      layoutMode = 'masonry';

      // this is needed for Turbolinks requests because TL only replaces the <body>
      $html.addClass('fullscreen');

      if (window.location.href.indexOf("animate=false") === -1) {
        window.R.setupKiosk(this.recognitionCards);
      }
      isCommentsEditOnly = true;

      // DEFAULT STREAM MODE
      //
    } else {

      // Stream page
      // needed when navigating back from grid page
      $html.removeClass("fullscreen");

      this.buttons = new R.ui.Buttons();
      this.$teamList = $("#team-list");

      this.setAnnouncementSidebarHeight();
      if (isDesktop()) {
        this.fixStreamHeight();
        this.watchTrumbowygModalPositioning();
      }

      if (isDesktop() || $("#team-stream-nav.open").length) {
        this.fixTeamsHeight();
      }

      this.bindAnnouncementsLoad();
    }

    this.comments = new R.Comments(null, isCommentsEditOnly);
    this.addEvents();
  };

  Stream.prototype.initCards = function(opts) {
    opts = opts || {};
    var layoutMode = opts.layoutMode || 'fitRows';
    var skipGlobalCallbacks = opts.skipGlobalCallbacks || false;
    this.recognitionCards = new R.Cards("#stream", {
      layoutMode: layoutMode,
      gridView: this.gridMode,
      skipGlobalCallbacks: skipGlobalCallbacks
    });
  };

  Stream.prototype.fixStreamHeight = function() {
    var offsetY = this.$container.offset().top;
    var windowHeight = $window.height();
    var cssObj = {height: (windowHeight - offsetY)+"px"};

    this.$container.css(cssObj);
  };

  Stream.prototype.resetStreamHeight = function() {
    this.$container.css({height: '100%'});
  };

  // Mar'25 update: This was initially set for desktop only in commit 7a798d4f857d4246ccf52a7e57faf5ea58cb4991
  //                Making it universal now
  Stream.prototype.setAnnouncementSidebarHeight = function() {
    let $announcements = $("#sidebar-announcements");
    let announcementTopOffset = $announcements.length ? $announcements.offset().top : 0;
    let $pointsWidgetWrapper = $("#remainingPointToGiveSummary");
    let $anniversaryWidget = $('a.anniversaryPopup').parent().parent();
    let spaceAboveAnnouncements = 0;

    if($pointsWidgetWrapper.length){
      spaceAboveAnnouncements = $pointsWidgetWrapper.height();
    }

    if($anniversaryWidget.length) {
      spaceAboveAnnouncements +=  $anniversaryWidget.height();
    }

    let windowHeight = $window.height();
    let cssObj = { height: (windowHeight - announcementTopOffset - spaceAboveAnnouncements - sidebarPaddingTop) + "px" };
    $announcements.css(cssObj);
  }

  Stream.prototype.fixTeamsHeight = function() {
    var windowHeight = $window.height();
    var $teamList = $("#team-list");
    var offsetY = this.$container.offset().top;
    var cssObj = {height: (windowHeight - offsetY)+"px"};

    if ($teamList.length) {
      cssObj = {height: (windowHeight - $teamList.offset().top - sidebarPaddingTop)+"px"};
      $teamList.css(cssObj);
    }
  };

  Stream.prototype.addEvents = function() {
    var resizeTimer = 0;
    var waitOnResizeBeforeHeightAdjustment = 1000;

    if (this.gridMode) { return; }
    $window.on('resize.stream', function() {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function() {

        this.fixTeamsHeight();

        this.setAnnouncementSidebarHeight();
        if (isDesktop()) {
          this.fixStreamHeight();
        } else {
          this.resetStreamHeight();
        }

      }.bind(this), waitOnResizeBeforeHeightAdjustment);

    }.bind(this));

    $document.on(R.touchEvent, '#stream-teams-mobile-button', this.fixTeamsHeight.bind(this));

    // On tab change
    $document.on(R.touchEvent, COLUMN_SWITCH_ELEMENT, this.toggleDetails.bind(this));
    // When a user clicks the Team link on the left to show the recognitions for that team.
    $document.on(R.touchEvent, "#team-list .link", this.goToTeam.bind(this));
    $document.on(R.touchEvent, '.filter-by-list a', this.filterBy.bind(this));
    this.setDatePicker();
    $document.on(R.touchEvent, "#filter-upto .submit-filter-upto", this.filterUpto.bind(this));
    $document.one("turbo:before-cache", this.removeEvents.bind(this));
  };

  Stream.prototype.goToTeam = function(e) {
    var $this = $(e.target);
    var newURL = $this.prop("href");
    var selectedFilterBadges = $('.filter-by-list:visible .selected');
    var $filterUptoDateVal = $("#filter_upto").val();

    if(selectedFilterBadges.length === 1) {
      newURL = this.setBadgeFilterInUrl(selectedFilterBadges, newURL);
    }

    if ($filterUptoDateVal !== '') {
      var formattedFilterDate = window.R.utils.formatDate($filterUptoDateVal);
      newURL = this.setFilterDateInUrl(formattedFilterDate.unix(), newURL);
    }

    e.preventDefault();
    this.pushHistoryAndLoadRecognitions(newURL, $this, {team: true});

    return false;
  };

  Stream.prototype.filterBy = function(e) {
    var $this = $(e.target);
    var filterByClass = '.filter-by-list';
    var newURL = $this.prop('href');
    var teamId = $('#team-list .team-item.current .link').attr('id');
    var $desktopFilterLinks = $(filterByClass).find('a');
    var $filterUptoDateVal = $("#filter_upto").val();
    var currentBadge = $this.data('badge');
    var queryParams = '';
    var that = this;
    var selectedBadgeCount = 0;

    // Either filter list can be chosen here for checking existing state, as the state is mirrored
    if ($this.hasClass('selected') && $(filterByClass).find('.selected').length === 1) {
      // this is the only selected filter and cannot be toggled
      e.preventDefault();
      return;
    }

    $desktopFilterLinks.each(function() {
      // Previous condition of filter's badge before the current action.
      var isSelected = $(this).hasClass('selected');
      var badge = $(this).data('badge');

      if ((isSelected && currentBadge !== badge) || (!isSelected && currentBadge === badge)) {
        queryParams = that.addBadgeToParams(queryParams, badge);
        selectedBadgeCount += 1;
      }
    });

   // if (selectedBadgeCount !== 2) {
      newURL += newURL.indexOf('?') > -1 ? '&' : '?';
      newURL += queryParams;
    //}

    if(typeof(teamId) !== 'undefined') {
      newURL = this.setTeamsIdInUrl(teamId, newURL);
    }

    if ($filterUptoDateVal !== '') {
      var formattedFilterDate = window.R.utils.formatDate($filterUptoDateVal);;
      newURL = this.setFilterDateInUrl(formattedFilterDate.unix(), newURL);
    }

    e.preventDefault();
    this.pushHistoryAndLoadRecognitions(newURL, $this, {filter: true});
    return false;
  };

  Stream.prototype.setDatePicker = function() {
    new window.R.DatePicker(function(){
      $(".datepicker").datepicker({
        format: $body.data('slashdateFormat'),
        autoclose: true,
        clearBtn: true,
        endDate: "0d",
        language: $html.attr('lang'),
        rtl: $html.attr('dir') === 'rtl' || false
      });
    });
  };

  Stream.prototype.filterUpto = function(e) {
    e.preventDefault();
    var $this = $(e.target).closest('form');
    var newURL = window.location.href.split('?')[0];
    var queryParams = window.R.utils.queryParams();

    if (queryParams['locale'] !== undefined) {
      newURL += "?locale=" + queryParams['locale'];
    }

    var $filterUptoDate = $this.find('.form-control[name=filter_upto]');
    var filterUptoDateVal = $filterUptoDate.val();

    if (filterUptoDateVal !== '') {
      newURL += newURL.indexOf('?') > -1 ? '&' : '?';

      if ($filterUptoDate.is('.border, .border-danger')) {
        $filterUptoDate.removeClass('border border-danger');
      }

      var formattedFilterDate = window.R.utils.formatDate(filterUptoDateVal);
      newURL += window.R.utils.objectToParamString({'filter_upto': formattedFilterDate.unix()});
    } else {

      // don't allow empty date submission
      // unless the filter is already active, in which case it should be permitted to enable clearing the filter
      if (queryParams['filter_upto'] === undefined) {
        $filterUptoDate.addClass('border border-danger');
        e.preventDefault();
        return false;
      }
    }

    var selectedFilterBadges = $('.filter-by-list:visible .selected');
    var teamId = $('#team-list .team-item.current .link').attr('id');

    if (selectedFilterBadges.length === 1) {
      newURL = this.setBadgeFilterInUrl(selectedFilterBadges, newURL);
    }

    if (typeof (teamId) !== 'undefined') {
      newURL = this.setTeamsIdInUrl(teamId, newURL);
    }

    e.preventDefault();
    this.pushHistoryAndLoadRecognitions(newURL, $this, {filter: true});

    return false;
  };

  Stream.prototype.setBadgeFilterInUrl = function (badgeFilter, url) {
    // assumes `filter_by[]` param is never present in these team links beforehand
    var badge = badgeFilter.data('badge');
    url += url.indexOf('?') > -1 ? '&' : '?';
    url += window.R.utils.objectToParamString({'filter_by[]': badge});
    return url;
  };

  Stream.prototype.setTeamsIdInUrl = function (teamId, url) {
    teamId = teamId.replace(/team-/, '');
    url += url.indexOf('?') > -1 ? '&' : '?';
    url += 'team_id=' + teamId;
    return url;
  };

  Stream.prototype.setFilterDateInUrl = function (dateVal, url) {
    url += url.indexOf('?') > -1 ? '&' : '?';
    url += window.R.utils.objectToParamString({'filter_upto': dateVal});
    return url;
  };

  Stream.prototype.removeEvents = function() {
    // delete this.$container;
    if (this.gridMode) {
      $html.removeClass('fullscreen');
    } else {
      // close team selection when exiting
      if (!isDesktop() && this.$teamList.is(':visible')) {
        this.toggleDetails();
      }

      $window.off('resize.stream');
      $document.off(R.touchEvent, COLUMN_SWITCH_ELEMENT);
      $document.off(R.touchEvent, "#team-list .link");
      $document.off(R.touchEvent, '.filter-by-list a');
      $document.off(R.touchEvent, '#filter-upto .submit-filter-upto');
      $document.off("resize:infiniteReset");
    }

    delete this.recognitionCards;

    this.favoriteTeam.removeEvents();

    $document.off(R.touchEvent, '#stream-teams-mobile-button', this.fixTeamsHeight.bind(this));
  };

  Stream.prototype.highlightCurrentTeamLink = function($this, opts) {
    var href = window.location.href;
    var params = R.utils.queryParams();
    var currentClass = 'current';

    if(opts['team']) {
      this.$teamList.find('li.' + currentClass).removeClass(currentClass);

      if ($this.length) {
        return $this.closest("li").addClass(currentClass);
      }

      if (href.indexOf("team_id") > -1) {
        $('#team-' + params['team_id']).closest("li").addClass(currentClass);
      } else {
        this.$teamList.find("li:first").addClass(currentClass);
      }
    }
  };

  Stream.prototype.highlightCurrentFilterLink = function($this, opts) {
    var isSelected = null;

    if(opts['filter']) {
      this.$filterByLists.find('a').each(function() {
        if ($(this).data('badge') === $this.data('badge')) {
          isSelected = $(this).hasClass('selected');

          if (isSelected) {
            $(this).removeClass('selected p-1 d-block text-white bg-secondary').addClass("text-primary");
          } else {
            $(this).addClass('selected badge bg-secondary rounded-pill p-1 d-block text-uppercase text-white');
          }
        }
      });
    }
  };

  Stream.prototype.loadRecognitionPage = function($this, opts) {
    var newURL = window.location.href;

    if (this.$container.data('infiniteScroll'))
      this.$container.infiniteScroll('abortLoadNextPage');

    R.utils.showTurboProgressBar();

    this.$container.addClass('loading');
    if (this.recognitionCards) { this.recognitionCards.unload(); }

    this.$container.load(newURL + " .recognition-card", null, function(response, status, xhr) {
      R.utils.hideTurboProgressBar();
      this.$container.removeClass('loading').scrollTop(0);

      this.initCards({skipGlobalCallbacks: true});
    }.bind(this));

    if ($window.width() <= 768) {
      this.toggleDetails();
    }

    this.highlightCurrentTeamLink($this, opts);
    this.highlightCurrentFilterLink($this, opts);
    this.updateGridLink(newURL);
  };

  Stream.prototype.toggleDetails = function(e) {
    var containerWatch;

    if (e) {e.preventDefault();}

    if ($('#team-stream-nav.showing').length) {
      containerWatch = 'disableScrollWatch';
    } else {
      containerWatch = 'enableScrollWatch';
    }

    if (this.$container.data('infiniteScroll')) {this.$container.infiniteScroll(containerWatch);}
  };

  /*
   * Update grid link URL when switching team.
   * using manual regex capture / replace instead of modern URL() API in order to support IE
   * alternatively, the grid path can be passed from the server as a data attr for each team item
   **/
  Stream.prototype.updateGridLink = function(newURL) {
    var $gridEl = $('#grid-link');
    var currentGridUrl = $gridEl.attr('href');
    var newGridUrl = '';

    if (typeof(currentGridUrl) === 'undefined') {
      return;
    }

    newGridUrl = currentGridUrl.replace(/(&?filter_by%5B%5D=\w+)+/, '');
    newGridUrl = newGridUrl.replace(/&?team_id=\w+/, '');
    newGridUrl = newGridUrl.replace(/&?filter_upto=\w+/, '');

    if(newURL.indexOf('?') > -1) {
      newGridUrl += newGridUrl.indexOf('?') > -1 ? '&' : '?';
      newGridUrl += newURL.substr(newURL.indexOf('?') + 1);
    }

    $gridEl.attr('href', newGridUrl);
  };

  Stream.prototype.pushHistoryAndLoadRecognitions = function(url, $targetEl, opts) {
    R.utils.pushHistoryWithTurbo(url);
    this.loadRecognitionPage($targetEl, opts);
  };

  Stream.prototype.addBadgeToParams = function(params, badge) {
    params += params.length != 0 ? '&' : '';
    params += window.R.utils.objectToParamString({'filter_by[]' : badge});
    return params;
  };

  /**
   * Issue description
   *   The modal can sometimes overflow below viewport without being able to scroll down
   *    - This happens when it is opened for the last card at the end of pagination
   *    - Occurs on stream page only (and not any other card pages) due to the split layout
   *
   * Fix notes
   *   1. This seems to work seamlessly when tested, i.e.
   *     - The position adjustment below happens before the modal is actually shown.
   *     - And still, the exact modal height is available for this fix (otherwise a static height could be used, depending on modal type)
   *   2. This fix could be skipped when there is enough scroll height below for relevant container,
   *      but the same issue still seems to occur for GIF modal in that case, so not skipping
   */
  Stream.prototype.watchTrumbowygModalPositioning = function(){
    if (!$('#team-list').length) return; // skip unless dual column layout

    this.$container.on('tbwmodalopen', function (a, b, c) {
      var $modal = $('.trumbowyg-modal');
      if (!$modal.length) return;

      var modalHeight = $modal.height();
      var windowHeight = $window.height();
      if (parseInt($modal.css('top')) + modalHeight > windowHeight){
        $modal.css('top', windowHeight - modalHeight);
      }
    })
  };

  Stream.prototype.bindAnnouncementsLoad = function() {
    $("#sidebar-announcements").on('pageletLoaded', function() {
      var announcements = new R.Announcements("#sidebar-announcements");
    });
  };

  Stream.prototype.setupFavoriteTeam = function() {
    return new window.R.ui.FavoriteTeam();
  };
  return Stream;
})(jQuery, window, document.body, window.R);
