import '~/scripts/integrations/jquery-extended';
import Swal from 'sweetalert2';
import Select2 from '~/scripts/lib/Select2.js'
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';
import Pagelet from '~/scripts/lib/Pagelet';
import ScrollMenu from '~/scripts/lib/ScrollMenu';
import SwalForm from '~/scripts/lib/SwalForm';

export default (function() {
  var companyScope;

  function Settings() {
    this.pagelet = new Pagelet();
    companyScope = $body.data('companyScope');
    this.turnOnSelectInputs();
    this.addEvents();
    new ScrollMenu("#company-admin-settings-menu", ".scroll-item", '.content-wrapper');
    new SwalForm();

    new Select2(function(){
      $("#kiosk-teams").select2();
    });
    this.updateKiosk();
  }

  function updateCurrencySymbol() {
    var currencySymbol = $("select#currency option:selected").data("currency-symbol");
    $("#currency-prefix").html(currencySymbol);
  }


  function updateRequireRecognitionTagsToggleDisplay(data){
    if (data.show_recognition_tags){
      $("#require-recognition-tags").show();
    }
    else{
      $("#require-recognition-tags").hide();
    }
  }

  function getURL(path) {
    return '/' + companyScope + path;
  }

  Settings.prototype.turnOnSelectInputs = function() {
    var $input = $("select.old-autosave"), that = this;
    that.selectInputs = that.selectInputs || {};

    $input.focus(function() {
      var $target = $(this);

      that.selectInputs[$target.uniqueId()] = $target.val();
    });

    $input.change(function() {
      var $target = $(this), data = {};
      var value = $target.val();
      data[$target.data('setting')] = value;

      //////////////////////////////////////////////////
      //
      // FIXME: the condition to update the select setting
      //        in on all selects right now...
      //        However, there is only one select input, so this is ok
      //        for now....
      //        if another is added, we'll need to figure out a way to
      //        generalize the condition
      //
      //////////////////////////////////////////////////
      if( $target.prop('id') !== 'reset-interval' ) {
        that.updateSettings(data);
      } else {
        var msg = "Changing the reset interval will reset all the point totals relative to the selected interval. This may take a few minutes to process.  Are you sure?";
        Swal.fire({
          title: 'Are you sure?',
          text: msg,
          icon: 'warning',
          showCancelButton: true
        }).then(function(result){
          if (result.value){
            Swal.fire(
              'Successfully updated.',
              'Please allow a few minutes for changes to go through the system.',
              'success'
            );
            that.updateSettings(data);
          } else {
            $target.val(that.selectInputs[$target.uniqueId()]);
          }
        });
      }
    });
  };

  Settings.prototype.updateSettings = function(data) {
    var isResetInterval = "reset_interval" in data;
    var isChangeCurrency = "currency" in data;
    var isShowRecognitionTags = "show_recognition_tags" in data;
    $.ajax({
      url: getURL('/company/update_settings'),
      type: "POST",
      data: { settings: data },
      success: function() {
        if (isResetInterval) {
          // no op
          // this used to update the header when it had interval points.
        }
        else if (isChangeCurrency) {
          updateCurrencySymbol();
        }
        else if(isShowRecognitionTags) {
          updateRequireRecognitionTagsToggleDisplay(data)
        }
      },
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
    });
  };

  Settings.prototype.addEvents = function() {
    this.companyAdmin = new CompanyAdmin();
    this.bindDefaultYammerGroupIdToPostSelect2();
    this.bindDefaultYammerGroupIdToPostContainerToggle();
    this.bindSamlSsoToggle();

    // Disabled until workplace gives us a way to post automatically.
    //
    // this.bindFacebookWorkplaceForm();
    // new Select2(function(){this.getFbWorkplaceGroups(true);}.bind(this));

    this.bindAutoSaveSettings();
    this.bindProfileBadgeSelect2();
    this.bindCurrencySelect2();
    this.bindTimezoneSelect2();
    this.bindCancelAccount();
    this.bindWebhookEvents();
    this.bindYammerImpersonationForm();
    this.bindYammerImpersonationSelect();

    this.$pointValues = $("#point-values");

    $window.bind("ajaxify:success:kioskUrlUpdated", function(evt, data) {
      this.updateKiosk(data);
    }.bind(this));

    this.$pointValues.bind("ajax:complete", function() {
      return successFeedback("Values Updated", this.$pointValues);
    }.bind(this));
  };

  // This was forgotten to be brought over from company.js when settings was made its own page
  // so just jamming in here for now
  function successFeedback(message, element) {
    var $existingButtons = element.find(".success-mention");
    $existingButtons.remove();
    var $successButton = $('<p class="success-mention success-text">' + message + '</p>');
    element.append($successButton);
    $successButton.fadeOut(4000);
  }

  function copyKioskButtonInit() {
    R.utils.bindCopyText("#KioskURL", "#kiosk .copyButton");
  }

  Settings.prototype.updateKiosk = function(data) {
    const $kioskURLWrapper = $("#kiosk-url-wrapper");
    const content = $('input[name="kiosk-content"]:checked').val();
    const team = $("#kiosk-teams").val();
    var html;
    var url;
    var kioskURLPartial;
    const $keyInput = $("#company_kiosk_mode_key");
    var fromAjax = false;

    if (data) {
      kioskURLPartial = data.data.params.kiosk_url_partial;
      fromAjax = true;
    } else {
      kioskURLPartial = $("#KioskURL").text();
    }

    if ($keyInput.val().length === 0 && (data && data.data && data.data.params && data.data.params.key === '')) {
      return $kioskURLWrapper.html('<p class="text-secondary">No Kiosk URL set</p>\n');
    }

    if (fromAjax) {
      $kioskURLWrapper.html(kioskURLPartial);
    }

    copyKioskButtonInit();

    url = $("#KioskURL").text();

    if (content !== 'all') {
      url += '&filter_by%5B%5D='+content;
    } else {
      url += '&filter_by%5B%5D=recognition&filter_by%5B%5D=anniversary';
    }

    url += '&animate='+$("#kiosk-scroll").is(":checked");

    if (team.length > 0) {
      url += '&team_id='+team;
    }

    $("#KioskURL").text(`${url}`);
    $kioskURLWrapper.closest("form").find(".error").remove();
  };

  Settings.prototype.bindCancelAccount = function(){
    var $container = $("#cancel-account input[type=submit]");
    $container.on(R.touchEvent, function(){
      Swal.fire({
        title: "Are you sure?",
        text: "This will cancel your account and log you out.",
        icon: "warning",
        showCancelButton: true,
        customClass: "destroy-confirm-button",
        confirmButtonText: "Yes, cancel it!"
      }).then(function(result) {
        if (result.value) {
          $("#cancel-account").submit();
        }
      });
      return false;
    });
  };

  Settings.prototype.bindWebhookEvents = function() {

    function getSelectedValFromEventList() {
      return $(".swal2-html-container .recent_events").val();
    }

    function doTestWebhookAction(evt) {
      var $target = $(evt.target);
      var objectGid = getSelectedValFromEventList();
      var url = $target.data('endpoint');
      var $webhookForm = $target.parents('.swal2-html-container form');
      var urlWithObjectId = R.utils.addParamsToUrlString(url, { object_gid: objectGid})
      // $(".swal2-html-container .output").html(urlWithObjectId); // for testing
      $(".swal2-html-container .output").css({opacity: 0.5})

      var $disabledFieldset = $webhookForm.find("fieldset.webhook-disabled-wrapper");
      $disabledFieldset.prop('disabled', null);

      $.post({
        url: urlWithObjectId,
        data: $webhookForm.serialize()
      }).then(function(){
        $(".swal2-html-container .output").css({ opacity: 1 })
        $disabledFieldset.prop('disabled', 'disabled');
      })
      evt.preventDefault();
      return false
    }

    // see test payload
    $document.on(R.touchEvent, ".seePayloadLink", doTestWebhookAction);

    // send test event
    $document.on(R.touchEvent, ".testWebhookLink", doTestWebhookAction);
  }

  Settings.prototype.bindYammerImpersonationForm = function() {
    $document.on('ajax:complete',  "#check-yammer-impersonation", function(e, xhr, status) {
      var $form = $(e.target);
      var responseJSON = xhr.responseJSON;
      if(responseJSON.success == true) {
        var opts = {title: "Yammer impersonation is active", text: "We can post to Yammer on behalf of this user."}
      } else {
        var opts = {title: "Yammer impersonation is NOT active for this user", text: responseJSON.error}
      }
      Swal.fire(opts);
    });
  }

  Settings.prototype.bindYammerImpersonationSelect = function () {
    var $yammerImpersonatorSelect = $("#company_setting_yammer_impersonator_user_id");
    window.R.utils.select2RemoteUsers($yammerImpersonatorSelect)

  }

  Settings.prototype.removeEvents = function() {
    // Needed here because settings are saved instantly on change so the updated select values should be shown on restore
    R.utils.saveSelectValuesToDom('#company_admin_settings-index .content-wrapper');

    // This allows the pagelet (& thus the sync groups select2) to initialize again on page restore
    $(".company_setting_sync_groups_container").removeClass('pagelet-loaded');
    $document.off("change", "select#company_post_to_yammer_group_id");
    $document.off("click", "#allow-posting-to-yammer-wall.form-check.form-switch");
    $(".form-check-input").unbind();
  };

  Settings.prototype.bindAutoSaveSettings = function() {
    this.companyAdmin.autosaveSetting(".autosave-setting-form input, .autosave-setting-form select, .autosave-setting-form textarea");
  };

  Settings.prototype.bindProfileBadgeSelect2 = function() {
    new Select2(function(){
      $("#company_setting_profile_badge_ids").select2();
    });
  };

  Settings.prototype.bindTimezoneSelect2 = function() {
    new Select2(function(){
      $("#company_setting_timezone").select2();
    });
  };

  Settings.prototype.bindCurrencySelect2 = function() {
    new Select2(function(){
      $("#currency").select2();
    });
  };

  Settings.prototype.getFbWorkplaceGroups = function(firstLoad){
    var that = this;
    var $select = $("#company_setting_fb_workplace_post_to_group_id");
    this.selectedFbWorkplaceGroupId = $select.data('selectedgroupid');

    $.get($select.data('groupsendpoint'), function(data){
      $select.html($('<option />')); // need this for placeholder

      //normalize and sort if an array
      if(data.length) {
        data = $.map(data, function (obj) {
          obj.text = obj.name; // normalize name attribute to select2's required text
          return obj;
        });

        data = data.sort(function(a, b) {
          var nameA = a.text.toUpperCase(); // ignore upper and lowercase
          var nameB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      }

      $select.select2({
        placeholder: 'Please select a group',
        allowClear: true,
        data: data,
      });

      // .select2('val', that.selectedFbWorkplaceGroupId);
      $select.val(that.selectedFbWorkplaceGroupId).trigger('change.select2');

      if(!firstLoad) {
        $select.select2('open');
      }

    });
  };

  Settings.prototype.bindFacebookWorkplaceForm = function(){
    var that = this;
    var done = function(){
      that.getFbWorkplaceGroups();
    };

    this.companyAdmin.autosaveSetting('#company_setting_fb_workplace_community_id', done);
    this.companyAdmin.autosaveSetting('#company_setting_fb_workplace_token', done);
    this.companyAdmin.autosaveSetting('#company_setting_fb_workplace_post_to_group_id', null, null, null, function(){
      console.log(that.selectedFbWorkplaceGroupId);
    });
  };

  Settings.prototype.bindSamlSsoToggle = function() {
    $document.on('keyup', '#saml_configuration_metadata_url', function(){
      var $el = $(this);
      var disabled;

      if($el.val() === '') {
        disabled = false;
      } else {
        disabled = true;
      }
      $("#saml-custom-fieldset input, #saml-custom-fieldset textarea").prop('disabled', disabled);
      console.log($el.val());
    });
    var event = jQuery.Event('keyup');
    event.target = $document.find('#saml_configuration_metadata_url')[0];
    $document.trigger(event);
  };

  Settings.prototype.bindDefaultYammerGroupIdToPostContainerToggle = function() {
    var $allowPostingToYammerWallCheckboxWrapper = $("#allow-posting-to-yammer-wall.form-check.form-switch");
    var $allowPostingToYammerWallCheckbox = $allowPostingToYammerWallCheckboxWrapper.find(".form-check-input:input[type=checkbox]");
    var $companyPostToYammerGroupIdWrapper = $(".company_post_to_yammer_group_id_wrapper");

    if($allowPostingToYammerWallCheckbox.prop("checked") === true){
      R.YammerGroupsSelect2.initialize();
    }

    $allowPostingToYammerWallCheckboxWrapper.on('click', function(){
      if($allowPostingToYammerWallCheckbox.prop("checked") === true){
        $companyPostToYammerGroupIdWrapper.show();
        R.YammerGroupsSelect2.initialize();
      } else{
        $companyPostToYammerGroupIdWrapper.hide();
      }
    });
  };

  Settings.prototype.bindDefaultYammerGroupIdToPostSelect2 = function() {
    var $groupSelect = $('select#company_post_to_yammer_group_id');

    $groupSelect.on("select2:select", function(){
      var network = $body.data("name");
      var endpoint= "/" + network + "/company/update_settings";

      var $select = $(this);
      var selectedGroupId = $select.val();
      var payload = { };
      payload[$select.attr("name")] = selectedGroupId;

      $.ajax({
        url: endpoint,
        data: payload,
        method: "POST"
      });
    });
  };

  return Settings;
})();
