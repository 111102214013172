import '~/scripts/integrations/jquery-extended';

(function(){
  window.R.components = window.R.components || {};

  function loadPieCharts() {
    var $pieChartEls = $(document).find(".pie-chart-wrapper")
    $pieChartEls.each(function(){
      var $this = $(this);
      var pieChart = $this.data('piechart');

      R.analytics.piechart(pieChart);
    });

  }

  window.R.components.pieChart = loadPieCharts;
})()
