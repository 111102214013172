import '~/scripts/integrations/jquery-extended';
import Select2 from '~/scripts/lib/Select2.js'

export default (function() {
  var T = function() {
    this.addEvents();
  };

  T.prototype.addEvents = function() {
    this.bindFeatureAvailabilityRadioButtons();
    this.bindCompanySelect2();
    this.bindTableFilters();
    this.bindSettingChange();
  };

  T.prototype.bindFeatureAvailabilityRadioButtons = function() {
    $('input[type=radio][class^=btn-check]').change(function() {
      var $relatedSelect = $(this).closest('tr').find('.company-select-wrapper');
      if ($(this).hasClass("partial-availability")) {
        $relatedSelect.removeClass('invisible');
      } else {
        $relatedSelect.addClass('invisible');
        $relatedSelect.find("select").val(null).select2();
      }
    })
  };

  T.prototype.bindCompanySelect2 = function() {
    new Select2(function () {
      $(".select2").select2();
    });
  };

  T.prototype.bindTableFilters = function() {
    $("#featureFlagSearchInput").on("keyup", function() {
      var value = $(this).val().toLowerCase();
      $("#featureFlagTable tr").not(":first").filter(function() {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
      });
    });
  };

  T.prototype.bindSettingChange = function() {
    $('.featureFlagForm input[type=radio], .featureFlagForm select').change(function() {
      var $relatedForm = $(this).closest('form');
      $relatedForm.submit();
    });

    $window.on("ajaxify:complete", function(){
      // show some sign the form was saved
    });
  };

  return T;
})();
