import '~/scripts/integrations/jquery-extended';
import Select2 from '~/scripts/lib/Select2.js'

export default (function() {
  var Re = function() {
    var tableId = '#provider_rewards_migrations-table';
    this.$table = $(tableId).DataTable();

    $(tableId).on('draw.dt', function(){
      this.addEvents();
    }.bind(this));
  };

  Re.prototype.addEvents = function() {
    new Select2(function () {
      $('.replacement-variants-select').each(function() {
        var deprecatedVariantId = $(this).data("id");

        $(this).select2({
          placeholder: "Select replacement reward variant",
          ajax: {
            dataType: 'json',
            delay: 250,
            data: function (params) {
              return {
                term: params.term,
                id: deprecatedVariantId
              };
            },
            processResults: function (data) {
              return {
                results: data.variants
              };
            },
            cache: true
          },
          minimumInputLength: 3
        }).on('select2:select', function(event) {
          $.ajax({
            url: event.params.data.url,
            dataType: 'json',
            delay: 250,
            type: 'PUT',
            data: { reward_variant: { replacement_provider_reward_variant_id: event.params.data.id }},
            success: function(msg) {
              console.log( "Data Saved: " + msg );
            },
            error: function(xhr, data, response) {
              alert(xhr.responseJSON.variants[0]);
              $(event.target).empty().trigger('change');
            }
          });
        });
      });
    });
  };

  return Re;

})();
