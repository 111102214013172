import ApplicationChartController from "./application_chart_controller";

export default class DonutChartController extends ApplicationChartController {
  // NOTE: why is this an empty class?
  // 1. It's more declarative/explicit in the view to choose which controller
  // 2. I had originally implemented this with extra javascript code to implement
  //    the centered title of the donut,
  //    but I was able to figure out another way.
  // 3. We may need overrides in the future if we use donut's more often and so
  //    we won't need to go back to the views to update the controller
}
