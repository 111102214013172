import '~/scripts/integrations/jquery-extended';

// This is a Recognize managed file (even though its in 3p)
// whereas appcues/bootstrap.js is the AppCues SDK loader
// that we self host and inject this function call for security
// to make sure we're only loading approved versions
window.R = window.R || {};
window.R.appCuesIntegrityChecker = {
  verify: verify,
  adjustToVerifiedVersion: adjustToVerifiedVersion,
  debugHtml: debugHtml
}

function adjustToVerifiedVersion(appCuesSettings) {
  // store this on this object for later debugging
  window.R.appCuesIntegrityChecker.appCuesSettings = appCuesSettings;

  //always return verified cached version
  return gon.appCues;
}

function verify(appCuesSettings) {
  // store this on this object for later debugging
  window.R.appCuesIntegrityChecker.appCuesSettings = appCuesSettings;

  var integrityHash = gon && gon.appCues && gon.appCues.generic_bundle_integrity;

  // exit if there is no integrity hash or it doesn't equal what the server expects
  if (!integrityHash || integrityHash != appCuesSettings.generic_bundle_integrity) {
    window.R.appCuesIntegrityChecker.versionMismatch = true
    console.error("Exiting AppCues loader. Unexpected integrity hash");
    console.warn("Expected: " + integrityHash + "(" + gon.appCues.version + ")");
    console.warn("AppCues has hash: " + appCuesSettings.generic_bundle_integrity);
    console.log(appCuesSettings);

    var $siteAdminNoticesWrapper = $("#site-admin-notices");
    if ($siteAdminNoticesWrapper.length) {
      var msg = "There is an Appcues version mismatch! Visit site settings and validate the latest version of AppCues.";
      $siteAdminNoticesWrapper.append('<li class=\'warning\'>'+msg+'</li>');
      $siteAdminNoticesWrapper.removeClass('displayNone');
    }

    throw 'Canceling AppCues loading - Unexpected integrity hash';
  }
};

function debugHtml() {
  var debugHtml = "";
  var debugKeys = ['generic_bundle_integrity', 'release_id', 'version'];
  var recognizeAppCuesSettings = JSON.stringify(gon.appCues, debugKeys);
  var appCuesApiSettings = JSON.stringify(window.R.appCuesIntegrityChecker.appCuesSettings, debugKeys);

  debugHtml += "<div style='text-align:left'>";
  debugHtml += "<h3>Expected AppCues Version (Recognize cache)</h3><pre>" + recognizeAppCuesSettings + "</pre>";
  debugHtml += "<h3>Actual Appcues Version (AppCues API)</h3><pre>" + appCuesApiSettings + "</pre>";
  debugHtml += "</div>";
  return debugHtml;
}
