import '~/scripts/integrations/jquery-extended';
import Select2 from '~/scripts/lib/Select2.js'
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';

export default (function() {
  var Task = function() {
    this.addEvents();
  };

  var rolesPlaceholder = "Roles";

  Task.prototype.addEvents = function() {
    new CompanyAdmin();
    this.bindRolesSelect();
    this.bindTagSelect();
  };

  Task.prototype.removeEvents = function() {
    // preserve tag/role selections on page restore
    R.utils.saveSelectValuesToDom('#tskz_new_tskz_task');
  };

  Task.prototype.bindRolesSelect = function() {
    new Select2(function () {
      $('.company-role-select').select2(R.utils.companyRoleSelect2TemplateOpts({
        tokenSeparators: [',', ' '],
        placeholder: window.R.utils.isIE() !== false && window.R.utils.isIE() < 12 ? '' : rolesPlaceholder,
      }));
    });
  };

  Task.prototype.bindTagSelect = function() {
    new Select2(function () {
      var $tagSelect = $('#tskz_tskz_task_tag_name');
      var tagPlaceholder = $tagSelect.attr('placeholder');
      $tagSelect.select2({
        placeholder: window.R.utils.isIE() !== false && window.R.utils.isIE() < 12 ? '' : tagPlaceholder
      });
    });
  };
  return Task;
})();
