import Select2 from '~/scripts/lib/Select2.js'
import FormErrors from '~/scripts/lib/FormErrors.js'

import * as C from "~/scripts/constants.js";

export default class SwalForm {
  constructor(inlineSwalForms = true) {
    if (inlineSwalForms) {
      this.addEventsForInlineSwalForm();
    }
  }

  addEventsForInlineSwalForm() {
    $document.on(C.touchEvent, '.swal-form-link', (evt) => {
      var eventTarget = evt.target;
      var $swalLink = eventTarget.tagName === 'A' ? $(eventTarget) : $(eventTarget).parents('a');
      var swalFormId = $swalLink.data('swalform-id');
      var $swalForm = $("#" + swalFormId);
      this.showSwalForm($swalForm);
    });
  }

  removeEvents() {
    $document.off('click', '.swal-form-link');
  }

  showSwalForm($swalForm) {
    var swalOpts = $swalForm.data('swalform');
    var successMessage = swalOpts.successmessage || 'Changes have been saved';
    var generalErrorMessage = swalOpts.generalerrormessage || 'The form could not be saved';
    var reshowForm = swalOpts.reshowform || false;
    var jsonResponse = swalOpts.jsonresponse || false;

    var swalConfig = {
      title: swalOpts.title || null,
      html: $swalForm[0].outerHTML,
      focusConfirm: swalOpts.focusconfirm || true,
      confirmButtonText: swalOpts.confirmbuttontext || 'Save',
      showCancelButton: swalOpts.showcancelbutton || true,
      showConfirmButton: swalOpts.showconfirmbutton ?? true,
      cancelButtonText: swalOpts.cancelbuttontext || 'Cancel',
      reverseButtons: swalOpts.reversebuttons || false,
      showLoaderOnConfirm: swalOpts.showloaderonconfirm || true,
      didOpen: () => {
        new Select2(() => { $(".swal2-container select.select2").select2(); });
        $(".swal2-container").find(".form-check").find("sup").tooltip();
        $(".swal2-container select.select2-remote").each(function() {
          var $el = $(this);
          var endpoint = $el.data('endpoint');
          $.ajax({
            url: endpoint,
            dataType: 'json'
          }).then(function(data){
            $el.select2({ data: data.results });
          });
        });
      },
      preConfirm: () => {
        return new Promise((resolve, reject) => {
          var $formInDom = $(Swal.getHtmlContainer()).find("form")
          // ensure the form is remote, so that the submission in the next step happens via ajax
          $formInDom.attr('data-remote', true);
          $formInDom.submit();

          $formInDom.on('ajax:success', (evt, data, status, xhr) => {
            if (jsonResponse) {
              resolve(data);
            } else {
              Swal.close();
              Swal.fire('Success', successMessage || 'Changes have been saved', 'success').then(() => {
                if(reshowForm) {
                  this.showSwalForm($swalForm);
                }
              });
            }
          });

          // CHATGPT: this method signature and reject line
          //          is from chatGPT
          $formInDom.on('ajax:error', (evt, xhr, status, error) => {
            reject(xhr.responseText || error || "An error occurred");
          });

        }).catch((err) => {
          try {
            var responseErrors = JSON.parse(err.responseText)['errors'];
            var errors = new FormErrors($(".swal2-html-container form"), responseErrors);
            errors.renderErrors();
            Swal.showValidationMessage(generalErrorMessage);
          } catch (e) {
            console.log(e);
            Swal.showValidationMessage('We\'re sorry. ' + generalErrorMessage + '<br />Please try again. If this continues, please contact support@recognizeapp.com.');
          }
        });
      }
    };

    // This covers 3 cases for icon here
    // 1. skip_icon: true where we hide icons from the swal
    // 2. swalOpts.icon: present where we custom pass the icon value
    // 3. swalOpts.icon: absent where we use default icon which is 'info'
    if (!swalOpts.skip_icon){
      swalConfig['icon'] = swalOpts.icon || "info"
    }

    Swal.fire(swalConfig)
  }
}
