import '~/scripts/integrations/jquery-extended';
import Swal from 'sweetalert2';
import Select2 from '~/scripts/lib/Select2.js'

window.R.QuickNominations = function() {
  function formatItem(item) {
    if (!item.id) { return item.text; }
    var $item = item.text;
    return $item;
  }

  var statusFilter = window.R.utils.queryParams().status;
  var disableQuickNoms = statusFilter == "denied";

  new Select2(function(){
    $("select.quick-nomination").select2({
      templateResult: formatItem,
      minimumResultsForSearch: Infinity,
      disabled: disableQuickNoms
    });
  });

  function clearQuickNominationSelection(evt) {
    var $el = $(evt.target);
    var vals = $el.select2().val();
    vals.splice(vals.indexOf(evt.params.data.id));
    $el.select2().val(vals);
    $el.select2().trigger('change');
  }

  function sendQuickNomination(evt) {
    var $target = $(evt.target);
    var url = $target.data('endPoint');
    var badgeId = evt.params.data.id;
    var recipientId = $target.data('recipientId');
    var recognitionId = $target.closest("tr").prop('id').split("-")[0]; // rows have id <slug>-<recipientid>

    $.ajax({
      url: url,
      type: 'post',
      dataType: 'json',
      data: {
        nomination_submission: {
          is_quick_nomination: true,
          nomination: {
            badge_id: badgeId
          },
          recipients: ["User:"+recipientId],
          recognition_slug: recognitionId
        }
      },
      error: function(xhr){
        clearQuickNominationSelection(this);

        Swal.fire({
          icon: 'warning',
          html: window.R.forms.errorList(xhr.responseJSON.errors)
        });
      }.bind(evt)
    });
  }

  $("select.quick-nomination").on('select2:select', function(evt) {
    Swal.fire({
      icon: 'info',
      text: "Are you sure you want to nominate this user for this award?",
      showCancelButton: true
    }).then( function(result) {
        if (result.value) {
          sendQuickNomination(this);
        } else {
          clearQuickNominationSelection(this);
        }
      }.bind(evt)
    );
  });
};
