import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  disconnect() {
    this.showSearchPromptIfEmpty();
  }

  showSearchPromptIfEmpty() {
    if ($("#landing-page-categories-container")?.children().length < 1) {
      $(".empty-landing-page-message").fadeIn("slow");
    }
  }
}
