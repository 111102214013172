import '~/scripts/integrations/jquery-extended';
import Swal from 'sweetalert2';
import Select2 from '~/scripts/lib/Select2.js'
import HandlebarsTemplates from '~/scripts/integrations/handlebars-templates';
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';
import FormErrors from '~/scripts/lib/FormErrors';

export default (function() {
  function formatUser(user) {
    if (user.loading) {
      return "Please wait";
    }

    if (user.avatar_thumb_url === R.defaultAvatarPath) {
      user.avatar_thumb_url = "/assets/" + R.defaultAvatarPath;
    }

    var template = HandlebarsTemplates['user/userAvatar'];
    return template({
      "avatar_url": user.avatar_thumb_url,
      "name": user.label
    });
  }

  function formatUserSelection(user) {
    return user.label || user.text;
  }

  var Accounts = function() {
    this.accountsDataTable = null;
    this.dynamicUserTeamCreator = new DynamicUserTeamCreator();
    this.usersDatatableUtils = new R.UsersDatatableUtils();
    this.addEvents();
  };

  Accounts.prototype.addEvents = function() {
    var companyAdmin = new CompanyAdmin();

    this.bindAccountsPageEvents();

  };

  Accounts.prototype.bindAccountsPageEvents = function() {
    this.bindAccountsPagelet();
    this.bindShowForgottenPasswordLink();
    this.bindResetPasswordLink();
  };

  Accounts.prototype.addTableEvents = function() {
    this.usersDatatableUtils.bindRolesSelect();
    this.usersDatatableUtils.bindDelegatesSelect();
    this.bindTeamsSelect();
    this.bindManagerAutocomplete();
    this.bindManagerSelect();
    $('sup[title]').tooltip();
  };

  Accounts.prototype.removeEvents = function() {
    $document.off('click', 'a#edit-accounts');
    $document.off('click', '#show-forgotten-password-link');
    $document.off('click', '#template-tags .badge');
    $document.off('click', '#sms-template-tags .badge');

    $(".manager-select").off();
    $("#user-set").off('column-visibility.dt');
    $('#response-feedback-wrapper').empty();

  };


  Accounts.prototype.bindAccountsPagelet = function() {
    var that = this;

    that.accountsDataTable = R.LitaTables['accounts'].dataTable;

    R.LitaTables['accounts'].$table.on( 'draw.dt column-visibility.dt', function () {
      that.addTableEvents();
    });

    new Select2(function () {
      $(".litatable-filters .select2").select2();
    });
  };

  Accounts.prototype.bindLeaveEditAccountsBtn = function() {
    var that = this;
    $document.on('click', "#leave-edit-accounts", function(e) {
      e.preventDefault();
      var url = $(e.target).attr('href');

      window.recognize.patterns.formLoading.setNonAjaxButton(e);

      Turbo.visit(url);

      return false;
    });

  };

  function DynamicUserTeamCreator(){

    this.createUserTeam = function(endpoint, ajaxData, $selectElement, isNewTeam) {
      var $triggererSelect = $selectElement;

      var that = this;

      $.ajax({
        url: endpoint,
        data: ajaxData,
        method: "post"
      }).done(function(data) {
        that._handleSuccessfulUserTeamCreation($triggererSelect, isNewTeam, data.team);
      }).fail(function(data) {
        that._showError(data);
      }).always(function(data){
        that._removeTag($triggererSelect);
      });
    };

    this.createNewTeamTag = function(params) {
      var term = $.trim(params.term);

      if (term === '') {
        return null;
      }

      return {
        id: term,
        name: term,
        text: term,
        newTag: true
      };
    };

    this.formatTeamTag = function(team) {
      if(team.newTag) {
        var createNewTeamHint = "Create new team: ";
        if (team.text.indexOf(createNewTeamHint) === -1) {
          team.text = createNewTeamHint + team.text;
        }
      }

      return $("<span />").attr('class', 'team-' + team.id).text(team.label || team.text || team.name);

    };

    this._showError = function(data){
      var errorMessage = 'Sorry, something went wrong. Please try again later.';
      if (data.status === 422 && data.responseJSON) {
        var errors = data.responseJSON.errors;
        errorMessage = new FormErrors(null, errors).getErrorMessagesFromErrorsObject().join("<br>");
      }
      Swal.fire('Team creation failed!', errorMessage, 'error');
    };

    this._removeTag = function($triggererSelect) {
      // Remove the user entered new option regardless of whether the ajax succceed or failed. Its obvious that when its
      // a fail'ed case, the option must be removed. And, for success, since we insert a new option for team that is
      // returned from the server after creation in the concerned select, we remove the user entered one.
      $triggererSelect.find("option[data-select2-tag=true]").remove();
      $triggererSelect.trigger('change');
    };

    this._handleSuccessfulUserTeamCreation = function($triggererSelect, isNewTeam, team){
      if (isNewTeam){
        var teamOptionSelected = new Option(team.label, team.id, false, true);

        $triggererSelect.append(teamOptionSelected).trigger('change');
      }
    };
  }

  Accounts.prototype.bindTeamsSelect = function() {
    new Select2(function() {
      this.initializeTeamsSelect2WithFnArgs();

      var that = this;

      if (!this.bindTeamsSelect.loadedEvents) {
        $document.on('select2:select', '.user-team-select', function(event) {
          var data = { team_id: event.params.data.id };
          var isNewTeam = event.params.data.newTag === true;
          if(isNewTeam) {
            // For newTags, select2 relies on the new option's `id` to be present, which it sets to be the inputted term.
            // However, at this point in execution, we don't need it, therefore it is null-ified to prepare request data.
            // For more, see:  https://select2.org/tagging#tag-properties
            data.team_id = null;
            data.team_name = event.params.data.name;
          }
          that.dynamicUserTeamCreator.createUserTeam($(this).data("url"), data,  $(this), isNewTeam);
        });

        $document.on('select2:unselect', '.user-team-select', function(event) {
          $.ajax({
            url: $(this).data("url"),
            data: { team_id: event.params.data.id, _method: "delete" },
            type: "post"
          });
        });

        this.bindTeamsSelect.loadedEvents = true;
      }
    }.bind(this));
  };

  Accounts.prototype.bindManagerAutocomplete = function($target) {
    new Select2(function () {
      var url = "/coworkers";
      var dept = window.R.utils.queryParams().dept;
      if (dept) {
        url = url + "?dept=" + dept;
      }

      if ($target === undefined) {
        $target = $(".manager-select");
      }

      $target.filter(':not(.select2-hidden-accessible)').select2({
        ajax: {
          url: url,
          dataType: 'json',
          delay: 250,
          data: function (params) {
            return {
              term: params.term, // search term
              page: params.page,
              include_self: true
            };
          },
          processResults: function (data, page) {
            return {
              results: data
            };
          },
          cache: true
        },
        escapeMarkup: function (markup) {
          return markup;
        },
        allowClear: true,
        minimumInputLength: 1,
        placeholder: '',
        templateResult: formatUser,
        templateSelection: formatUserSelection
      });
    });

  };
  Accounts.prototype.bindManagerSelect = function() {
    if (!Accounts.prototype.bindManagerSelect.loadedEvents) {
      $document.on('select2:select', '.manager-select', function (e) {
        $.ajax({
          url: $(this).data("url"),
          data: { manager_id: e.params.data.id, user_id: $(this).data("user") },
          method: "post"
        });
      });
      $document.on('select2:unselect', '.manager-select', function(event) {
        $.ajax({
          url: $(this).data("url"),
          type: "post"
        });
      });
      Accounts.prototype.bindManagerSelect.loadedEvents = true;
    }
  };

  Accounts.prototype.initializeTeamsSelect2WithFnArgs = function ($target) {
    this.usersDatatableUtils.initializeTeamsSelect2($target,
      this.dynamicUserTeamCreator.formatTeamTag, this.dynamicUserTeamCreator.createNewTeamTag);
  }

  Accounts.prototype.bindShowForgottenPasswordLink = function() {
    $document.on('click', '#show-forgotten-password-link', function(event){
      event.preventDefault();

      var target = $(event.target);
      var network = target.data('network');
      var user_id = target.data('user-id');
      var user_name = target.data('user-name');
      var passwordResetTimeout = target.data('pw-reset-timeout');
      var signupLinkTimeout = target.data('signup-timeout');
      var hasCryptedPassword = target.data('has-crypted-password');

      $.ajax({
        method: 'GET',
        url: '/' + network + '/company/accounts/user_password_reset_link',
        data: {
          user: {
            id: user_id
          }
        },
        success: function (response) {
          Swal.fire({
            title: ( hasCryptedPassword ?  'Reset Password Link for ' : 'Link to set password for ') + user_name,
            html: '<pre>' + response['password_reset_url'] + `</pre><br><p class='text-secondary small'>The link expires in ${ hasCryptedPassword ? passwordResetTimeout : signupLinkTimeout }.</p>`
          });
        }
      });
    });
  };

  Accounts.prototype.bindResetPasswordLink = function() {
    $document.on('click', '#reset-password-link', function(event){
      event.preventDefault();

      var target = $(event.target);
      var user_name = target.data('user-name');
      var user_id = target.data('user-id');
      var network = target.data('network');

      var form = '<label style="text-align:left;">New Password </label><input type="password" id="new-password" name="new-password" style="width:100%;" />';

      Swal.fire({
        title: 'Reset password for ' + user_name,
        html: form,
        showCancelButton: true,
        confirmButtonText: "Reset Password",
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            var new_password = $('[name=new-password]').val();

            $.ajax({
              method: 'PATCH',
              url: '/' + network + '/company/accounts/update_user_password',
              data: {
                user: {
                  id: user_id,
                  password: new_password
                }
              },
              success: function () {
                resolve();
              },
              error: function (err) {
                reject(err);
              }
            });
          }).then(function() {
            Swal.close();
            Swal.fire('Success', 'Password for ' + user_name + ' has been updated.', 'success');
          }).catch(function(err) {
            Swal.showValidationMessage('Password ' + err.responseJSON['errors']['password']);
          });
        },
      });
    });
  };

  // Note: Before colvis was implemented, updating DOM elements of the affected row would be tackled by just replacing
  // the overall row with 'user_set_row' template or by changing the html of the affected td cells that required updating
  // However, after implementation of colvis, an affected column might not necessarily be present in the DOM if the
  // relevant column is hidden, making the update rather impossible. Therefore, instead of manipulating the DOM, to
  // handle the dynamism of colvis, we update the relevant row's data.
  // Note: updateUserRow is used when dynamically changing things on such as disabling/activating user
  //       and so we update the whole row with the latest state so we get back status and correct new link
  Accounts.prototype.updateUserRow = function(rowSelector, updatedUserRowData) {
    this.accountsDataTable.row(rowSelector).data(updatedUserRowData);
    this.usersDatatableUtils.reinitializeRowComponents(rowSelector, this);
  };

  return Accounts;
})();
