import '~/scripts/integrations/jquery-extended';
import Pagelet from '~/scripts/lib/Pagelet';

export default (function() {
  return function() {
    this.pagelet = new Pagelet();
    this.dateRange = new window.R.DateRange({refresh: 'turbo'});

    $("#yammer_stats").click(function (e) {
      e.preventDefault();
      $("#leaderboard_filters").hide();
      document.location.hash = "yammer";
    });

    $("#recognize_stats").click(function (e) {
      e.preventDefault();
      $("#leaderboard_filters").show();
      document.location.hash = "recognize";
    });

    if (document.location.hash == "#yammer") {
      $("#yammer_stats").trigger("click");
    }

    R.utils.setupSelectFilters(); // needed for badge select only
  };
})();
