import '~/scripts/integrations/jquery-extended';
import FormErrors from '../lib/FormErrors';

import { init as initEmojiMart, Picker as EmojiMartPicker } from 'emoji-mart';
import emojiMartGoogleData from '@emoji-mart/data/sets/14/google.json'

// this is mainly for IE: where EmojiMart doesn't seem to be supported and is not available
// Note: This was originally added during ES5 version; not sure if this type of check still works with ES modules
//       although R.reactionsSupported should still be falsely if above imports error out
window.R.reactionsSupported = (typeof initEmojiMart !== 'undefined' && typeof EmojiMartPicker !== 'undefined');

window.R.Reactions = (function() {
  "use strict";

  var reactionsParentElemSel = '.reactions';
  var triggerElemSel = '.emoji-trigger';
  var pickerSel = 'em-emoji-picker';
  var pendingRequestClass = 'pending-request';

  var pickerAttr = '$picker';
  var targetElemAttr = '$target-elem';
  var expandedStateAttr = 'isListExpanded';

  var Reactions;

  Reactions = function(opts) {
    if (!R.reactionsSupported)
      return;

    this.opts = opts || {};

    // sample image-based dataset; this needs to be in sync with the emoji component in view
    this.emojiSet = 'google';
    this.emojiData = emojiMartGoogleData;
    window.R.emojiData = this.emojiData.emojis; // this is for specs only
    this.locale = this.getLocale();

    this.addEvents();
  };

  Reactions.prototype.getLocale = function() {
    // Purpose: Locale of application is used for fetching locale texts for emoji by emoji mart on picker initialization.
    //          Alternatively we could manually translate the strings on the server-side and pass via gon, which would be tedious and harder to maintain
    // NOTE: Only below set of languages are supported by emoji mart for locale.
    var availableLangsInEmojiMart = ["ar", "de", "fa", "hi", "kr", "pt", "tr", "zh", "be", "en", "fi", "it", "nl", "ru", "uk",
    "cs", "es", "fr", "ja", "pl", "sa", "vi"];

    // use only the base locale instead of country specific locale. eg: zh for zh-CN
    var lang = $('html').attr('lang').substring(0,2);

    // The emoji locale will fallback to 'en' incase the language is not within the list.
    return (availableLangsInEmojiMart.includes(lang) ? lang : 'en');
  }

  Reactions.prototype.addEvents = function() {
    // Purpose: this is for initializing emoji components (i.e. generating emoji from HTML markups)
    //          this seems to cover the components added in the DOM later on as well
    // note: despite us hosting the emoji json data, the images (spritesheet/individual) are still fetched from the CDN
    //       datasets we are currently storing: google, apple, native
    //       locale texts for emojis are being fetched from CDN
    initEmojiMart({ data: this.emojiData, locale: this.locale, set: this.emojiSet });

    this.bindReactionInteractionListener();
    this.bindPickerTriggerListener();
    this.bindTruncationToggleListener();
  };

  // handles clicks on existing reactions on a reactable
  // Note: .non-interactable sections should already ignore UI interactions, but also ignoring here explicitly
  Reactions.prototype.bindReactionInteractionListener = function() {
    var that = this;
    $body.on(R.touchEvent, reactionsParentElemSel + ':not(.non-interactable) .reaction', function (evt) {
      evt.preventDefault();

      var $reaction = $(this);
      var $emojiComponent = $reaction.find('em-emoji');

      var reactionParams = {};
      var isCreateRequest = !$reaction.hasClass('self-reacted');
      if (isCreateRequest) {
        // in case of multiple skins, just use the first one in the list
        reactionParams.emoji_shortcode = $emojiComponent.attr('shortcodes');
      } else {
        // using id here because in case of multiple skin tones, we don't know which variant belongs to the current user
        reactionParams.emoji_id = $reaction.data('emojiId');
      }

      $reaction.addClass(pendingRequestClass);
      that.sendCreateOrDeleteRequest($reaction, reactionParams, isCreateRequest, function () {
        $reaction.removeClass(pendingRequestClass); // this is practically necessary for failed case only
        if (that.opts.updateCallback) {
          that.opts.updateCallback();
        }
      });
    });
  };

  /*
   * Note: Not using any caching for the picker as such approaches have proved to be problematic
   *       (whether caching globally or per trigger element; see commit desc)
   **/
  Reactions.prototype.bindPickerTriggerListener = function() {
    var that = this;
    $body.on(R.touchEvent, triggerElemSel, function (evt) {
      evt.preventDefault();

      var $this = $(this);
      // we could probably just specify the picker selector generically here, but tracking via data attr for robustness
      var $picker = $this.data(pickerAttr);

      if ($picker){
        that.removePicker(null, null, $this);
      } else {
        // close any other open picker first
        var $existingPicker = $(pickerSel);
        if ($existingPicker.length){
          that.removePicker($existingPicker);
        }

        that.createAndInsertPicker($this);
      }
    });
  };

  Reactions.prototype.bindTruncationToggleListener = function (e) {
    $body.on(R.touchEvent, '.truncation-toggle', function (evt) {
      var $toggleBtn = $(this);
      var $reactionSection = $toggleBtn.parents(reactionsParentElemSel);
      var $truncationWrapper = $reactionSection.find('.truncated-emojis');
      var wasExpanded = $reactionSection.data(expandedStateAttr) || false;
      ensureHiddenClassRemoved($truncationWrapper);

      var dataAttrForNewLabel = wasExpanded ? 'showActionLabel' : 'hideActionLabel';
      var onAnimationComplete = function() {
        var newLabel = $toggleBtn.data(dataAttrForNewLabel);
        $toggleBtn.html(newLabel);
        $reactionSection.data(expandedStateAttr, !wasExpanded);
        $document.trigger("recognition:change"); // for remnant of isotope in grid page
      };

      // animation note: slideup/slidedown animation might look better for recognition show page
      //                 (as the section has much smaller width compared to cards, esp. in desktop)
      if (wasExpanded) {
        $truncationWrapper.fadeOut(onAnimationComplete);
      } else {
        $truncationWrapper.fadeIn(onAnimationComplete);
      }
    });

    function ensureHiddenClassRemoved($truncationWrapper) {
      // remove initial hidden class from server to make slideUp/slideDown work
      if (($truncationWrapper).hasClass('hidden')) {
        $truncationWrapper.hide();
        $truncationWrapper.removeClass('hidden');
      }
    }
  };

  Reactions.prototype.handleClickOutsidePopup = function (e) {
    var $target = $(e.target);
    if ($target.closest(triggerElemSel).length) // target element is trigger element
      return;

    var $picker = $(pickerSel);
    if ($picker.length) {
      var $pickerTarget = $picker.data(targetElemAttr);
      $picker.remove();
      if ($pickerTarget) {
        $pickerTarget.data(pickerAttr, null);
      }
      $body.trigger('reactions:pickerToggled', [false, $pickerTarget]);
    }
  };

  Reactions.prototype.sendRequest = function(refChildElem, reactionParams, endpoint, method, callback) {
    if (!endpoint) return false;

    var that = this;
    var $reactionSection = $(refChildElem).parents(reactionsParentElemSel);
    var reactableData = $reactionSection.data();
    var reactableParams = {
      type: reactableData.reactableType,
      id: reactableData.reactableId
    };

    callback = callback || function () {};

    $.ajax({
      type: method,
      url: endpoint,
      data: {
        reaction: reactionParams,
        reactable: reactableParams,
        is_list_expanded: $reactionSection.data(expandedStateAttr),
        default_visible_count: $reactionSection.data('defaultVisibleCount'),
        display_inline: $reactionSection.data('displayInline')
      }
    }).then(function(data) {
      that.handleSuccessfulRequest(data, $reactionSection);
    }).fail(function(xhr) {
      that.handleFailedRequest(xhr, $reactionSection);
    }).always(callback);
  };

  Reactions.prototype.handleSuccessfulRequest = function(data, $reactionSection) {
    var partial = data && data.partial;
    if (partial) {
      this.hideReactionTooltip($reactionSection);

      var $newReactionSection = $(partial);
      $reactionSection.replaceWith($newReactionSection);
      R.utils.safe_feather_replace(); // for picker trigger icon
      $body.trigger('reactions:pickerToggled', [false, $newReactionSection]);
      $newReactionSection.find("[title]").tooltip();
    }
  };

  Reactions.prototype.handleFailedRequest = function(xhr, $reactionSection) {
    if (['cancelled', 'abort'].includes(xhr.statusText)) {
      return;
    }
    var errors = xhr.responseJSON && xhr.responseJSON.errors;
    if (xhr.status !== 422 || !errors) {
      // TODO (maybe): localize these?
      var message;
      if (xhr.status === 401) {
        // permission mismatch, like setting disabled and user reacts from a stale page
        message = 'Action not permitted. Please try reloading the page.';
      } else {
        // generic fallback for unexpected (server-side) errors
        message = 'There was a problem with updating reactions. Please try again later.';
      }
      errors = {"base": [message]};
    }

    // beforehand cleanup
    this.hideReactionTooltip($reactionSection);
    this.removePicker(null, $reactionSection);

    var formErrors = new FormErrors($reactionSection, errors, {});
    formErrors.renderErrors();

    // hide errors after few seconds
    // to avoid cluttering up the UI as well as since reactions is a relatively minor feature/section
    setTimeout(function () {
      $reactionSection.find('.error').fadeOut(1000);
    }, 3000);
  };

  // invoked from picker
  Reactions.prototype.sendToggleRequest = function (refChildElem, reactionParams, callback) {
    var endpoint = gon.reactions_toggle_path;
    var method = 'POST';
    this.sendRequest(refChildElem, reactionParams, endpoint, method, callback);
  };

  // invoked from existing reaction component
  Reactions.prototype.sendCreateOrDeleteRequest = function (refChildElem, reactionParams, isCreateRequest, callback) {
    var endpoint = gon.reactions_path;
    var method = isCreateRequest ? 'POST' : 'DELETE';
    this.sendRequest(refChildElem, reactionParams, endpoint, method, callback);
  };

  Reactions.prototype.handleEmojiSelect = function (emojiData, evt){
    var reactionParams = {
      // note: the key name 'shortcodes' is plural, but the value always seems to be a string.
      emoji_shortcode: emojiData.shortcodes
    };
    var refElem = evt.target.getRootNode().host; // picker reference outside shadow DOM
    this.sendToggleRequest(refElem, reactionParams, this.opts.updateCallback);
  };

  /*
   * Tooltips linked to old reaction elements seem to get stuck when the section is replaced/moved, so need to be reset
   * Note: Doing this instead of just remove()ing .tooltip, as:
   *       - that only works in case of section replacement (success case)
   *       - but causes issue for error rendering case where the same section is persisted
   *         where the tooltip for the relevant element stops working
   *       - with this approach, it needs to be invoked before updating the section and cannot be place in always() callback
  ***/
  Reactions.prototype.hideReactionTooltip = function($wrapper) {
    $wrapper.find('.reaction[aria-describedby*="tooltip"]').tooltip('hide');
  };

  Reactions.prototype.createAndInsertPicker = function($triggerElem) {
    var picker = new EmojiMartPicker({
      // data: ..., // not needed here, as we are already passing on init
      set: this.emojiSet,
      locale: this.locale,
      onEmojiSelect: this.handleEmojiSelect.bind(this),
      onClickOutside: this.handleClickOutsidePopup,
      autoFocus: true
    });

    var $picker = $(picker);
    $picker.data(targetElemAttr, $triggerElem);
    $triggerElem.data(pickerAttr, $picker);
    $triggerElem.after($picker);
    $body.trigger('reactions:pickerToggled', [true, $triggerElem]);
  };

  Reactions.prototype.removePicker = function($picker, $reactionSection, $triggerElem) {
    if ($picker){
      $triggerElem = $picker.data(targetElemAttr);
    } else {
      $triggerElem = $triggerElem || $reactionSection.find(triggerElemSel);
      $picker = $triggerElem.data(pickerAttr);
    }

    if ($picker){
      $picker.remove();
      $triggerElem.data(pickerAttr, null);
      $body.trigger('reactions:pickerToggled', [false, $triggerElem]);
    }
  };

  // off() not needed for triggerElemSel callback as it's being registered on $body
  Reactions.prototype.removeEvents = function() {
  };

  return Reactions;

})();
