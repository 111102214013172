import CompanyAdmin from '~/scripts/lib/CompanyAdmin';

export default (function() {
  var N = function() {
    this.addEvents();
  };

  N.prototype.addEvents = function() {
    var that = this;
    var companyAdmin = new CompanyAdmin();
    this.dateRange = new window.R.DateRange({refresh: 'turbo'});
  };

  N.prototype.removeEvents = function() {};
  return N;
})();
