import Swal from 'sweetalert2';

(function () {
    window.R.ui.swalHelpers = {
        success: function (title, text) {
            Swal.fire({
                icon: 'success',
                title: title,
                text: text
            });
        }
    };
})();
