import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const $el = $(this.element);
    $el.tooltip('show');
    setTimeout(function() {
      $el.tooltip('hide');
    }, 7000);
  }

}
