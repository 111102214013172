import '~/scripts/integrations/jquery-extended';

export default function initSurveyFilters() {
  const $surveyTypeLabel = $("label[for='Survey_Type']");
  const $surveyTypeFilter = $("#filter_survey_type");
  const $surveyTable = $("#surveys-table");

  function updateVisibilityOfSurveyTypeFilter(surveyTypes) {
    if (surveyTypes.length <= 1) {
      $surveyTypeLabel.hide();
      $surveyTypeFilter.hide();
    } else {
      $surveyTypeLabel.show();
      $surveyTypeFilter.show();
    }
  }

  function populateSurveyTypes(surveyTypes) {
    updateVisibilityOfSurveyTypeFilter(surveyTypes);
    $surveyTypeFilter.empty();
    if (surveyTypes.length <= 1) return;

    $surveyTypeFilter.append($('<option>', {
      value: 'all',
      text: 'All'
    }));

    const filteredSurveyTypes = surveyTypes.filter(item => item !== null);
    filteredSurveyTypes.forEach(item => {
      $surveyTypeFilter.append($('<option>', {
        value: item,
        text: item.replace(/^Survey::/, '')
      }));
    });

    $surveyTable.DataTable().ajax.reload();
  }

  function handleSurveyStatusChange() {
    const selectedValue = $(this).val();
    const surveyTypes = selectedValue === "disabled" ? gon.survey_types.disabled : gon.survey_types.enabled;

    populateSurveyTypes(surveyTypes);
  }

  updateVisibilityOfSurveyTypeFilter(gon.survey_types.enabled);

  $('#filter_survey_status').on('change', handleSurveyStatusChange);
}