import '~/scripts/integrations/jquery-extended';
import AwardGenerator from '~/scripts/lib/AwardGenerator';

export default function() {
  var awards = {
    classic: {
      big_number: {
        x: 690,
        y: 470,
        fontsize: 280,
        draggable: true,
        fill: $("#big_number-color").val(),
        width: 350,
        fontsytle: 'bold',
        fontfamily: 'bitter',
        align: 'center'
      },
      title: {
        x: 1710,
        y: 300,
        fontsize: 200,
        draggable: true,
        fill: $("#title").find("input[type=color]").val(),
        width: 1300,
        fontfamily: 'bitter'
      },
      description: {
        x: 1710,
        y: 940,
        fontsize: 100,
        draggable: true,
        fill: $("#description").find("input[type=color]").val(),
        width: 1000,
        fontfamily: 'bitter'
      },
      signature: {
        align: 'center',
        x: 2430,
        y: 2200,
        fontsize: 50,
        draggable: true,
        fill: $("#signature").find("input[type=color]").val(),
        width: 540,
        fontfamily: 'bitter'
      },
      name: {
        align: 'center',
        x: 1530,
        y: 1600,
        fontsize: 120,
        draggable: true,
        fill: $("#name").find("input[type=color]").val(),
        width: 1500,
        fontfamily: 'bitter'
      },
      date: {
        align: 'center',
        x: 1570,
        y: 2050,
        fontsize: 50,
        draggable: true,
        fill: $("#date").find("input[type=color]").val(),
        width: 520,
        fontfamily: 'bitter'
      }
    }
  };
  this.awardCert = new AwardGenerator(awards);
};
