import '~/scripts/integrations/jquery-extended';
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';

export default (function() {
  var AnniversaryNotifications = function() {
    this.addEvents();
  };

  AnniversaryNotifications.prototype.addEvents = function() {
    var companyAdmin;

    $document.on(R.touchEvent, '.role_toggle,.role_toggle_individual_team', function(e) {
      $(this).closest('form').submit();
    });

    companyAdmin = new CompanyAdmin();
  };

  AnniversaryNotifications.prototype.removeEvents = function() {
    $document.off(R.touchEvent, '.role_toggle,.role_toggle_individual_team');
  };

  return AnniversaryNotifications;
})();
