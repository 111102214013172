import '~/scripts/integrations/jquery-extended';
import CompanyAdmin from '~/scripts/lib/CompanyAdmin';

export default (function() {
  var Rewards = function() {
    this.addEvents();
  };

  Rewards.prototype.addEvents = function() {
    new CompanyAdmin();
    this.initPieCharts();
  };

  Rewards.prototype.initPieCharts = function() {
    if (window.R.company && window.R.company.dashboard && window.R.company.dashboard.pieChartDataGiftCards) {
      R.analytics.piechart({
        series: [{
          name: '',
          colorByPoint: true,
          lang: {
            noData: "No company fulfilled rewards have been approved yet."
          },
          data: window.R.company.dashboard.pieChartDataCompany
        }],
        container: 'top-company-rewards-piechart'
      });

      if(window.R.company.dashboard.pieChartDataGiftCards.length > 0
         && $('#top-giftcard-rewards-piechart').length) {
        R.analytics.piechart({
          series: [{
            name: '',
            colorByPoint: true,
            lang: {
              noData: "No gift cards have been approved yet."
            },
            data: window.R.company.dashboard.pieChartDataGiftCards
          }],
          container: 'top-giftcard-rewards-piechart'
        });

      }
    }
  };

  Rewards.prototype.removeEvents = function() {};

  return Rewards;
})();
