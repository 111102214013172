/**
 * This file was added during Vite migration
 * - to preserve the global access for lib classes that were migrated from lib-legacy/ to lib/ as ES modules
 * - for use in some HTML / JS.erb files, where modules cannot be imported
 */

import FormErrors from '../lib/FormErrors';
import Select2 from '~/scripts/lib/Select2.js'
import SwalForm from '~/scripts/lib/SwalForm';
import remoteCheckboxToggle from '~/scripts/lib/ui/remoteCheckboxToggle';

// As of writing, this is added for views/account_chooser/update.js.erb
import '~/scripts/integrations/legacy-namespaces/transitions';

/**
 * This was mainly added for views/company_admin/accounts_spreadsheet_importers/process_data_sheet.js.erb
 */
window.R.forms.Errors = FormErrors;

/**
 * Moved from init.js
 * As of writing, this is needed only for views/admin/companies/_site_admin_company_header.html.erb
 */
window.R.Select2 = Select2;

/**
 * This is needed for about half a dozen .js.erb files
 */
window.R.SwalForm = SwalForm;

