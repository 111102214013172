import '~/scripts/integrations/jquery-extended';
import Pagelet from '~/scripts/lib/Pagelet';
import Select2 from '~/scripts/lib/Select2.js'
import 'flot'
import 'flot/jquery.flot.time'

export default (function() {
  return function() {
    // handle pagelets
    this.pagelet = new Pagelet();

    $(".card .collapse").on('shown.bs.collapse', function(e) {
      $(e.target).find(".delayed-pagelet").addClass("pagelet");
      this.pagelet.attachPagelets();
    }.bind(this));

    // handle select2
    new Select2(function () {
      $(".select2").select2().on('select2:select', function(e){
        if(e.params.data.id) {
          Turbo.visit('/admin/companies/'+e.params.data.id)
        }
      });
    });
  }
})();
